import React from "react";
import { Link, Outlet, useLocation } from "react-router-dom";

const navItemStyle = {
  padding: "3px 8px",
  cursor: "pointer",
  fontSize: "14px",
  fontWeight: "bold",
  color: "white",
  textAlign: "center",
  flex: 1,
  transition: "background-color 0.3s ease, color 0.3s ease",
  textDecoration: "none", // Remove underline
};

// Styles for active item
const activeNavItemStyle = {
  ...navItemStyle,
   backgroundColor: "white",
 
  color: "black",
  borderRadius: "30px",
  transform: "scale(1)", // Slight increase for active state
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", // Subtle shadow for active
};

// Styles for the subnavbar container
const subNavBarStyle = {
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "15px",
  backgroundColor: "#2980B9",
  padding: "8px",
  borderRadius: "30px",
  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)", // Slight shadow for navbar
};


const StudentManagerLayout = () => {
  const location = useLocation();

  // Function to check if the current route matches
  const isActive = (path) => location.pathname === path;

  return (
    <div>
      {/* Sub-navbar for Employee Manager */}
      <div style={subNavBarStyle}>
        <Link to="/layout/fees-manager" style={isActive("/layout/fees-manager") ? activeNavItemStyle : navItemStyle}>
          Dashboard
        </Link>
        {/* <Link to="/layout/fees-manager/FeeInvoice" style={isActive("/layout/fees-manager/FeeInvoice") ? activeNavItemStyle : navItemStyle}>
        Fees Invoice
        </Link> */}
         <Link to="/layout/fees-manager/Invoice" style={isActive("/layout/fees-manager/Invoice") ? activeNavItemStyle : navItemStyle}>
        Invoice
        </Link>
        <Link to="/layout/fees-manager/InvoiceList" style={isActive("/layout/fees-manager/InvoiceList") ? activeNavItemStyle : navItemStyle}>
        Invoice List
        </Link>
        <Link to="/layout/fees-manager/AddFee" style={isActive("/layout/fees-manager/AddFee") ? activeNavItemStyle : navItemStyle}>
        Add Fees
        </Link>
        <Link to="/layout/fees-manager/FeeTable" style={isActive("/layout/fees-manager/FeeTable") ? activeNavItemStyle : navItemStyle}>
        Fees Table
        </Link>
        {/* <Link to="/layout/fees-manager/AddMedium" style={isActive("/layout/fees-manager/AddMedium") ? activeNavItemStyle : navItemStyle}> */}
        {/* Add Medium */}
        {/* </Link> */}
        <Link to="/layout/fees-manager/FeeReport" style={isActive("/layout/fees-manager/FeeReport") ? activeNavItemStyle : navItemStyle}>
        Fees Report
        </Link>
      </div>

      {/* This is where the content of each route will be displayed */}
      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default StudentManagerLayout;
