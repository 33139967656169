import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { SketchPicker } from "react-color";
import Swal from "sweetalert2";
import {
  getFooterByInstitutecode,
  createFooter,
  updateFooter,
} from "./Api/AllApi";
import ShowFooter from "./ShowFooter";

const Footer = () => {
  const [footerData, setFooterData] = useState([]);
  const [formData, setFormData] = useState({
    footerId: null,
    title: "",
    footerColor: "",
    instagramLink: "",
    facebookLink: "",
    twitterLink: "",
    youtubeLink: "",
    whatsappLink: "",
    email: "",
    mobileNumber: "",
    address: "",
  });
  const [isEditMode, setIsEditMode] = useState(false);
  const [open, setOpen] = useState(false);

  const institutecode = () => localStorage.getItem("institutecode");

  useEffect(() => {
    fetchFooterData();
  }, []);
  const fetchFooterData = async () => {
    try {
      const response = await getFooterByInstitutecode(institutecode());
      setFooterData(Array.isArray(response.data) ? response.data : [response.data]);
    } catch (error) {
      console.error("Error fetching footer data:", error);
    }
  };

  const handleSubmit = async () => {
    try {
      if (!formData.title) {
        Swal.fire("Error", "Title is required", "error");
        return;
      }

      const url = isEditMode
        ? `https://pjsofttech.in:28443/updateFooterDetail/${formData.footerId}`
        : `https://pjsofttech.in:28443/createFooterDetail`;

      const payload = {
        ...formData,
        institutecode: institutecode(),
      };

      if (isEditMode) {
        await updateFooter(payload);
        Swal.fire("Success", "Footer updated successfully!", "success");
      } else {
        await createFooter(payload);
        Swal.fire("Success", "Footer created successfully!", "success");
      }

      handleClose();
      fetchFooterData();
    } catch (error) {
      console.error("Error submitting footer data:", error);
      Swal.fire("Error", "Failed to submit footer data.", "error");
    }
  };

  const handleEdit = (row) => {
    setFormData(row);
    setIsEditMode(true);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFormData({
      footerId: null,
      title: "",
      footerColor: "",
      instagramLink: "",
      facebookLink: "",
      twitterLink: "",
      youtubeLink: "",
      whatsappLink: "",
      email: "",
      mobileNumber: "",
      address: "",
    });
    setIsEditMode(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleColorChange = (color) => {
    setFormData({ ...formData, footerColor: color.hex });
  };

  return (
    <div>
      <h2>Footer Details</h2>
      <Button variant="contained" onClick={() => setOpen(true)}>
        Add Footer
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>{isEditMode ? "Edit Footer" : "Add Footer"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="title"
                label="Title"
                fullWidth
                value={formData.title}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="address"
                label="Address"
                fullWidth
                value={formData.address}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="email"
                label="Email"
                fullWidth
                value={formData.email}
                onChange={handleInputChange}
                required
                type="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="mobileNumber"
                label="Mobile Number"
                fullWidth
                value={formData.mobileNumber}
                onChange={handleInputChange}
                required
                type="tel"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="instagramLink"
                label="Instagram Link"
                fullWidth
                value={formData.instagramLink}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="facebookLink"
                label="Facebook Link"
                fullWidth
                value={formData.facebookLink}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="twitterLink"
                label="Twitter Link"
                fullWidth
                value={formData.twitterLink}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="youtubeLink"
                label="YouTube Link"
                fullWidth
                value={formData.youtubeLink}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="whatsappLink"
                label="WhatsApp Link"
                fullWidth
                value={formData.whatsappLink}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <SketchPicker
                color={formData.footerColor}
                onChangeComplete={handleColorChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} variant="contained">
            {isEditMode ? "Update" : "Submit"}
          </Button>
        </DialogActions>
      </Dialog>
      <ShowFooter />
    </div>
  );
};

export default Footer;
