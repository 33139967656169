import React, { useEffect, useState } from "react";
import {
  Typography,
  CircularProgress,
  Container,
  Divider,
  IconButton,
  Grid,
} from "@mui/material";
import { getFooterByInstitutecode, getAllCourses } from "./Api/AllApi";
import { useNavigate } from "react-router-dom";
import "./CSS/Navbar.css";
import playStore from "./Images/google-play-store-app-logo-gets-a-slight-redesign-png-19.png";
import appStore from "./Images/app-store-png-logo-33116.png";
import facebook from "./Images/facebook-logo-icon-facebook-icon-png-images-icons-and-png-backgrounds-6.png";
import instagram from "./Images/logo-ig-logo-instagram-ini-ada-varias-dan-transparan-33.png";
import twitter from "./Images/512x512-transparent-logo-download-twitter-png-transparent-image-and-clipart-8.png";
import youtube from "./Images/youtube-play-button-icon-icons-and-png-backgrounds-25.png";
import whatsApp from "./Images/whatsapp-logo-png-2266.png";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";

const ShowFooter = () => {
  const [footerData, setFooterData] = useState(null);
  const [courses, setCourses] = useState([]);
  const [footerTitle, setFooterTitle] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [navbarImage, setNavbarImage] = useState("");
  const navigate = useNavigate();

  const institutecode = () => localStorage.getItem("institutecode");

  useEffect(() => {
    if (!institutecode()) {
      setError("Institutecode not found in local storage.");
      setLoading(false);
      return;
    }

    const fetchFooterData = async () => {
        try {
            const code = institutecode();
            if (!code) {
                throw new Error("Institutecode is missing from local storage.");
            }
            const footer = await getFooterByInstitutecode(code);
            console.log("API Response for Footer:", footer); // Debug
            setFooterData(footer);
        } catch (err) {
            console.error("Error in API call:", err.message);
            setError("Failed to load footer data. Please try again later.");
        } finally {
            setLoading(false);
        }
    };
    
    

    fetchFooterData();
  }, []);

  const getTextColor = (bgColor) => {
    if (!bgColor) return "black"; // Default to black
    const color = bgColor.replace("#", "");
    const r = parseInt(color.substring(0, 2), 16);
    const g = parseInt(color.substring(2, 4), 16);
    const b = parseInt(color.substring(4, 6), 16);

    // Calculate relative luminance
    const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    return luminance > 128 ? "black" : "white"; // Choose black or white based on luminance
  };

  if (loading) {
    return (
      <Container>
        <Typography align="center">Loading footer...</Typography>
        <CircularProgress color="primary" />
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Typography align="center" color="error">
          {error}
        </Typography>
      </Container>
    );
  }

  if (!footerData) {
    return null;
  }

  const footerBgColor = footerData.footerColor || "gray";
  const textColor = getTextColor(footerBgColor);
  const currentYear = new Date().getFullYear();

  return (
    <footer
      style={{
        backgroundColor: footerBgColor,
        color: textColor,
        padding: "20px",
        marginTop: "50px",
      }}
    >
      <Grid container spacing={4} alignItems="start" style={{ marginTop: "20px" }}>
        <Grid item xs={12} sm={8}>
          <Typography
            variant="h6"
            align="center"
            style={{
              marginBottom: "20px",
              fontWeight: "bold",
              color: textColor,
            }}
          >
            Courses
          </Typography>
          <Divider style={{ marginBottom: "20px", backgroundColor: textColor }} />
          <Grid container spacing={1}>
            {courses.map((course) => (
              <Grid
                item
                xs={12}
                sm={4}
                key={course.courseId}
                onClick={() => navigate("/courses")}
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  color: textColor,
                }}
              >
                <Typography variant="subtitle1">{course.courseTitle}</Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Typography
            variant="h6"
            align="center"
            style={{
              marginBottom: "20px",
              fontWeight: "bold",
              color: textColor,
            }}
          >
            Contact Us
          </Typography>
          <Divider style={{ marginBottom: "20px", backgroundColor: textColor }} />
          <Typography
            variant="body1"
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
              color: textColor,
            }}
          >
            <LocationOnIcon style={{ marginRight: "8px", color: "red" }} />
            Address: {footerData.address || "N/A"}
          </Typography>
          <Typography
            variant="body1"
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
              color: textColor,
            }}
          >
            <EmailIcon style={{ marginRight: "8px", color: "orange" }} />
            Email: {footerData.email || "info@example.com"}
          </Typography>
          <Typography
            variant="body1"
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "20px",
              color: textColor,
            }}
          >
            <CallIcon style={{ marginRight: "8px", color: "#0086B9" }} />
            Phone: {footerData.mobileNumber || "+1 (123) 456-7890"}
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center" justifyContent="space-between">
        <Grid item xs={12} sm={4} style={{ textAlign: "left" }}>
          {navbarImage && (
            <img
              src={navbarImage}
              alt="Navbar Logo"
              className="navbar-logo"
              style={{
                borderRadius: "50%",
                width: "50px",
                height: "50px",
                objectFit: "cover",
                marginRight: "10px",
              }}
            />
          )}
          <Typography
            variant="h6"
            style={{
              fontWeight: "bold",
              display: "inline-block",
              verticalAlign: "middle",
              marginLeft: navbarImage ? "10px" : "0",
              marginBottom: "10px",
            }}
          >
            {footerTitle}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={4} style={{ textAlign: "center" }}>
          <div style={{ display: "flex", justifyContent: "center", gap: "15px" }}>
            <IconButton
              component="a"
              href={footerData.instagramLink || "#"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={instagram} alt="Instagram" style={{ width: "30px" }} />
            </IconButton>
            <IconButton
              component="a"
              href={footerData.facebookLink || "#"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={facebook} alt="Facebook" style={{ width: "30px" }} />
            </IconButton>
            <IconButton
              component="a"
              href={footerData.twitterLink || "#"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={twitter} alt="Twitter" style={{ width: "30px" }} />
            </IconButton>
            <IconButton
              component="a"
              href={footerData.youtubeLink || "#"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={youtube} alt="YouTube" style={{ width: "33px" }} />
            </IconButton>
            <IconButton
              component="a"
              href={footerData.whatsAppLink || "#"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={whatsApp} alt="WhatsApp" style={{ width: "50px" }} />
            </IconButton>
          </div>
        </Grid>

        <Grid item xs={12} sm={4} style={{ textAlign: "right" }}>
          <div style={{ display: "flex", justifyContent: "flex-end", gap: "15px" }}>
            <a
              href={footerData.googlePlayLink || "#"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={playStore}
                alt="Google Play"
                style={{ width: "90px", borderRadius: "8px" }}
              />
            </a>
            <a
              href={footerData.appStoreLink || "#"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={appStore}
                alt="App Store"
                style={{ width: "90px", borderRadius: "8px" }}
              />
            </a>
          </div>
        </Grid>
      </Grid>

      <Typography align="center" variant="body2" style={{ marginTop: "30px" }}>
        &copy; {currentYear} {footerTitle}. All rights reserved.
      </Typography>
    </footer>
  );
};

export default ShowFooter;
