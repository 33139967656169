import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./PlanInfo.css";
import {
  MenuItem,
  FormControl,
  TextField,
  Grid,
  Divider,
  Checkbox,
  FormControlLabel,
  Paper,
} from "@mui/material";
import numToWords from "num-to-words";

const PlanInfo = () => {
  const navigate = useNavigate(); // Hook for navigation
  const { state } = useLocation(); // Extracting state from location
  const { plan, section } = state; // Destructuring plan and section from state

  const [years, setYears] = useState(1); // State to manage the number of years
  const [range, setRange] = useState("Upto 25"); // Default range value
  const [selectedAddOns, setSelectedAddOns] = useState([]); // Selected add-ons state
  const gstRate = 0.18; // GST rate
  const addOnPrice = 500; // Price for each add-on
  const activationFee = 3000; // Activation fee for "Institute / Academy" when range < 250

  const ranges = [
    "Upto 25",
    "Upto 50",
    "Upto 150",
    "Upto 250",
    "Upto 500",
    "Upto 800",
    "Upto 1000",
    "Upto 2000",
    "Upto 3000",
    "Upto 4000",
  ];

  // Function to calculate pricing details
  const calculatePrice = () => {
    let basePrice;

    // Determine base price based on section and plan name
    if (section === "Institute / Academy") {
      switch (plan.name) {
        case "Basic":
          basePrice = 2000;
          break;
        case "Premium":
          basePrice = 3000;
          break;
        case "Business":
          basePrice = 5000;
          break;
        default:
          basePrice = 1; // Default for unknown plans
      }
    } else if (section === "School / College") {
      switch (plan.name) {
        case "Basic":
          basePrice = 5;
          break;
        case "Premium":
          basePrice = 10;
          break;
        case "Business":
          basePrice = 20;
          break;
      }
    } else if (section === "Organization / Company") {
      switch (plan.name) {
        case "Basic":
          basePrice = 50;
          break;
        case "Premium":
          basePrice = 100;
          break;
        case "Business":
          basePrice = 150;
          break;
      }
    } else {
      basePrice = 1; // Default for other sections
    }

    // Extract count from range (e.g., "Upto 25" -> 25)
    const count = parseInt(range.replace("Upto ", ""), 10);

    // Calculate yearly price based on section condition
    const yearlyPrice = section === "Institute / Academy"
      ? basePrice * 12 * years
      : basePrice * count * 12 * years;

    // Calculate add-ons total price
    const addOnsTotal = selectedAddOns.length * addOnPrice;
    let activationFeeTotal = 0;

    // Include activation fee if applicable
    if (section === "Institute / Academy" && count < 250) {
      activationFeeTotal = activationFee;
    }

    // Calculate GST and total amount
    const gstAmount = (yearlyPrice + addOnsTotal + activationFeeTotal) * gstRate;
    const total = yearlyPrice + addOnsTotal + activationFeeTotal + gstAmount;

    return { basePrice, yearlyPrice, addOnsTotal, activationFeeTotal, gstAmount, total };
  };

  // Handlers for user actions
  const handleYearChange = (change) =>
    setYears((prev) => Math.max(1, prev + change)); // Ensure years stay >= 1

  const handleRangeChange = (event) => setRange(event.target.value); // Update range

  const handleAddOnToggle = (addOn) => {
    setSelectedAddOns((prev) =>
      prev.includes(addOn)
        ? prev.filter((item) => item !== addOn) // Remove add-on if already selected
        : [...prev, addOn] // Add add-on if not selected
    );
  };

  // Calculate pricing breakdown
  const {
    basePrice,
    yearlyPrice,
    addOnsTotal,
    activationFeeTotal,
    gstAmount,
    total,
  } = calculatePrice();

  return (
    <div className="plan-info-container">
      <div className="plan-content-wide">
        {/* Left Side: Features */}
        <div className="features-container">
          <h2>
            {section} Plan ({plan.name})
          </h2>
          <div className="features-grid">
            {plan.features.map((feature, index) => (
              <div key={index} className="feature-item">
                {feature.name}
              </div>
            ))}
          </div>
          <div className="back-button-container">
            <button onClick={() => navigate(-1)} className="back-button">
              Back To Plans
            </button>
          </div>
        </div>

        {/* Right Side: Price Breakdown */}
        <div className="price-details">
          <h2>Price Breakdown</h2>
          <Grid container spacing={2}>
            {/* Range Selector */}
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                variant="outlined"
                className="range-selector"
              >
                <TextField
                  select
                  value={range}
                  className="textField-root"
                  onChange={handleRangeChange}
                  size="small"
                  label="Range"
                >
                  {ranges.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>

            {/* Year Adjustment */}
            <Grid item xs={12} sm={6}>
              <div className="year-adjustment">
                <button onClick={() => handleYearChange(-1)}>-</button>
                <span>{years} year(s)</span>
                <button onClick={() => handleYearChange(1)}>+</button>
              </div>
            </Grid>
          </Grid>

          <Divider style={{ margin: "16px 0", border: "2px solid black" }} />

          {/* Payment Details */}
          <div className="payment-details">
            <ul>
              {plan.originalPrice && (
                <li className="original-price">
                  Original Price: <s>₹{plan.originalPrice}</s>
                </li>
              )}
              <li>
                Base Price: <strong>₹{basePrice.toFixed(2)} / Month</strong>
              </li>
              <li>
                Total Year(s): <strong>{years} Year(s)</strong>
              </li>
              <li>
                Range Count: <strong>{range}</strong>
              </li>
              {activationFeeTotal > 0 && (
                <li>
                  Activation Fee: <strong>₹{activationFeeTotal.toFixed(2)}</strong>
                </li>
              )}

              {/* Add-Ons Section */}
              <div className="add-ons">
                <Paper
                  elevation={3}
                  style={{
                    padding: "20px",
                    marginTop: "20px",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  <h3>Optional Add-Ons (₹500 each)</h3>
                  <div className="add-ons-grid">
                    {["Ebook", "Payroll", "Shipment", "Project Management"].map(
                      (addOn, index) => (
                        <div key={index} className="add-ons-item">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selectedAddOns.includes(addOn)}
                                onChange={() => handleAddOnToggle(addOn)}
                              />
                            }
                            label={addOn}
                          />
                        </div>
                      )
                    )}
                  </div>
                </Paper>
              </div>

              <li>
                Add-Ons Total: <strong>₹{addOnsTotal.toFixed(2)}</strong>
              </li>
              <li>
                Total Price (Excluding GST):
                <strong> ₹{yearlyPrice.toFixed(2)}</strong>
              </li>
              <li>
                GST (18%):<strong> ₹{gstAmount.toFixed(2)}</strong>
              </li>

              <Divider
                style={{ margin: "16px 0", border: "2px solid black" }}
              />
              <li>
                Total Payable Amount (Including GST):
                <strong> ₹{total.toFixed(2)}</strong>
              </li>
              <li>
                Total in Words: <strong>{numToWords(total)} rupees only</strong>
              </li>
            </ul>
          </div>
          <div className="back-button-container">
            <button
              onClick={() =>
                navigate("/next-page", {
                  state: {
                    total,
                    selectedAddOns,
                    range,
                    years,
                    basePrice,
                    section,
                    planName: plan.name,
                  },
                })
              }
              className="back-button"
            >
              Continue To Next Step
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanInfo;
