import React, { useState, useEffect } from "react";
import {
  getAllMaterialTypes,
  getAllCategoriesByMaterialType,
  getSubcategoriesByCategory,
  getAllUserMaterial,
} from "./API/AllApi";
import {
  Grid,
  TextField,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  Typography,
} from "@mui/material";

const SalesList = () => {
  const [materials, setMaterials] = useState([]);
  const [filteredMaterials, setFilteredMaterials] = useState([]);
  const [materialTypes, setMaterialTypes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [totalPaidAmount, setTotalPaidAmount] = useState("");
  const [filters, setFilters] = useState({
    username: "",
    materialType: "",
    categoryName: "",
    subcategoryName: "",
  });

  useEffect(() => {
    getAllUserMaterial().then((data) => {
      setMaterials(data);
      const total = data.reduce(
        (sum, material) => sum + material.paidAmount,
        0
      );
      setTotalPaidAmount(total);
      setFilteredMaterials(data);
    });

    getAllMaterialTypes().then(setMaterialTypes);
  }, []);

  // Fetch categories based on selected material type
  useEffect(() => {
    if (filters.materialType) {
      getAllCategoriesByMaterialType(filters.materialType).then(setCategories);
    } else {
      setCategories([]);
    }
  }, [filters.materialType]);

  useEffect(() => {
    if (filters.categoryName) {
      getSubcategoriesByCategory(filters.categoryName).then(setSubcategories);
    } else {
      setSubcategories([]);
    }
  }, [filters.categoryName]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
    filterMaterials({ ...filters, [name]: value });

    // Reset dependent filters
    if (name === "materialType") {
      setFilters((prevFilters) => ({ ...prevFilters, categoryName: "", subcategoryName: "" }));
      setCategories([]);
      setSubcategories([]);
    } else if (name === "categoryName") {
      setFilters((prevFilters) => ({ ...prevFilters, subcategoryName: "" }));
      setSubcategories([]);
    }
  };

  const filterMaterials = (currentFilters) => {
    const {
      username,
      materialType,
      chapterName,
      categoryName,
      subcategoryName,
    } = currentFilters;

    const filtered = materials.filter((material) => {
      return (
        (!username ||
          material.user.username
            .toLowerCase()
            .includes(username.toLowerCase())) &&
        (!chapterName ||
          material.material.chapterName
            .toLowerCase()
            .includes(chapterName.toLowerCase())) &&
        (!materialType || material.material.materialtype === materialType) &&
        (!categoryName || material.material.categoryName === categoryName) &&
        (!subcategoryName ||
          material.material.subcategoryName === subcategoryName)
      );
    });

    setFilteredMaterials(filtered);
  };

  return (
    <div>
      <Grid container spacing={2} style={{ marginBottom: "20px" }} className="textField-root">
        <Grid item xs={2.4}>
          <TextField
            label="Material Type"
            select
            fullWidth
            name="materialType"
            onChange={handleFilterChange}
          >
            <MenuItem value="">All</MenuItem>
            {materialTypes.map((type) => (
              <MenuItem key={type.id} value={type.materialtype}>
                {type.materialtype}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={2.4}>
          <TextField
            label="Category"
            select
            fullWidth
            name="categoryName"
            onChange={handleFilterChange}
            disabled={!filters.materialType} // Disable if material type not selected
          >
            <MenuItem value="">All</MenuItem>
            {categories.map((category) => (
              <MenuItem key={category.id} value={category.categoryName}>
                {category.categoryName}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={2.4}>
          <TextField
            label="Subcategory"
            select
            fullWidth
            name="subcategoryName"
            onChange={handleFilterChange}
            disabled={!filters.categoryName}
          >
            <MenuItem value="">All</MenuItem>
            {subcategories.map((subcategory) => (
              <MenuItem
                key={subcategory.id}
                value={subcategory.subcategoryName}
              >
                {subcategory.subcategoryName}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={2.4}>
          <TextField
            label="Username"
            name="username"
            onChange={handleFilterChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={2.4}>
          <TextField
            label="Subject Name"
            name="chapterName"
            onChange={handleFilterChange}
            fullWidth
          />
        </Grid>
      </Grid>
      <Typography variant="h6" style={{ marginBottom: "20px", color: "green" }}>
        Total Revenue Generated : ₹{totalPaidAmount}
      </Typography>
      <TableContainer component={Paper}>
        <Table className="table-root">
          <TableHead>
            <TableRow>
              <TableCell>User</TableCell>
              <TableCell>Contact</TableCell>
              <TableCell>Material</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>SubCategory</TableCell>
              <TableCell>Subject</TableCell>
              <TableCell>MRP</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Paid Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredMaterials.map((material) => (
              <TableRow key={material.id}>
                <TableCell>{material.user.username}</TableCell>
                <TableCell>{material.user.contact}</TableCell>
                <TableCell>{material.material.materialtype}</TableCell>
                <TableCell>{material.material.categoryName}</TableCell>
                <TableCell>{material.material.subcategoryName}</TableCell>
                <TableCell>{material.material.chapterName}</TableCell>
                <TableCell>₹{material.material.mrp}</TableCell>
                <TableCell>₹{material.material.price}</TableCell>
                <TableCell sx={{ color: "green", fontWeight:'bold' }}>
                  ₹{material.paidAmount}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default SalesList;
