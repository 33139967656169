import React, { useState } from "react";
import { Box, Paper, Typography, Tooltip } from "@mui/material";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import AssessmentIcon from "@mui/icons-material/Assessment";
import BookIcon from "@mui/icons-material/Book";
import StoreIcon from "@mui/icons-material/Store";
import PersonIcon from "@mui/icons-material/Person";
import Type from "../Shipment/Type";
import Status from "../Shipment/Status";
import BookName from "../Shipment/Bookname";
import VendorName from "../Shipment/VendorName";
import Sentby from "./Sentby";

const menuItems = [
  { id: "Type", label: "Add Product Type", icon: <LocalShippingIcon /> },
  { id: "Status", label: "Add Status", icon: <AssessmentIcon /> },
  { id: "BookName", label: "Add Product", icon: <BookIcon /> },
  { id: "VendorName", label: "Add Vendor", icon: <StoreIcon /> },
  { id: "SentBy", label: "Add Sent By", icon: <PersonIcon /> },
];

const ShipmentSetting = () => {
  const [selectedComponent, setSelectedComponent] = useState("Type");
  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <Box sx={{ display: "flex", padding: "10px" }}>
      {/* Sidebar */}
      <Paper
        elevation={3}
        sx={{
          width: isCollapsed ? "60px" : "220px",
          padding: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          height: "250px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          borderRadius: "30px",
          backgroundColor: "#2980B9",
          transition: "width 0.7s ease",
          position: "relative",
          overflow: "hidden",
        }}
        onMouseEnter={() => setIsCollapsed(false)}
        onMouseLeave={() => setIsCollapsed(true)}
      >
        {menuItems.map((item) => (
          <Tooltip key={item.id} title={isCollapsed ? item.label : ""} placement="right">
            <Typography
              variant="h6"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                cursor: "pointer",
                padding: "10px",
                color: selectedComponent === item.id ? "black" : "white",
                fontWeight: selectedComponent === item.id ? "bold" : "normal",
                borderRadius: "30px",
                backgroundColor: selectedComponent === item.id ? "white" : "",
                fontSize: "13px",
                transition: "all 0.3s ease",
                whiteSpace: "nowrap",
                boxShadow:
                  selectedComponent === item.id
                    ? "0px 4px 10px rgba(0, 0, 0, 0.2)"
                    : "none",
              }}
              onClick={() => setSelectedComponent(item.id)}
            >
              {item.icon}
              {!isCollapsed && item.label}
            </Typography>
          </Tooltip>
        ))}
      </Paper>

      {/* Content Area */}
      <Box
        sx={{
          flexGrow: 1,
          padding: "20px",
          marginLeft: "20px",
          border: "1px solid #ddd",
          borderRadius: "8px",
        }}
      >
        {selectedComponent === "Type" && <Type />}
        {selectedComponent === "Status" && <Status />}
        {selectedComponent === "BookName" && <BookName />}
        {selectedComponent === "VendorName" && <VendorName />}
        {selectedComponent === "SentBy" && <Sentby />}
      </Box>
    </Box>
  );
};

export default ShipmentSetting;
