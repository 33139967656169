import React, { useState } from "react";
import { Box, Paper, Typography, Tooltip } from "@mui/material";
import CategoryIcon from "@mui/icons-material/Category";
import ApartmentIcon from "@mui/icons-material/Apartment";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import AddEmpcategory from "../Employee/AddEmpcategory";
import AddDepartment from "../Employee/AddDepartment";
import ManageHoliday from "../Employee/ManageHoliday";

const menuItems = [
  { id: "AddDepartment", label: "Add Department", icon: <ApartmentIcon /> },
  { id: "AddEmpcategory", label: "Add Category", icon: <CategoryIcon /> },
  {
    id: "ManageHoliday",
    label: "Manage Holiday",
    icon: <HolidayVillageIcon />,
  },
];

const EmployeeSetting = () => {
  const [selectedComponent, setSelectedComponent] = useState("AddEmpcategory");
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleMouseEnter = () => setIsCollapsed(false);
  const handleMouseLeave = () => setIsCollapsed(true);

  const renderContent = () => {
    switch (selectedComponent) {
      case "AddDepartment":
        return <AddDepartment />;
      case "AddEmpcategory":
        return <AddEmpcategory />;
      case "ManageHoliday":
        return <ManageHoliday />;
      default:
        return null;
    }
  };

  return (
    <Box sx={{ padding: "10px", display: "flex", justifyContent: "center" }}>
      {/* Sidebar */}
      <Paper
        elevation={3}
        sx={{
          width: isCollapsed ? "60px" : "230px",
          padding: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          height: "150px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          borderRadius: "30px",
          backgroundColor: "#2980B9",
          transition: "width 0.7s ease",
          position: "relative",
          overflow: "hidden",
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {menuItems.map((item) => (
          <Tooltip
            key={item.id}
            title={isCollapsed ? item.label : ""}
            placement="right"
          >
            <Typography
              variant="h6"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                cursor: "pointer",
                padding: "10px",
                color: selectedComponent === item.id ? "black" : "white",
                fontWeight: selectedComponent === item.id ? "bold" : "normal",
                borderRadius: "30px",
                backgroundColor: selectedComponent === item.id ? "white" : "",
                fontSize: "13px",
                transition: "all 0.3s ease",
                whiteSpace: "nowrap",
                boxShadow:
                  selectedComponent === item.id
                    ? "0px 4px 10px rgba(0, 0, 0, 0.2)"
                    : "none",
              }}
              onClick={() => setSelectedComponent(item.id)}
            >
              {item.icon}
              {!isCollapsed && item.label}
            </Typography>
          </Tooltip>
        ))}
      </Paper>

      {/* Content Area */}
      <Box
        sx={{
          flexGrow: 1,
          padding: "20px",
          marginLeft: "20px",
          border: "1px solid #ddd",
          borderRadius: "8px",
        }}
      >
        {renderContent()}
      </Box>
    </Box>
  );
};

export default EmployeeSetting;
