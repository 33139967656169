import axios from "axios";

const BASE_URL = "https://pjsofttech.in:28443"; 


// Function to add a dynamic URL
export const addDynamicUrl = async (dynamicPart, institutecode) => {
  console.log("Creating URL mapping:", dynamicPart, institutecode);
  const payload = {
      dynamicPart,
      institutecode,
  };

  try {
      const response = await axios.post(`${BASE_URL}/api/urls`, payload, {
          headers: {
              "Content-Type": "application/json",
          },
      });
      console.log("URL mapping created successfully:", response.data);
      return response.data; // Return response data for further use
  } catch (error) {
      console.error("Error creating URL mapping:", error.response?.data || error.message);
      throw error;
  }
};

  export const createCourse = async (formData) => {
    return await axios.post(`${BASE_URL}/createCourse`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
    });
};

export const updateCourse = async (id, formData) => {
    return await axios.put(`${BASE_URL}/updateCourse/${id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
    });
  }
export const getAllCourses = async (institutecode) => {
    return axios.get(`${BASE_URL}/getAllCourses`, {
        params: { institutecode },
    });
};


export const deleteCourse = async (id) => {
    return axios.delete(`${BASE_URL}/deleteCourse/${id}`);
};

export const addCourseColor = async (institutecode, courseColor) => {
    return axios.post(`${BASE_URL}/addCourseColor`, null, {
        params: { institutecode, courseColor },
    });
};

export const updateCourseColor = async (institutecode, courseColor) => {
    return axios.put(`${BASE_URL}/updateCourseColor`, null, {
        params: { institutecode, courseColor },
    });
};




export const getAllFacilities = (institutecode) => {
  return axios.get(`${BASE_URL}/getAllFacilities`, { params: { institutecode } });
};

export const createFacility = (data) => {
  return axios.post(`${BASE_URL}/createFacility`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const updateFacility = (id, data) => {
  return axios.put(`${BASE_URL}/updateFacility/${id}`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const addFacilityColor = (institutecode, facilityColor) => {
  return axios.post(`${BASE_URL}/addFacilityColor`, null, {
    params: { institutecode, facilityColor },
  });
};

export const updateFacilityColor = (institutecode, facilityColor) => {
  return axios.put(`${BASE_URL}/updateFacilityColor`, null, {
    params: { institutecode, facilityColor },
  });
};


export const deleteFacility = async (id) => {
  return axios.delete(`${BASE_URL}/deleteFacility/${id}`);
};

export const getAllSlideBars = async (institutecode) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/getAllSlideBars?institutecode=${institutecode}`
    );
    return response.data; // Ensure response has `.data` for the array
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};






export const getFooterByInstitutecode = (institutecode) => {
  return axios.get(`${BASE_URL}/getFooterByInstitutecode`, { params: { institutecode } });
};
export const createFooter = (footerData) => {
  const { title, footerColor, institutecode, email, mobileNumber, address, instagramLink, facebookLink, twitterLink, youtubeLink, whatsappLink } = footerData;
  
  const params = new URLSearchParams({
    title,
    footerColor,
    institutecode,
    email,
    mobileNumber,
    address,
    instagramLink: instagramLink || '',
    facebookLink: facebookLink || '',
    twitterLink: twitterLink || '',
    youtubeLink: youtubeLink || '',
    whatsappLink: whatsappLink || ''
  });

  return axios.post(`${BASE_URL}/createFooter?${params.toString()}`);
};
export const updateFooter = (footerData) => {
  return axios.put(`${BASE_URL}/updateFooter`, footerData);
};

export const deleteFooter = (institutecode) => {
  return axios.delete(`${BASE_URL}/deleteFooter`, { params: { institutecode } });
};
