import React, { useState, useEffect } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Box,
  Typography,
} from "@mui/material";
import './sidebar.css';

import { Link } from "react-router-dom";
import {
  Add,
  Remove,
  Dashboard as DashboardIcon,
  People as PeopleIcon,
} from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import PersonSearchRoundedIcon from "@mui/icons-material/PersonSearchRounded";
import AddHomeRoundedIcon from "@mui/icons-material/AddHomeRounded";
import CurrencyRupeeRoundedIcon from "@mui/icons-material/CurrencyRupeeRounded";
import EmojiPeopleRoundedIcon from "@mui/icons-material/EmojiPeopleRounded";
import SchoolRoundedIcon from "@mui/icons-material/SchoolRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import SupervisorAccountRoundedIcon from "@mui/icons-material/SupervisorAccountRounded";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import PersonIcon from "@mui/icons-material/Person";
import HelpIcon from "@mui/icons-material/Help";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";
import WorkOffIcon from "@mui/icons-material/WorkOff";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import LanguageIcon from '@mui/icons-material/Language';

const drawerWidth = 255; // Full sidebar width
const iconOnlyWidth = 60; // Width when showing icons only
const Sidebar = ({ menuItems }) => {
  const [expanded, setExpanded] = useState("");
  const location = useLocation();

  const [systemValues, setSystemValues] = useState(null);
  const [isMinimized, setIsMinimized] = useState(false); // Controls sidebar minimized state
  const institutecode = () => localStorage.getItem("institutecode");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://pjsofttech.in:20443/getSystemValueByInstitutecode?institutecode=${institutecode()}`
        );
        const data = await response.json();
        setSystemValues(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  // Collapse sidebar automatically after 30 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsMinimized(true);
    }, 30000);
    return () => clearTimeout(timer); // Clear timeout on component unmount
  }, []);

  // Hover events to expand/collapse sidebar
  const handleMouseEnter = () => {
    if (isMinimized) setIsMinimized(false);
  };

  const handleMouseLeave = () => {
    if (!isMinimized) setIsMinimized(true);
  };

  // Handle toggling between minimized and expanded states
  const handleDrawerToggle = () => {
    setIsMinimized((prev) => !prev); // Toggle between minimized and expanded
  };

  // Toggle expanding sub-options
  const handleToggle = (optionName) => {
    setExpanded((prev) => (prev === optionName ? "" : optionName));
  };

  const isActive = (route) => location.pathname === route;
  const sidebarOptions = [
    {
      name: "Main Dashboard",
      icon: <ViewQuiltIcon  />,
      route: "/layout/combineDash",
      subOptions: [],
    },
    {
      name: "Enquiry System",
      icon: <PersonSearchRoundedIcon  />,
      show: systemValues?.enquirymanagementsystem,
      route: "/layout/Enquiry-manager",
      subOptions: [],
    },
    {
      name: "Admission System",
      icon: <AddHomeRoundedIcon  />,
      show: systemValues?.admissionmanagementsystem,
      route: "/layout/Admission-manager",
      subOptions: [],
    },
    {
      name: "Income & Expense",
      icon: <CurrencyRupeeRoundedIcon  />,
      show: systemValues?.incomeandexpense,
      route: "/layout/income-expense-manager",
      subOptions: [],
    },
    {
      name: "Employee System",
      icon: <PeopleIcon  />,
      show: systemValues?.employeemanagementsystem,
      subOptions: [
        {
          name: "Employee",
          icon: <PersonIcon  />,
          route: "/layout/employee-manager",
        },
        {
          name: "Attendance",
          icon: <SupervisorAccountRoundedIcon  />,
          route: "/layout/attendance-manager",
        },
        {
          name: "Leave",
          icon: <WorkOffIcon  />,
          route: "/layout/leave-manager",
        },
        {
          name: "Salary",
          icon: <CurrencyRupeeRoundedIcon  />,
          route: "/layout/employee-salary-manager",
        },
        {
          name: "Memo",
          icon: <NoteAltIcon  />,
          route: "/layout/Memo-manager",
        },
        {
          name: "Notice",
          icon: <StickyNote2Icon  />,
          route: "/layout/AddNotice",
        },
      ],
    },
    {
      name: "Student System",
      icon: <EmojiPeopleRoundedIcon  />,
      show: systemValues?.studentmanagementsystem,
      subOptions: [
        {
          name: "Student",
          route: "/layout/student-manager",
          icon: <DashboardIcon  />,
        },
        {
          name: "Fees System",
          icon: <CurrencyRupeeRoundedIcon  />,
          route: "/layout/fees-manager",
        },
      ],
    },
    {
      name: "Classroom System",
      icon: <SchoolRoundedIcon  />,
      subOptions: [],
    },
    {
      name: "Shipment System",
      icon: <LocalShippingIcon   />,
      route: "/layout/shipment-manager",
      subOptions: [],
    },
    {
      name: "Payroll System",
      icon: <AccountBalanceWalletIcon   />,
      route: "/layout/payroll-manager",
      subOptions: [],
    },
    {
      name: "Website System",
      icon: <LanguageIcon   />,
      route: "/layout/website-manager",
      subOptions: [],
    },
    {
      name: "Ebook System",
      icon: <LanguageIcon   />,
      route: "/layout/Ebook-manager",
      subOptions: [],
    },
    {
      name: "Add Sub-Admin",
      icon: <AdminPanelSettingsIcon  />,
      route: "/layout/subadmin",
      subOptions: [],
    },
    {
      name: "Help Desk",
      icon: <HelpIcon  />,
      route: "/layout/helpDesk",
      subOptions: [],
    },
    {
      name: "Billing Section",
      icon: <PriceCheckIcon  />,
      route: "/layout/bill",
      subOptions: [],
    },
    {
      name: "Settings",
      icon: <SettingsRoundedIcon  />,
      route: "/layout/Settings",
      subOptions: [],
    },
  ];

  const filteredSidebarOptions = sidebarOptions.filter(
    (option) => option.show !== false
  );

  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        width: isMinimized ? iconOnlyWidth : drawerWidth,
        flexShrink: 0,
        transition: "width 0.5s",
        "& .MuiDrawer-paper": {
          width: isMinimized ? iconOnlyWidth : drawerWidth,
          boxSizing: "border-box",
          backgroundColor: "#3498DB",
          overflowX: "hidden",
          transition: "width 0.7s",
          whiteSpace: "nowrap",
        },
      }}
    >
      {/* Sidebar Options */}
      <Box sx={{ overflowY: "auto", marginTop: "60px", overflowX: "hidden" }}>
        <List sx={{ color: "white" }}>
          {filteredSidebarOptions.map((option, index) => (
            <div key={index}>
              <ListItem
                button
                onClick={() => handleToggle(option.name)}
                component={Link}
                to={option.route}
                className={isActive(option.route) ? "sidebar-item active" : "sidebar-item"}
                sx={{
                  transition:
                    "transform 0.7s ease, box-shadow 0.7s ease, background-color 0.7s",
                  "&:hover": {
                    transform: "scale(1.04)",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    backgroundColor: "#2980B9",
                  },
                }}
              >
                <ListItemIcon>{option.icon}</ListItemIcon>

                {/* Show option name only if not minimized */}
                {!isMinimized && (
                  <ListItemText
                    primary={
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          marginLeft: "-20px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {option.name}
                      </Typography>
                    }
                  />
                )}

                {/* Dropdown Icon for options with sub-options */}
                {option.subOptions.length > 0 && (
                  <ListItemIcon>
                    {expanded === option.name ? (
                      <Remove sx={{ color: "red"}} />
                    ) : (
                      <Add sx={{ color: "#FFF"}} />
                    )}
                  </ListItemIcon>
                )}
              </ListItem>

              {/* Sub-options with collapse */}
              {!isMinimized && option.subOptions.length > 0 && (
                <Collapse
                  in={expanded === option.name}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {option.subOptions.map((subOption, subIndex) => (
                      <ListItem
                        key={subIndex}
                        button
                        component={Link}
                        to={subOption.route}
                        className={isActive(subOption.route) ? "sidebar-subitem active" : "sidebar-subitem"}
                        sx={{
                          pl: 4,
                          "&:hover": {
                            backgroundColor: "#1F618D",
                          },
                        }}
                      >
                        <ListItemIcon sx={{ ml: "-8px" }}>
                          {subOption.icon}
                        </ListItemIcon>

                        {/* Sub-option text */}
                        {!isMinimized && (
                          <ListItemText
                            primary={
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: "11px",
                                  marginLeft: "-20px",
                                  fontWeight: "normal",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {subOption.name}
                              </Typography>
                            }
                          />
                        )}
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              )}
            </div>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

export default Sidebar;