import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography,
  ThemeProvider,
  createTheme,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Grid,
  IconButton,
} from "@mui/material";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditIcon from "@mui/icons-material/Edit";
import AddCategory from "./EbookAddCategory";
import { getAllCategories } from "./API/AllApi";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2196f3",
    },
    secondary: {
      main: "#ffffff",
    },
    background: {
      default: "#f5f5f5",
    },
  },
});

const EbookCategory = () => {
  const navigate = useNavigate();
  const [openDeleteCategoryDialog, setOpenDeleteCategoryDialog] =
    useState(false);
  const [openDeleteSubcategoryDialog, setOpenDeleteSubcategoryDialog] =
    useState(false);
  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  const [openAddSubCategoryDialog, setOpenAddSubCategoryDialog] =
    useState(false);
  const [openThumbnailDialog, setOpenThumbnailDialog] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [filteredCategory, setFilteredCategory] = useState("");
  const [newSubCategoryName, setNewSubCategoryName] = useState("");
  const [selectedSubCategoryCategory, setSelectedSubCategoryCategory] =
    useState("");
  const [categories, setCategories] = useState([]);
  const [subCategoriesToShow, setSubCategoriesToShow] = useState([]);
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [categoryName, setCategoryName] = useState("");
  const [categoryType, setCategoryType] = useState("");
  const [subCategories, setSubCategories] = useState([]);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [subcategoryToDelete, setSubcategoryToDelete] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [subcategoryToEdit, setSubcategoryToEdit] = useState(null);
  const [newSubcategoryName, setNewSubcategoryName] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCategories = async () => {
      setLoading(true);
      try {
        const data = await getAllCategories();
        const formattedCategories = data.map((cat) => ({
          id: cat.id,
          name: cat.categoryName,
          type: cat.type,
          image: cat.thumbnail,
          subCategories: cat.subcategories,
        }));
        setCategories(formattedCategories);
      } catch (error) {
        console.error("Error fetching categories:", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const handleDeleteCategoryConfirmation = (category) => {
    setSelectedCategory(category);
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this category?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteCategory();
      }
    });
  };

  // const handleDeleteCategory = async () => {
  //   try {
  //     await axios.delete(
  //       `https://pjsofttech.in:21443/deleteCategory/${selectedCategory.id}`
  //     );
  //     const updatedCategories = categories.filter(
  //       (category) => category.id !== selectedCategory.id
  //     );
  //     setCategories(updatedCategories);
  //   } catch (error) {
  //     Swal.fire("Error", "Error deleting category", "error");
  //     console.error("Error deleting category:", error);
  //   }
  // };

  const handleDeleteCategory = (category) => {
    if (!category || !category.id) {
      console.error("Cannot delete category: category or category.id is null");
      console.log("Category object:", category);  // Log the category object for debugging
      return;
    }
  
    fetch(`https://pjsofttech.in:21443/deleteCategory/${category.id}`, {
      method: 'DELETE',
    })
      .then(response => {
        if (!response.ok) {
          throw new Error("Failed to delete category");
        }
        console.log("Category deleted successfully");
        // Further code for handling successful deletion
      })
      .catch(error => {
        console.error("Error deleting category:", error);
      });
  };
  
  const handleDeleteSubCategory = (subCategory) => {
    setSubcategoryToDelete(subCategory);
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this sub-category?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteSubCategoryConfirm();
      }
    });
  };

  const handleDeleteSubCategoryConfirm = async () => {
    try {
      await axios.delete(
        `https://pjsofttech.in:21443/deleteSubcategory/${subcategoryToDelete.id}`
      );
      const updatedSubCategories = subCategories.filter(
        (sc) => sc.id !== subcategoryToDelete.id
      );
      setSubCategories(updatedSubCategories);
      const updatedCategories = categories.map((category) => {
        if (category.id === selectedCategory.id) {
          return { ...category, subCategories: updatedSubCategories };
        }
        return category;
      });
      setCategories(updatedCategories);
    } catch (error) {
      Swal.fire("Error", "Error deleting sub-category", "error");
      console.error("Error deleting sub-category:", error);
    }
  };

  const handleInfoOpen = (category) => {
    setSelectedCategory(category);
    setCategoryName(category.name);
    setCategoryType(category.type);
    setOpenInfoDialog(true);
  };

  const handleAddSubCategoryOpen = (category) => {
    setSelectedCategory(category);
    setOpenAddSubCategoryDialog(true);
  };

  const handleAddSubCategory = async () => {
    if (selectedSubCategoryCategory && newSubCategoryName) {
      try {
        const selectedCategoryObj = categories.find(
          (cat) => cat.id === selectedCategory.id
        );
        if (!selectedCategoryObj) {
          console.error("Selected category not found.");
          return;
        }

        const response = await axios.post(
          "https://pjsofttech.in:21443/addsubcategory",
          {
            subcategoryName: newSubCategoryName,
            categoryId: selectedCategoryObj.id,
          }
        );

        const newSubCategory = response.data;
        if (!newSubCategory) {
          console.error("No sub-category returned from the API.");
          return;
        }

        const updatedCategories = categories.map((category) => {
          if (category.id === selectedCategoryObj.id) {
            return {
              ...category,
              subCategories: [...category.subCategories, newSubCategory],
            };
          }
          return category;
        });
        setCategories(updatedCategories);
        setOpenAddSubCategoryDialog(false);
      } catch (error) {
        Swal.fire("Error", "Error adding sub-category", "error");
        console.error("Error adding sub-category:", error);
      }
    } else {
      Swal.fire("Error", "Category or Sub-category name is missing", "error");
    }
  };
  const handleShowSubCategories = async (category) => {
    try {
      const response = await axios.get(
        `https://pjsofttech.in:21443/subcategoryByCategory`,
        {
          params: {
            categoryName: category.name,
          },
        }
      );

      const subCategories = response.data;

      navigate("/Layout/viewSubcategory", {
        state: {
          categoryId: category.id,
          categoryName: category.name,
          subCategories: subCategories,
        },
      });
    } catch (err) {
      setError("Error fetching sub-categories");
      Swal.fire("Error", "Fetch subcategories error", "error");
      console.error("Fetch subcategories error:", err);
    }
  };

  const handleUpdateCategory = async () => {
    const formData = new FormData();
    formData.append("name", categoryName);
    formData.append("type", categoryType);
    if (selectedImageFile) {
      formData.append("thumbnail", selectedImageFile);
    }

    try {
      await axios.put(
        `https://pjsofttech.in:21443/updateCategory/${selectedCategory.id}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      const updatedCategories = categories.map((category) => {
        if (category.id === selectedCategory.id) {
          return { ...category, name: categoryName, type: categoryType };
        }
        return category;
      });
      setCategories(updatedCategories);
      setOpenInfoDialog(false);
      Swal.fire("Success", "Category updated successfully", "success");
    } catch (error) {
      Swal.fire("Error", "Error updating category", "error");
      console.error("Error updating category:", error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div style={{ padding: "20px" }}>
        <Paper elevation={3} style={{ padding: "20px", marginBottom: "20px" }}>
          <Grid container spacing={2} alignItems="center" wrap="nowrap">
            <Grid item xs={12} md={4}>
              <Typography variant="body1" align="center" sx={{ color: "grey" }}>
                {`Total Categories: ${categories.length}`}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                variant="outlined"
                label="Filter Category"
                value={filteredCategory}
                onChange={(e) => setFilteredCategory(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={4} sx={{ textAlign: "center" }}>
              <AddCategory />
            </Grid>
          </Grid>
        </Paper>

        <TableContainer component={Paper} sx={{ marginBottom: "20px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Category Name</TableCell>{" "}
                <TableCell>Thumbnail</TableCell>
                <TableCell>SubCategory</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {categories
                .filter((category) =>
                  category.name
                    .toLowerCase()
                    .includes(filteredCategory.toLowerCase())
                )
                .map((category) => (
                  <TableRow key={category.id}>
                    <TableCell>{category.name}</TableCell>
                    <TableCell>
                      {category.image ? (
                        <img
                          src={category.image}
                          alt={category.name}
                          style={{
                            width: "50px",
                            height: "50px",
                            objectFit: "cover",
                            borderRadius: "5px", // optional
                          }}
                        />
                      ) : (
                        <Typography variant="body2" color="textSecondary">
                          No Image
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      {" "}
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleShowSubCategories(category)}
                      >
                        View Subcategories
                      </Button>
                    </TableCell>

                    <TableCell>
                      <IconButton
                        onClick={() => handleInfoOpen(category)}
                        color="primary"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() =>
                          handleDeleteCategoryConfirmation(category)
                        }
                        color="error"
                      >
                        <DeleteRoundedIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </ThemeProvider>
  );
};

export default EbookCategory;
