import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Typography,
  Box,
  Grid,
  Button,
  TextField,
  MenuItem,
  FormControl,
  Paper,
  CardContent,
  CircularProgress,
} from "@mui/material";
import Swal from "sweetalert2";
import indianStatesAndDistricts from "../indianStatesAndDistricts";
import {
  fetchAddOnDetails,
  fetchValidities,
  saveInstitute,
} from "./CreateINSTApi"; // Adjust the import based on the location of api.js
import { color } from "d3";

const BuyAddOn = () => {
  const { addOnId } = useParams();
  const navigate = useNavigate();
  const [addOnDetails, setAddOnDetails] = useState(null);
  const [validities, setValidities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    emailaddress: "",
    phonenumber: "",
    password: "",
    confirmpassword: "",
    institutename: "",
    mobilenumber: "",
    websitename: "",
    systemName: "",
    address: "",
    landmark: "",
    pincode: "",
    city: "",
    state: "",
    country: "India",
    registrationnumber: "",
    studentRange: "",
    validity: "",
    gstpersent: 18,
    amount: 0,
    totalAmount: 0,
    district: "",
    aadhar: "",
    pancard: "",
    loa: "",
    mou: "",
    publicid: "",
    gstNo: "",
    ownerName: "",
    transactionId: "",
    paymentMode: "",
    remark: "",
    gstAmount: "",
  });

  const [districts, setDistricts] = useState([]);

  const studentRangeMapping = {
    "Upto 25": 25,
    "Upto 50": 50,
    "Upto 150": 150,
    "Upto 250": 250,
    "Upto 500": 500,
    "Upto 800": 800,
    "Upto 1000": 1000,
    "Upto 2000": 2000,
    "Upto 3000": 3000,
    "Upto 4000": 4000,
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        const addOnData = await fetchAddOnDetails(addOnId);
        setAddOnDetails(addOnData);
        setFormData((prevData) => ({
          ...prevData,
          systemName: addOnData.addOnSystemName,
        }));

        const validityData = await fetchValidities();
        setValidities(validityData);
      } catch (error) {
        setError("Failed to fetch add-on details or validities.");
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [addOnId]);

  const calculateTotalAmount = (studentRange, validity) => {
    if (addOnDetails && typeof addOnDetails.addOnSystemPrice === "number") {
      const selectedCount = studentRangeMapping[studentRange] || 1;
      const selectedValidity = validity || 1;
      return addOnDetails.addOnSystemPrice * selectedCount * selectedValidity;
    }
    return 0;
  };

  const handleValidityChange = (event) => {
    const selectedValidity = Number(event.target.value);
    setFormData((prevData) => {
      const newTotalAmount = calculateTotalAmount(
        prevData.studentRange,
        selectedValidity
      );
      return {
        ...prevData,
        validity: selectedValidity,
        totalAmount: newTotalAmount,
      };
    });
  };

  const handleStudentRangeChange = (e) => {
    const selectedRange = e.target.value;
    setFormData((prevData) => {
      const newTotalAmount = calculateTotalAmount(
        selectedRange,
        prevData.validity
      );
      return {
        ...prevData,
        studentRange: selectedRange,
        totalAmount: newTotalAmount,
      };
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      const newData = { ...prevData, [name]: value };

      if (name === "studentRange" || name === "validity") {
        const newTotalAmount = calculateTotalAmount(
          newData.studentRange,
          newData.validity
        );
        newData.totalAmount = newTotalAmount;
      }

      if (name === "state") {
        const selectedDistricts = indianStatesAndDistricts[value] || [];
        setDistricts(selectedDistricts);
        newData.district = "";
      }

      return newData;
    });
  };

  const validateForm = () => {
    const errors = {};
    if (formData.password !== formData.confirmpassword) {
      errors.password = "Passwords do not match.";
    }
    if (!/^\d{12}$/.test(formData.aadhar)) {
      errors.aadhar = "Aadhaar number must be 12 digits.";
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm) {
      return;
    }

    setIsSubmitting(true);

    const totalGst = (formData.totalAmount * 0.18).toFixed(2);
    const totalAmountIncludingGst = (
      formData.totalAmount + parseFloat(totalGst)
    ).toFixed(2);

    const dataToSend = {
      ...formData,
      totalAmount: formData.totalAmount,
      amount: formData.totalAmount,
      gstAmount: totalGst,
      systemName: formData.systemName,
    };

    try {
      await saveInstitute(dataToSend);

      await Swal.fire({
        title: "Success!",
        text: "Your institute has been successfully saved.",
        icon: "success",
        confirmButtonText: "OK",
      });
    } catch (error) {
      console.error("Failed to save institute:", error);
      await Swal.fire({
        title: "Error!",
        text: "Failed to save your institute. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  if (!addOnDetails) {
    return <Typography>Loading...</Typography>;
  }

  const handleBackToPlans = () => {
    navigate("/create-account#");
  };

  const discountedPrice = addOnDetails.mrp * (addOnDetails.discount / 100);
  const totalGst = (formData.totalAmount * 0.18).toFixed(2);
  const totalAmountIncludingGst = (
    formData.totalAmount + parseFloat(totalGst)
  ).toFixed(2);

  const validityOptions = [
    { months: 6, discount: 10 },
    { months: 12, discount: 20 },
    { months: 24, discount: 30 },
    { months: 48, discount: 40 },
  ];

  const calculateDiscountedPrice = (price, discount) => {
    return price - price * (discount / 100);
  };

  return (
    <div className="plan-info-container">
      <Grid container spacing={5}>
        <Grid item xs={5}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Box
                style={{
                  borderRadius: "5px",
                  padding: "20px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  background: "linear-gradient(135deg, #e8d67d, #c93)",
                }}
              >
                <Typography
                  color="#334450"
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  {addOnDetails.addOnSystemName}
                </Typography>
                {/* <Typography
                  color="#334450"
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    textAlign: "center",
                    textDecoration: "line-through",
                    fontSize: "16px",
                  }}
                >
                  ₹{addOnDetails.mrp} / Month
                </Typography> */}

                <Typography
                  color="#334450"
                  variant="h6"
                  sx={{ fontWeight: "bold", textAlign: "center" }}
                >
                  ₹{addOnDetails.addOnSystemPrice} / Month
                </Typography>

                <Typography>
                  <ul>
                    {addOnDetails.addOnFeatures.map((feature) => (
                      <li key={feature.id}>{feature.addOnFeatureName}</li>
                    ))}
                  </ul>
                </Typography>

                <Box alignItems={"center"} textAlign={"center"} mt={3}>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={handleBackToPlans}
                  >
                    Back to Plans
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              fullWidth
              style={{
                borderBottom: "3px solid #BFBFBF",
                textAlign: "center",
                marginLeft: "1.4%",
                marginBottom: "2%",
                marginTop: "2%",
              }}
            ></Grid>

            <Grid item xs={12}>
              <Box>
                <Grid container spacing={2}>
                  {validityOptions.map((option) => {
                    const discountedPrice = calculateDiscountedPrice(
                      addOnDetails.addOnSystemPrice,
                      option.discount
                    );
                    return (
                      <Grid item xs={6} key={option.months} mt={0}>
                        <Box
                          style={{
                            borderRadius: "16px",
                            padding: "5px",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                            cursor: "pointer",
                            
                            background:
                              formData.validity === option.months
                                ? "#5E92F3"
                                : "white",
                            color: formData.validity === option.months ? "white" : "white",
                          }}
                          onClick={() => {
                            setFormData((prevData) => ({
                              ...prevData,
                              validity: option.months,
                              totalAmount: calculateTotalAmount(
                                prevData.studentRange,
                                option.months
                              ),
                            }));
                          }}
                        >
                          <Typography
                            color="#334450"
                            sx={{ textAlign: "center" }}
                          >
                            {option.months} Months
                          </Typography>
                          <Typography
                            color="#334450"
                            sx={{
                              textAlign: "center",
                              textDecoration: "line-through",
                            }}
                          >
                            ₹{addOnDetails.addOnSystemPrice} / Month
                          </Typography>

                          <Typography
                            color="#334450"
                            sx={{ textAlign: "center" }}
                          >
                            ₹{discountedPrice} / Month
                          </Typography>

                          <Typography
                            color="#334450"
                            sx={{ textAlign: "center" }}
                          >
                            ( {option.discount}% OFF )
                          </Typography>
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={7} className="textField-root" mt={1}>
          <Paper
            elevation={1}
            sx={{
              padding: "2%",
              backgroundColor: "white",
              boxShadow: "0px 4px 10px rgba(105, 104, 104, 0.6)",
            }}
          >
            <CardContent>
              <Typography
                variant="h6"
                padding={"10px"}
                fontWeight={"bold"}
                textAlign={"center"}
              >
                Price Breakdown
              </Typography>

              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography>Validity: </Typography>
                <Typography fontWeight={"bold"}>
                  {formData.validity} Months
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography>Base Price:</Typography>
                <Typography fontWeight={"bold"}>
                  ₹{addOnDetails.addOnSystemPrice}
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography>
                  Discount (
                  {formData.validity
                    ? validityOptions.find(
                        (option) => option.months === formData.validity
                      )?.discount
                    : 0}
                  %):
                </Typography>
                <Typography fontWeight={"bold"}>
                  ₹
                  {formData.validity
                    ? (
                        addOnDetails.addOnSystemPrice -
                        calculateDiscountedPrice(
                          addOnDetails.addOnSystemPrice,
                          validityOptions.find(
                            (option) => option.months === formData.validity
                          )?.discount
                        )
                      ).toFixed(2)
                    : 0}
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography>Discounted Price:</Typography>
                <Typography fontWeight={"bold"}>
                  ₹
                  {formData.validity
                    ? calculateDiscountedPrice(
                        addOnDetails.addOnSystemPrice,
                        validityOptions.find(
                          (option) => option.months === formData.validity
                        )?.discount
                      ).toFixed(2)
                    : addOnDetails.addOnSystemPrice.toFixed(2)}{" "}
                  / Month
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                fullWidth
                style={{
                  borderBottom: "3px solid #BFBFBF",
                  textAlign: "center",
                  marginLeft: "1.4%",
                  marginBottom: "2%",
                  marginTop: "2%",
                }}
              ></Grid>

              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography>Total Price (Excluding GST):</Typography>
                <Typography fontWeight={"bold"}>
                  ₹{formData.totalAmount}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography>GST (18%):</Typography>
                <Typography fontWeight={"bold"}>₹{totalGst}</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "space-between" }}
                mb={-1}
              >
                <Typography>Total Amount (Including GST):</Typography>
                <Typography fontWeight={"bold"}>
                  ₹{totalAmountIncludingGst}
                </Typography>
              </Grid>
            </CardContent>
          </Paper>
        </Grid>

        <Grid item xs={12} className="textField-root">
          <Paper
            mt={2}
            elevation={1}
            sx={{
              padding: "2%",
              backgroundColor: "rgba(224, 224, 224, 0.6)",
              boxShadow: "0px 4px 10px rgba(105, 104, 104, 0.6)",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper
                  elevation={1}
                  sx={{
                    padding: "2%",
                    backgroundColor: "rgba(224, 224, 224, 0.6)",
                    boxShadow: "0px 4px 10px rgba(105, 104, 104, 0.6)",
                    marginTop: "2.5%",
                  }}
                >
                  <Typography
                    variant="h6"
                    color="#00649F"
                    mb={2}
                    fontWeight={"bold"}
                  >
                    STEP 1] - Personal Details
                  </Typography>
                  <Grid container spacing={2}>
                    {[,{
                        label: "INST Name",
                        name: "institutename",
                        required: true,
                        xs: 3,
                      },
                      {
                        label: "Owner Name",
                        name: "ownerName",
                        required: true,
                        xs: 3,
                      },
                      { label: "INST Phone No.", name: "phonenumber", xs: 3 },
                      { label: "Owner Phone No.", name: "mobilenumber", xs: 3 },
                      { label: "Email Address", name: "emailaddress", xs: 3 },
                      { label: "Address", name: "address", xs: 3 },
                      { label: "City", name: "city", xs: 3 },
                      { label: "Landmark", name: "landmark", xs: 3 },
                      {
                        label: "Country",
                        name: "country",
                        xs: 3,
                        readOnly: true,
                      },
                      {
                        label: "State",
                        name: "state",
                        xs: 3,
                        select: true,
                        options: Object.keys(indianStatesAndDistricts),
                      },
                      {
                        label: "District",
                        name: "district",
                        xs: 3,
                        select: true,
                        options: districts,
                      },
                      { label: "Pincode", name: "pincode", xs: 3 },
                      {
                        label: "Password",
                        name: "password",
                        xs: 3,
                        type: "password",
                      },
                      {
                        label: "Confirm Password",
                        name: "confirmpassword",
                        xs: 3,
                        type: "password",
                      }
                    ].map(
                      ({
                        label,
                        name,
                        xs,
                        readOnly,
                        select,
                        options,
                        type,
                      }) => (
                        <Grid item xs={xs} key={name}>
                          <FormControl fullWidth>
                            <TextField
                              fullWidth
                              label={label}
                              name={name}
                              value={formData[name]}
                              onChange={handleInputChange}
                              size="small"
                              required
                              readOnly={readOnly}
                              select={select}
                              type={type}
                              error={!!validationErrors[name]}
                              helperText={validationErrors[name]}
                            >
                              {select &&
                                options.map((option) => (
                                  <MenuItem key={option} value={option}>
                                    {option}
                                  </MenuItem>
                                ))}
                            </TextField>
                          </FormControl>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Paper>
              </Grid>

              



              <Grid item xs={12}>
                <Paper
                  elevation={1}
                  sx={{
                    padding: "2%",
                    backgroundColor: "rgba(224, 224, 224, 0.6)",
                    boxShadow: "0px 4px 10px rgba(105, 104, 104, 0.6)",
                    marginTop: "2.5%",
                  }}
                >
                  <Typography
                    variant="h6"
                    color="#00649F"
                    mb={2}
                    fontWeight={"bold"}
                  >
                    STEP 2] - Payment Details
                  </Typography>
                  <Grid container spacing={2}>
                    {[
                      {
                        label: "System Name",
                        name: "systemName",
                        xs: 6,
                        sm: 2.4,
                        readOnly: true,
                      },
                      {
                        label: "Validity",
                        name: "validity",
                        xs: 6,
                        sm: 2.4,
                        readOnly: true,
                      },
                      {
                        label: "Amount",
                        name: "totalAmount",
                        xs: 6,
                        sm: 2.4,
                        readOnly: true,
                      },
                      {
                        label: "GST Amt.",
                        name: "totalGst",
                        xs: 6,
                        sm: 2.4,
                        readOnly: true,
                        value: totalGst,
                      },
                      {
                        label: "Total Amount (Including GST)",
                        name: "totalAmountIncludingGst",
                        xs: 6,
                        sm: 2.4,
                        readOnly: true,
                        value: totalAmountIncludingGst,
                      }
                    ].map((field) => (
                      <Grid
                        item
                        xs={field.xs}
                        sm={field.sm || 12}
                        key={field.name}
                      >
                        <TextField
                          fullWidth
                          label={field.label}
                          name={field.name}
                          size="small"
                          value={
                            field.value !== undefined
                              ? field.value
                              : formData[field.name]
                          }
                          onChange={handleInputChange}
                          required
                          InputProps={{
                            readOnly: field.readOnly || false,
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <div style={{ textAlign: "center", marginTop: "2%" }}>
              <button
                className="nextPage-button"
                variant="outlined"
                color="secondary"
                style={{ marginTop: "20px" }}
                onClick={handleBackToPlans}
              >
                Back
              </button>
              <button
                className="nextPage-button"
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                Buy System
              </button>
              {isSubmitting && (
                <div className="loading-overlay">
                  <CircularProgress />
                </div>
              )}
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default BuyAddOn;
