import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { fetchAddOns } from "./CreateINSTApi"; // Import the API function

export default function SelectAddOns() {
  const navigate = useNavigate();
  const [addOnSystems, setAddOnSystems] = useState([]);

  useEffect(() => {
    const getAddOnSystems = async () => {
      try {
        const data = await fetchAddOns(); // Use the API function
        setAddOnSystems(data);
      } catch (error) {
        console.error("Error fetching add-on systems:", error);
      }
    };

    getAddOnSystems();
  }, []);

  const validityOptions = [{ months: 48, discount: 40 }];

  const calculateDiscountedPrice = (price, discount) => {
    return price - price * (discount / 100);
  };

  return (
    <Grid container spacing={5}>
      {addOnSystems.map((addOn) => {
        // Calculate the savings
        const savings = addOn.mrp - addOn.addOnSystemPrice;
        const discountedPrice = calculateDiscountedPrice(
          addOn.addOnSystemPrice,
          40
        ); // Calculate discounted price for 40% off

        return (
          <Grid item xs={2.4} key={addOn.id}>
            <Card
              variant="contained"
              className="section-plan"
              style={{
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%", // Changed to 100%
              }}
            >
              <CardContent>
                <div className="heading">
                  <Typography
                    variant="h5" // Changed to h4 to match the second card
                    padding={"10px"}
                    color="#334450"
                    style={{fontWeight:'bold'}}
                  >
                    {addOn.addOnSystemName}{" "}
                    {/* Keeping the add-on system name */}
                  </Typography>

                  <Typography
                    color="#334450"
                    variant="h6"
                    sx={{ fontWeight: "bold", textDecoration: "line-through" }}
                  >
                    ₹{addOn.addOnSystemPrice}
                  </Typography>

                  <Typography sx={{ fontWeight: "bold"}}>(40% OFF)</Typography>

                  {/* Displaying the discounted price */}
                  <Typography
                    color="#334450"
                    variant="h6"
                    sx={{ fontWeight: "bold" }}
                  >
                    ₹{discountedPrice} / Month
                  </Typography>
                </div>

                <Box alignItems={"center"} textAlign={"center"}>
                  <button
                    className="details-button"
                    variant="contained"
                    size="small"
                    onClick={() =>
                      navigate(`/buyAddOn/${addOn.id}`)
                    }
                  >
                    Select
                  </button>
                </Box>

                {addOn.addOnFeatures.length > 0 ? (
                  addOn.addOnFeatures.map((feature) => (
                    <div key={feature.id}>
                      <Box className="feature-name">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            gap: "1rem",
                          }}
                        >
                          <li
                            fontSize={"18px"}
                            fontWeight={"bold"}
                            style={{ color: "#334450" }} // Adjusted color
                          >
                            {feature.addOnFeatureName}
                          </li>
                        </div>
                      </Box>
                    </div>
                  ))
                ) : (
                  <Typography variant="body2" style={{ fontStyle: "italic" }}>
                    No features available.
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
}
