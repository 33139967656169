import React from "react";
import { Link, Outlet, useLocation } from "react-router-dom";

const navItemStyle = {
  padding: "3px 8px",
  cursor: "pointer",
  fontSize: "14px",
  fontWeight: "bold",
  color: "white",
  textAlign: "center",
  flex: 1,
  transition: "background-color 0.3s ease, color 0.3s ease",
  textDecoration: "none", // Remove underline
};

// Styles for active item
const activeNavItemStyle = {
  ...navItemStyle,
   backgroundColor: "white",
 
  color: "black",
  borderRadius: "30px",
  transform: "scale(1)", // Slight increase for active state
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", // Subtle shadow for active
};

// Styles for the subnavbar container
const subNavBarStyle = {
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "5px",
  backgroundColor: "#2980B9",
  padding: "8px",
  borderRadius: "30px",
  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)", // Slight shadow for navbar
  marginBottom:"20px",
};

const AdmissionManagerLayout = () => {
  const location = useLocation();

  const isActive = (path) => location.pathname === path;

  return (
    <div>
      {/* Sub-navbar for the AdmissionManager */}
      <div style={subNavBarStyle}>
        <Link to="/layout/Ebook-manager" style={isActive("/layout/Ebook-manager") ? activeNavItemStyle : navItemStyle}>
          Dashboard
        </Link>
        <Link to="/layout/Ebook-manager/AddData" style={isActive("/layout/Ebook-manager/AddData") ? activeNavItemStyle : navItemStyle}>
        Add Material
        </Link>
        <Link to="/layout/Ebook-manager/AddMaterial" style={isActive("/layout/Ebook-manager/AddMaterial") ? activeNavItemStyle : navItemStyle}>
        Add Material Type
        </Link>
        <Link to="/layout/Ebook-manager/EbookReport" style={isActive("/layout/Ebook-manager/EbookReport") ? activeNavItemStyle : navItemStyle}>
        Material List
        </Link>
        <Link to="/layout/Ebook-manager/SalesList" style={isActive("/layout/Ebook-manager/SalesList") ? activeNavItemStyle : navItemStyle}>
        Sales List
        </Link>
        {/* <Link to="/layout/Admission-manager/add-source" style={isActive("/layout/Admission-manager/add-source") ? activeNavItemStyle : navItemStyle}>
          Add Source
        </Link>
        <Link to="/layout/Admission-manager/add-guide" style={isActive("/layout/Admission-manager/add-guide") ? activeNavItemStyle : navItemStyle}>
          Add Guide
        </Link> */}
      
      </div>

      {/* This is where the content of each route will be displayed */}
      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default AdmissionManagerLayout;
