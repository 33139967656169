

import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  IconButton,
  AppBar,
  Toolbar,
} from "@mui/material";
import "./ViewPlans.css";
import { useNavigate } from "react-router-dom";
import logo from "../../img/logo.jpg";
import {
  fetchSections,
  fetchPlansBySectionId,
  updateFeatures,
  fetchAddOns,
} from "./CreateINSTApi"; // Import API functions
import EditIcon from "@mui/icons-material/Edit";
import Swal from "sweetalert2"; // Import SweetAlert2
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import SelectAddOns from "./SelectAddOns";
import { Login as LoginIcon } from "@mui/icons-material";
import { color } from "d3";

export default function SelectPlans() {
  const navigate = useNavigate();
  const [sections, setSections] = useState([]);
  const [plans, setPlans] = useState([]);
  const [selectedSection, setSelectedSection] = useState(null);
  const [hoveredSection, setHoveredSection] = useState(null);
  const [editablePlanId, setEditablePlanId] = useState(null);
  const [addOns, setAddOns] = useState([]); // New state for add-ons
  const [showAddOns, setShowAddOns] = useState(false); // Toggle visibility for Add Ons

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchSections();
        setSections(data);

        if (data.length > 0) {
          const firstSection = data[0];
          setSelectedSection(firstSection);
          const plansData = await fetchPlansBySectionId(firstSection.id);
          setPlans(plansData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleSectionClick = async (section) => {
    setSelectedSection(section);
    setIsAddOnsSelected(false); // Clear Add Ons selection when a section is clicked
    try {
      const data = await fetchPlansBySectionId(section.id);
      setPlans(data);
      setShowAddOns(false); // Hide add-ons when changing sections
    } catch (error) {
      console.error("Failed to fetch plans:", error);
    }
  };

  const handleChoosePlan = (planId) => {
    navigate(`/${planId}`);
  };

  const handleEditClick = (planId) => {
    setEditablePlanId(planId);
  };

  const handleSaveChanges = async (featureId, enabled) => {
    try {
      const response = await updateFeatures(
        selectedSection.id,
        editablePlanId,
        featureId,
        enabled
      );
      console.log("Feature updated successfully:", response);

      // Show a SweetAlert notification
      Swal.fire({
        title: "Success!",
        text: `Feature successfully updated for plan: ${editablePlanId}`,
        icon: "success",
        confirmButtonText: "Okay",
      });

      // Update local state to reflect the change
      setPlans((prevPlans) =>
        prevPlans.map((plan) => {
          if (plan.id === editablePlanId) {
            return {
              ...plan,
              features: plan.features.map((feature) =>
                feature.id === featureId ? { ...feature, enabled } : feature
              ),
            };
          }
          return plan;
        })
      );
    } catch (error) {
      console.error("Failed to update feature:", error);
    }
  };

  const [showSubFeatures, setShowSubFeatures] = useState(false); // New state for subfeature visibility

  const handleToggleSubFeatures = () => {
    setShowSubFeatures((prev) => !prev); // Toggle subfeature visibility
  };

  const [isAddOnsSelected, setIsAddOnsSelected] = useState(false); // New state for Add Ons selection

  const handleFetchAddOns = async () => {
    try {
      const data = await fetchAddOns();
      setAddOns(data);
      setShowAddOns(true); // Show add-ons
      setPlans([]); // Clear plans when showing add-ons
      setIsAddOnsSelected(true); // Set Add Ons as selected
      setSelectedSection(null); // Deselect other sections
    } catch (error) {
      console.error("Failed to fetch add-ons:", error);
    }
  };

  return (
    <div className="create-account">
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          background: "#00649F",
        }}
      >
        <Toolbar>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item display="flex" alignItems="center">
              <img
                src={logo}
                alt="Logo"
                style={{ marginRight: "8px", height: "30px" }}
              />
              <Typography variant="h5" color="white" fontWeight="bold">
                PJSOFTTECH
              </Typography>
            </Grid>
          </Grid>
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => navigate("/")}
            sx={{ position: "absolute", right: 16 }}
          >
            <LoginIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <div style={{ padding: "10px", marginTop: "-38px" }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-evenly"
          marginTop={2}
        >
          <Box
            spacing={2}
            className="section-names"
            display="flex"
            justifyContent={"center"}
            textAlign={"center"}
            alignItems={"center"}
            marginTop={1}
          >
            {sections.map((section) => (
              <Box
                item
                key={section.id}
                onMouseEnter={() => setHoveredSection(section.id)}
                onMouseLeave={() => setHoveredSection(null)}
              >
                <Typography
                  className={`section-name-1 ${
                    selectedSection && selectedSection.id === section.id
                      ? "selected"
                      : ""
                  } ${hoveredSection === section.id ? "hovered" : ""}`}
                  onClick={() => handleSectionClick(section)}
                  marginRight={"10px"}
                  style={{ cursor: "pointer" }}
                >
                  {section.sectionName}
                </Typography>
              </Box>
            ))}
            <Box
              item
              color={"black"}
              onMouseEnter={() => setHoveredSection("add-ons")}
              onMouseLeave={() => setHoveredSection(null)}
              className={`section-names ${isAddOnsSelected ? "selected" : ""}`} // Apply selected class if Add Ons is selected
              onClick={handleFetchAddOns} // Fetch Add Ons on click
              style={{ cursor: "pointer", color: "black" }}
            >
              <Typography
                className={`section-name-1 ${
                  isAddOnsSelected ? "selected" : ""
                } ${hoveredSection === "add-ons" ? "hovered" : ""}`}
              >
                Add Ons
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box marginTop={2} marginBottom={2} alignContent={"center"}>
          {selectedSection && !showAddOns && (
            <>
              <Typography variant="h5" align="center" mb={2}>
                Select plan To Continue
              </Typography>
              <Grid
                container
                spacing={5}
                justifyContent="center"
                alignItems="stretch"
              >
                {plans.map((plan) => (
                  <Grid item xs={4} key={plan.id}>
                    <Card
                      variant="contained"
                      className="section-plan"
                      style={{
                        borderRadius: "8px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        height: "100%",
                      }}
                    >
                      <CardContent>
                        <div className="heading">
                          <Typography
                            variant="h4"
                            padding={"10px"}
                            color="#334450"
                          >
                            {plan.planName}
                          </Typography>
                         
                          {/* <Typography
                            variant="body1"
                            color="#334450"
                          ></Typography> */}
                          <Typography
                            color="#334450"
                            variant="h6"
                            sx={{ fontWeight: "bold" }}
                          >
                            {selectedSection.sectionName.includes(
                              "Institute"
                            ) ||
                            selectedSection.sectionName.includes("Academy") ? (
                              <>₹{plan.mrp} / Month</>
                            ) : (
                              <>
                                ₹{plan.perEntry} /{" "}
                                {selectedSection.sectionName.includes(
                                  "School"
                                ) ||
                                selectedSection.sectionName.includes("College")
                                  ? "Student"
                                  : selectedSection.sectionName.includes(
                                      "Organization"
                                    ) ||
                                    selectedSection.sectionName.includes(
                                      "Company"
                                    )
                                  ? "Employee"
                                  : "Entry"}{" "}
                                / Month
                              </>
                            )}
                          </Typography>
                        </div>

                        <Box alignItems={"center"} textAlign={"center"}>
                          <button
                            className="details-button"
                            variant="contained"
                            size="small"
                            onClick={() => handleChoosePlan(plan.id)}
                          >
                            Select Plan
                          </button>
                        </Box>

                        {plan.features
                          .sort((a, b) => a.id - b.id)
                          .map((feature) => (
                            <div key={feature.id}>
                              <Box className="feature-name">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center", // Align items vertically
                                    gap: "1rem", // Optional: Add spacing between elements
                                  }}
                                >{editablePlanId === plan.id ? (
                                  <select
                                    value={
                                      feature.enabled ? "Enabled" : "Disabled"
                                    }
                                    onChange={(e) =>
                                      handleSaveChanges(
                                        feature.id,
                                        e.target.value === "Enabled"
                                      )
                                    }
                                    style={{
                                      padding: "0.3rem",
                                      fontSize: "0.875rem", // Match Typography font size
                                    }}
                                  >
                                    <option value="Enabled">Enabled</option>
                                    <option value="Disabled">Disabled</option>
                                  </select>
                                ) : feature.enabled ? (
                                  <DoneIcon style={{ color: "#334450" }} />
                                ) : (
                                  <ClearIcon style={{ color: "red" }} />
                                )}
                                  <Typography
                                    align="left"
                                    fontSize={'15px'}
                                    fontWeight={"bold"}
                                    style={{ marginLeft: "-4%",

                                      color: feature.enabled ? "#334450" : "red",}} // Conditional color }} // Inline styling for margin
                                  >
                                    {feature.featureName}
                                  </Typography>

                                  
                                </div>

                                {showSubFeatures &&
                                  feature.subFeatures.length > 0 && (
                                    <Box>
                                      <ul>
                                        {feature.subFeatures.map(
                                          (subFeature) => (
                                            <>
                                              <Typography  key={subFeature.id}
                                              fontSize={'13px'}
                                              align="left"
                                                variant="body2"
                                                fontWeight={"bold"}
                                                marginLeft={'-5px'}
                                              >
                                                {subFeature.subFeatureName}
                                              </Typography>
                                            </>
                                          )
                                        )}
                                      </ul>
                                    </Box>
                                  )}
                              </Box>
                            </div>
                          ))}
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </Box>
        {showAddOns && (
          <>
            <Typography
              variant="h5"
              align="center"
              padding={"1%"}
              marginTop={"-2%"}
            >
              Select plan To Continue
            </Typography>
            <Grid
              container
              spacing={5}
              justifyContent="center"
              alignItems="stretch"
            >
              <Grid item xs={12}>
                <Box sx={{ textAlign: "center", justifyContent: "center" }}>
                  <SelectAddOns addOns={addOns} />{" "}
                  {/* Pass the addOn data to the component */}
                </Box>
              </Grid>
            </Grid>
          </>
        )}
        <Box
  display="flex"
  flexDirection="column"
  alignItems="center"
  justifyContent="center"
  marginTop={2}
>
  <button
    className="nextPage-button"
    onClick={handleToggleSubFeatures}
    disabled={isAddOnsSelected} // Disable the button if add-ons are selected
    style={{
      backgroundColor: isAddOnsSelected ? '#ccc' : '#00649F', // Change background color if disabled
      cursor: isAddOnsSelected ? 'not-allowed' : 'pointer', // Change cursor style if disabled
      opacity: isAddOnsSelected ? 0.6 : 1, // Change opacity if disabled
    }}
  >
    {showSubFeatures ? "Hide Details" : "Show Details"}
  </button>
</Box>

      </div>
    </div>
  );
}
