import React, { useState } from "react";
import {
  Button,
  Grid,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import Swal from "sweetalert2";
import { addCategory } from './API/AllApi';
import { HashLoader } from "react-spinners"; // Import HashLoader
import './LoadingAnimation.css'
const EbookAddCategory = () => {
  const [type, setType] = useState("");
  const [name, setName] = useState("");
  const [thumbnailFile, setThumbnailFile] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state

  const handleAddCategorySubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

    const formData = new FormData();
    formData.append("name", name);
    formData.append("thumbnail", thumbnailFile);
    formData.append("createdDate", new Date().toISOString().split("T")[0]);

    try {
      const response = await addCategory(formData);
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: 'Category added successfully!',
      });
      resetAddCategoryForm();
    } catch (error) {
      const errorMessage = error.message || "Failed to add category!";
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: errorMessage,
      });
    } finally {
      setLoading(false); // Stop loading
      setOpenDialog(false);
    }
  };

  const resetAddCategoryForm = () => {
    setName("");
    setType("");
    setThumbnailFile(null);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    resetAddCategoryForm();
  };

  return (
    <div>
      {/* Add Category Button */}
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpenDialog}
        sx={{ marginBottom: "16px" }}
      >
        Add Category
      </Button>

      {/* Dialog for Adding Category */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Add New Category</DialogTitle>
        <DialogContent>
          <form onSubmit={handleAddCategorySubmit}>
            <TextField
              label="Category Name"
              variant="outlined"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              style={{ marginTop: "16px" }}
            />

            <Grid item xs>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <Typography variant="subtitle2" style={{ marginRight: "8px" }}>
                  Upload Thumbnail (png/jpg)
                </Typography>
                <input
                  type="file"
                  accept=".jpg,.png"
                  onChange={(e) => setThumbnailFile(e.target.files[0])}
                  required
                  style={{ display: "none" }}
                  id="thumbnail-upload-category"
                />
                <label htmlFor="thumbnail-upload-category">
                  <Button
                    variant="contained"
                    color="error"
                    component="span"
                    startIcon={<ImageIcon />}
                  >
                    Cover
                  </Button>
                </label>
              </div>
            </Grid>

            {/* Loading Animation */}
            {loading && (
              <div className="loader-overlay" style={{ textAlign: 'center', marginTop: '20px' }}>
                <HashLoader
                  color="#00649F"
                  size={80}
                />
                <Typography variant="h5" color="white" mt={2}>
                  Adding category, please wait...
                </Typography>
              </div>
            )}

            <DialogActions>
              <Button onClick={handleCloseDialog} color="secondary" disabled={loading}>
                Cancel
              </Button>
              <Button type="submit" color="primary" disabled={loading}>
                Submit
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EbookAddCategory;
