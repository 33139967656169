import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import {
  Container,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Modal,
  Box,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { SketchPicker } from "react-color";

export default function MenuBar() {
  const [menuBars, setMenuBars] = useState([]);
  const [manuBarColor, setMenuBarColor] = useState("#ffffff");
  const [instituteCode, setInstituteCode] = useState(() => localStorage.getItem("institutecode") || "");
  const [menubarImage, setMenubarImage] = useState(null);
  const [editManuBar, setEditManuBar] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false); // For displaying image in a modal
  const [selectedImage, setSelectedImage] = useState(null); // To store the selected image for preview

  useEffect(() => {
    fetchMenuBars();
  }, []);

  const fetchMenuBars = async () => {
    try {
      const response = await axios.get("https://pjsofttech.in:28443/getAllManuBars");
      setMenuBars(response.data);
    } catch (error) {
      console.error("Error fetching menu bars:", error);
    }
  };

  const handleColorChange = (color) => {
    setMenuBarColor(color.hex);
  };

  const handleCreateMenuBar = async () => {
    const formData = new FormData();
    formData.append("manuBarColor", manuBarColor);
    formData.append("institutecode", instituteCode);

    if (menubarImage) {
      formData.append("menubarImage", menubarImage);
    }

    try {
      const response = await axios.post("https://pjsofttech.in:28443/createManuBar", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      setMenuBarColor("#ffffff");
      setInstituteCode(localStorage.getItem("institutecode") || "");
      setMenubarImage(null);
      setOpenCreateDialog(false);
      fetchMenuBars();
      Swal.fire("Success", "Menu Bar created successfully!", "success");
    } catch (error) {
      console.error("Error creating menu bar:", error);
      Swal.fire("Error", "Failed to create Menu Bar!", "error");
    }
  };

  const handleUpdateMenuBar = async () => {
    if (!editManuBar) return;

    const formData = new FormData();
    formData.append("manuBarColor", manuBarColor);
    formData.append("institutecode", instituteCode);

    if (menubarImage) {
      formData.append("menubarImage", menubarImage);
    }

    try {
      await axios.put(
        `https://pjsofttech.in:28443/updateManuBarByInstitutecode`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      setMenuBarColor("#ffffff");
      setInstituteCode(localStorage.getItem("institutecode") || "");
      setMenubarImage(null);
      setEditManuBar(null);
      setOpenEditDialog(false);
      fetchMenuBars();
      Swal.fire("Success", "Menu Bar updated successfully!", "success");
    } catch (error) {
      console.error("Error updating menu bar:", error);
      Swal.fire("Error", "Failed to update Menu Bar!", "error");
    }
  };

  const handleDeleteMenuBar = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "This action cannot be undone!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel"
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`https://pjsofttech.in:28443/deleteManuBar/${id}`);
          fetchMenuBars();
          Swal.fire("Deleted!", "Menu Bar has been deleted.", "success");
        } catch (error) {
          console.error("Error deleting menu bar:", error);
          Swal.fire("Error", "Failed to delete Menu Bar!", "error");
        }
      }
    });
  };


  const openEditMenuBarDialog = (menuBar) => {
    setEditManuBar(menuBar);
    setMenuBarColor(menuBar.manuBarColor);
    setInstituteCode(menuBar.institutecode);
    setMenubarImage(null); // Reset the image if you don't want to show the previous image during edit
    setOpenEditDialog(true);
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setOpenImageModal(true);
  };

  const handleCloseImageModal = () => {
    setOpenImageModal(false);
    setSelectedImage(null); // Reset the selected image
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Menu Bar
      </Typography>

      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpenCreateDialog(true)}
      >
        Create New MenuBar
      </Button>

      {/* Create Dialog */}
      <Dialog open={openCreateDialog} onClose={() => setOpenCreateDialog(false)} className="textField-root">
        <DialogTitle color={'black'}>Create New Menu Bar</DialogTitle>
        <DialogContent>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
  {/* Sketch Picker for color selection */}
  <SketchPicker color={manuBarColor} onChangeComplete={handleColorChange} />

  {/* Box to preview selected color and label below it */}
  <div style={{ textAlign: 'center' }}>
    <div
      style={{
        width: '150px',
        height: '150px',
        backgroundColor: manuBarColor,
        border: '1px solid #ccc',
        borderRadius: '50%',
        marginBottom: '10px', // Adds space between the circle and the label
      }}
    />
    Selected Color
  </div>
</div>
<br />

          <input
            type="file"
            accept="image/*"
            onChange={(e) => setMenubarImage(e.target.files[0])}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCreateDialog(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleCreateMenuBar} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>

      <Typography variant="h5" gutterBottom style={{ marginTop: "20px" }}>
        Existing Menu Bars
      </Typography>

      <TableContainer component={Paper} className="table-root">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Color</TableCell>
              <TableCell>Img</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {menuBars.map((menuBar) => (
              <TableRow key={menuBar.manuBarId}>
                <TableCell>{menuBar.manuBarId}</TableCell>
                <TableCell>
                  <div
                    style={{
                      width: "20px",
                      height: "20px",
                      backgroundColor: menuBar.manuBarColor,
                      display: "inline-block",
                      marginRight: "5px",
                    }}
                  />
                  {menuBar.manuBarColor}
                </TableCell>
                <TableCell>
                  <img
                    src={menuBar.menubarImage}
                    alt={`Menu Bar ${menuBar.manuBarId}`}
                    width="100px"
                    height="auto"
                    style={{ borderRadius: "4px", cursor: "pointer" }}
                    onClick={() => handleImageClick(menuBar.menubarImage)} // Open the modal on click
                  />
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => openEditMenuBarDialog(menuBar)}
                    color="primary"
                    style={{ marginRight: "10px" }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => handleDeleteMenuBar(menuBar.manuBarId)}
                    sx={{ color: "red" }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Edit Dialog */}
      <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
        <DialogTitle color={'black'}>Edit Menu Bar</DialogTitle>
        <DialogContent>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
  {/* Sketch Picker for color selection */}
  <SketchPicker color={manuBarColor} onChangeComplete={handleColorChange} />

  {/* Box to preview selected color and label below it */}
  <div style={{ textAlign: 'center' }}>
    <div
      style={{
        width: '150px',
        height: '150px',
        backgroundColor: manuBarColor,
        border: '1px solid #ccc',
        borderRadius: '50%',
        marginBottom: '10px', // Adds space between the circle and the label
      }}
    />
    Selected Color
  </div>
</div>
<br />
          <input
            type="file"
            accept="image/*"
            onChange={(e) => setMenubarImage(e.target.files[0])}
          />
          {menubarImage && (
            <img
              src={URL.createObjectURL(menubarImage)}
              alt="Preview"
              style={{
                width: "100px",
                height: "auto",
                marginTop: "10px",
                borderRadius: "4px",
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditDialog(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleUpdateMenuBar} color="primary">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      {/* Image Modal */}
      <Modal
        open={openImageModal}
        onClose={handleCloseImageModal} // Close modal when clicking outside
        aria-labelledby="image-modal-title"
        aria-describedby="image-modal-description"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
          }}
        >
          <Box
            sx={{
              position: "relative",
              maxWidth: "80%",
              maxHeight: "80%",
            }}
          >
            <img
              src={selectedImage}
              alt="Full Image"
              style={{
                width: "80%",
                height: "auto",
                borderRadius: "8px",
              }}
            />
            <IconButton
              onClick={handleCloseImageModal}
              color="primary"
              sx={{
                position: "absolute",
                top: "10px",
                right: "10px",
                zIndex: 10,
              }}
            >
              X
            </IconButton>
          </Box>
        </Box>
      </Modal>
    </Container>
  );
}
