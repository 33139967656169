import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Grid,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { getTypes, deleteType, addType, handleDeleteType } from "./API/ShipmentAPI";

const MySwal = withReactContent(Swal);

const Type = () => {
  const { id } = useParams(); // Make sure id is correctly used

  const [types, setTypes] = useState([]);
  const [filteredTypes, setFilteredTypes] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [openAddDialog, setOpenAddDialog] = useState(false); // For add type dialog
  const [type, setType] = useState({ typeName: "" });

  const institutecode = localStorage.getItem("institutecode") || "";

  useEffect(() => {
    loadTypes();
  }, [institutecode]);

  useEffect(() => {
    filterTypes();
  }, [searchTerm, types]);

  const loadTypes = async () => {
    try {
      const data = await getTypes(institutecode);
      setTypes(data);
      setFilteredTypes(data);
    } catch (error) {
      MySwal.fire({
        title: 'Error!',
        text: 'Failed to load types.',
        icon: 'error',
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  const filterTypes = () => {
    const filtered = types.filter((type) => {
      // Check for undefined or null values before calling toLowerCase
      if (type.typeName && type.typeName.toLowerCase) {
        return type.typeName.toLowerCase().includes(searchTerm.toLowerCase());
      }
      return false; // Skip invalid types without a typeName
    });
    setFilteredTypes(filtered);
  };

  const handleDeleteType = (type) => {
    MySwal.fire({
      title: 'Are you sure?',
      text: `Do you want to delete type: ${type.typeName}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteTypeHandler(type.id);
      }
    });
  };

  const deleteTypeHandler = async (typeId) => {
    try {
      await deleteType(typeId);
      MySwal.fire({
        title: 'Deleted!',
        text: 'The type has been deleted successfully.',
        icon: 'success',
        timer: 1500,
        showConfirmButton: false,
      });
      loadTypes();
    } catch (error) {
      MySwal.fire({
        title: 'Error!',
        text: 'There was an error deleting the type.',
        icon: 'error',
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };


  const handleOpenAddDialog = () => {
    setType({ typeName: "" });
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
  };

  const onAddTypeSubmit = async (e) => {
    e.preventDefault();
    if (!type.typeName) {
      MySwal.fire({
        title: 'Error!',
        text: 'Please fill out the necessary fields.',
        icon: 'error',
        timer: 2000,
        showConfirmButton: false,
      });
      return;
    }

    try {
      await addType(type, institutecode);
      MySwal.fire({
        title: 'Success!',
        text: 'The type has been added successfully.',
        icon: 'success',
        timer: 1500,
        showConfirmButton: false,
      });
      handleCloseAddDialog();
      loadTypes();
    } catch (error) {
      MySwal.fire({
        title: 'Error!',
        text: 'There was an error adding the type.',
        icon: 'error',
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  return (
    <div sx={{ marginTop: "10px" }}>
      <Grid container spacing={2} sx={{ marginTop: "10px" }}>
        <Typography
          variant="h6"
          gutterBottom
          sx={{ marginTop: 3, whiteSpace: "nowrap" }}
        >
          Total Product Types: {filteredTypes.length}
        </Typography>

        <Grid item xs={12} sm={1.6} className="textField-root">
          <TextField
            label="Search Product Type"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenAddDialog}
            sx={{ marginTop: 1 }}
          >
            Add Product Type
          </Button>
        </Grid>
      </Grid>

      <Box mt={2}>
        <TableContainer>
          <Table className="table-root">
            <TableHead>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>Product Type</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredTypes.map((type, index) => (
                <TableRow key={type.id}>
                  <TableCell>{type.id}</TableCell>
                  <TableCell>{type.typeName}</TableCell>
                  <TableCell>
                    <Button
                      color="error"
                      onClick={() => handleDeleteType(type)}
                      size="small"
                      variant="contained"
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Add Type Dialog */}
      <Dialog open={openAddDialog} onClose={handleCloseAddDialog} className="textField-root">
        <DialogTitle color={'black'}>Add Product Type</DialogTitle>
        <DialogContent>
          <form onSubmit={onAddTypeSubmit}>
            <TextField
              autoFocus
              margin="dense"
              label="Type Name"
              fullWidth
              variant="outlined"
              value={type.typeName} // Make sure it refers to typeName
              onChange={(e) => setType({ ...type, typeName: e.target.value })} // Update typeName
            />

            <DialogActions>
              <Button onClick={handleCloseAddDialog} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Add
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Type;
