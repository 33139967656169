import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Grid,
  Paper,
  MenuItem,
  Box,
  IconButton,
} from "@mui/material";
import Calendar from "react-calendar"; // Import react-calendar
import "react-calendar/dist/Calendar.css"; // Import calendar styles
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Delete, Edit } from "@mui/icons-material";

const MySwal = withReactContent(Swal);

const ManageHoliday = () => {
  const [open, setOpen] = useState(false);
  const [holidayName, setHolidayName] = useState("");
  const [date, setDate] = useState("");
  const [day, setDay] = useState("");
  const [users, setUsers] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [paidHoliday, setPaidHoliday] = useState(true); // Default to Paid
  const [error, setError] = useState("");
  const [editUser, setEditUser] = useState({ id: null, holidayName: "", date: "", day: "", paidHoliday: true});
  const [editOpen, setEditOpen] = useState(false);
  const [search, setSearch] = useState("");
  const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  const getInstituteCode = () => localStorage.getItem("institutecode");

  // Fetch all holidays on component load
  useEffect(() => {
    fetchHolidays();
  }, []);

  const fetchHolidays = async () => {
    try {
      const response = await fetch(
        `https://pjsofttech.in:10443/getAllHolidays?institutecode=${getInstituteCode()}`
      );
      const result = await response.json();
      setUsers(result);
      setHolidays(result.map((holiday) => new Date(holiday.date)));
    } catch (error) {
      console.error("Error fetching holidays:", error);
    }
  };

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setHolidayName("");
    setDate("");
    setDay("");
    setError("");
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  // Filter holidays by holidayName based on search input
  const filteredHolidays = users.filter((user) =>
    user.holidayName.toLowerCase().includes(search.toLowerCase())
  );

  const handleAddHoliday = async () => {
    if (!holidayName || !date || !day) {
      setError("All fields are required!");
      return;
    }
    try {
      const response = await fetch(
        `https://pjsofttech.in:10443/addHoliday?institutecode=${getInstituteCode()}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ holidayName, date, day, paidHoliday}),
        }
      );

      if (response.ok) {
        MySwal.fire("Success", "Holiday Added Successfully", "success");

        const newHoliday = { id: Date.now(), holidayName, date, day, paidHoliday};
        setUsers((prevUsers) => [...prevUsers, newHoliday]);
        setHolidays((prevHolidays) => [...prevHolidays, new Date(date)]);

        handleClose();
      } else {
        setError("Failed to add holiday.");
      }
    } catch (error) {
      console.error("Error adding holiday:", error);
      setError("Failed to add holiday.");
    }
  };

  const handleEditClickOpen = async (id) => {
    try {
      const response = await fetch(`https://pjsofttech.in:10443/getHolidayById/${id}`);
      const result = await response.json();
      setEditUser(result);
      setEditOpen(true);
    } catch (error) {
      console.error("Error fetching user details: ", error);
    }
  };

  const handleEditClose = () => {
    setEditOpen(false);
    setEditUser({ id: null, holidayName: "", date: "", day: "", paidHoliday: true});
    setError("");
  };

  const handleEditChange = (event) => {
    setEditUser({ ...editUser, holidayName: event.target.value });
  };

  const handleEditDateChange = (event) => {
    setEditUser({ ...editUser, date: event.target.value });
  };

  const handleEditDayChange = (event) => {
    setEditUser({ ...editUser, day: event.target.value });
  };

  const handleEditPaidHolidayChange = (event) => {
    setEditUser({ ...editUser, paidHoliday: event.target.value === "true" });
  };

  const handleUpdate = async (id) => {
    if (editUser.holidayName.trim() === "") {
      setError("User name cannot be empty");
    } else if (!editUser.date) {
      setError("Date cannot be empty");
    } else if (!editUser.day) {
      setError("Day cannot be empty");
    } else {
      try {
        const response = await fetch(
          `https://pjsofttech.in:10443/updateHoliday/${id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              holidayName: editUser.holidayName.trim(),
              date: editUser.date,
              day: editUser.day,
              paidHoliday: editUser.paidHoliday,
              institutecode: getInstituteCode(),
            }),
          }
        );
        if (response.ok) {
          const updatedResponse = await fetch(
            `https://pjsofttech.in:10443/getAllHolidays?institutecode=${getInstituteCode()}`
          );
          const updatedUser = await updatedResponse.json();
          setUsers(updatedUser);
          MySwal.fire("Success", "Holiday Updated Successfully", "success");
          handleEditClose();
        } else {
          setError("Failed to update user");
        }
      } catch (error) {
        console.error("Error updating user: ", error);
        setError("Failed to update user");
        MySwal.fire("Error","Failed to update user","error");
      }
    }
  };

  const handleDelete = async (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await fetch(`https://pjsofttech.in:10443/deleteHoliday/${id}`, {
            method: "DELETE",
          });
  
          if (response.ok) {
            const updatedResponse = await fetch(
              `https://pjsofttech.in:10443/getAllHolidays?institutecode=${getInstituteCode()}`
            );
            const updatedUser = await updatedResponse.json();
            setUsers(updatedUser);
            MySwal.fire("Deleted!", "The holiday has been deleted.", "success");
          } else {
            MySwal.fire("Error", "Failed to delete the holiday.", "error");
          }
        } catch (error) {
          MySwal.fire("Error", "An error occurred while deleting the holiday.", "error");
        }
      }
    });
  };

    // Inline style for paid holiday color
    const paidHolidayStyle = (paidHoliday) => ({
    color: paidHoliday ? "green" : "red", // Green for Paid, Red for Unpaid
    });
  

  // Inline style for holiday highlight
  const holidayHighlightStyle = {
    backgroundColor: "#FF0000", // Red background for holidays
    color: "white",
  };

  // Highlight holiday dates on the calendar
  const tileClassName = ({ date, view }) => {
    // Check if the date matches any holiday
    if (
      holidays.some(
        (holidayDate) =>
          holidayDate.getFullYear() === date.getFullYear() &&
          holidayDate.getMonth() === date.getMonth() &&
          holidayDate.getDate() === date.getDate()
      )
    ) {
      return (
        <div style={holidayHighlightStyle}>
          {date.getDate()}
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      <Grid container spacing={2} style={{ marginTop: "20px" }}>
        {/* Left Side - Calendar */}
        <Grid item xs={4}>
          <Paper elevation={3} style={{ padding: "20px" }}>
          <Box
          sx={{
            width: "100%",
            maxWidth: "600px", // Maximum width for larger screens
            "& .react-calendar": {
              width: "100%", // Calendar width is dynamic
            },
          }}
        >
            <Calendar tileContent={tileClassName} /> {/* Highlight holidays */}
            </Box>
          </Paper>
        </Grid>

        {/* Right Side - Holiday Management Table and Add Form */}
        <Grid item xs={8}>
          <Paper elevation={3} style={{ padding: "20px", width: "100%" }}>
            <Typography variant="h6" gutterBottom sx={{ marginTop: 3, whiteSpace: "nowrap" }}>
              Total Holidays: {users.length}
            </Typography>
            <Grid container spacing={2} className="textField-root">
            <Grid item xs={12} sm={4}>
                <TextField
                  label="Search Holiday"
                  variant="outlined"
                  fullWidth
                  value={search}
                  onChange={handleSearchChange}
                />
              </Grid>
              <Grid item xs={12} sm={4.5}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickOpen}
            >
              Add Holiday
            </Button>
            </Grid>
            </Grid>
            <TableContainer>
              <Table sx={{ minWidth: 300, marginTop: 3, width: "100%" }}> {/* Ensures full width */}
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ padding: "4px", fontWeight: "bold", backgroundColor: "#f5f5f5" }}>No</TableCell>
                    <TableCell sx={{ padding: "4px", fontWeight: "bold", backgroundColor: "#f5f5f5" }}>Holiday Name</TableCell>
                    <TableCell sx={{ padding: "4px", fontWeight: "bold", backgroundColor: "#f5f5f5" }}>Holiday Date</TableCell>
                    <TableCell sx={{ padding: "4px", fontWeight: "bold", backgroundColor: "#f5f5f5" }}>Holiday Day</TableCell>
                    <TableCell sx={{ padding: "4px", fontWeight: "bold", backgroundColor: "#f5f5f5" }}>Holiday Type</TableCell>
                    <TableCell sx={{ padding: "4px", fontWeight: "bold", backgroundColor: "#f5f5f5" }}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredHolidays.map((user, index) => (
                    <TableRow key={user.id}>
                      <TableCell sx={{ padding: "4px" }}>{index + 1}</TableCell>
                      <TableCell sx={{ padding: "4px" }}>{user.holidayName}</TableCell>
                      <TableCell sx={{ padding: "4px" }}>{user.date}</TableCell>
                      <TableCell sx={{ padding: "4px" }}>{user.day}</TableCell>
                      <TableCell style={paidHolidayStyle(user.paidHoliday)} sx={{fontWeight: "bold"}}>
                        {user.paidHoliday ? "Paid" : "Unpaid"}
                      </TableCell>
                      <TableCell sx={{ padding: "4px" }}>
                  <IconButton onClick={() => handleEditClickOpen(user.id)} color="primary">
                    <Edit />
                  </IconButton>
                  <IconButton
  onClick={() => {
    handleDelete(user.id);
  }}
  color="error"
>
  <Delete />
</IconButton>

                </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>

      {/* Add Holiday Dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New Holiday</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Holiday Name"
            type="text"
            fullWidth
            variant="outlined"
            value={holidayName}
            onChange={(e) => setHolidayName(e.target.value)}
            className="textField-root"
          />
           <TextField
            select
            margin="dense"
            className="textField-root"
            label="Holiday Type"
            value={paidHoliday}
            onChange={(e) => setPaidHoliday(e.target.value === "true")}
            fullWidth
            variant="outlined"
          >
            <MenuItem value="true">Paid</MenuItem>
            <MenuItem value="false">Unpaid</MenuItem>
          </TextField>
          <TextField
            autoFocus
            margin="dense"
            label="Holiday Date"
            type="date"
            fullWidth
            variant="outlined"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            className="textField-root"
          />
          <TextField
            margin="dense"
            select
            label="Holiday Day"
            value={day}
            onChange={(e) => setDay(e.target.value)}
            fullWidth
            variant="outlined"
             className="textField-root"
          >
            {["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"].map(
              (dayOption) => (
                <MenuItem key={dayOption} value={dayOption}>
                  {dayOption}
                </MenuItem>
              )
            )}
          </TextField>
          {error && <Typography color="error">{error}</Typography>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddHoliday} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={editOpen} onClose={handleEditClose}>
        <DialogTitle>Edit User</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Holiday Name"
            type="text"
            fullWidth
            variant="outlined"
            value={editUser.holidayName}
            onChange={handleEditChange}
            error={!!error}
            helperText={error}
            className="textField-root"
          />
            <TextField
            select
            margin="dense"
            label="Holiday Type"
            value={editUser.paidHoliday ? "true" : "false"}
            onChange={handleEditPaidHolidayChange}
            className="textField-root"
            fullWidth
            variant="outlined"
          >
            <MenuItem value="true">Paid</MenuItem>
            <MenuItem value="false">Unpaid</MenuItem>
          </TextField>
          <TextField
            autoFocus
            margin="dense"
            label="Holiday Date"
            type="date"
            fullWidth
            variant="outlined"
            value={editUser.date}
            onChange={handleEditDateChange}
            InputLabelProps={{
              shrink: true,
            }}
            error={!!error}
            helperText={error}
            className="textField-root"
          />
          <TextField
          margin="dense"
            select
            label="Holiday Day"
            value={editUser.day}
            onChange={handleEditDayChange}
            fullWidth
            variant="outlined"
             className="textField-root"
          >
            {days.map((day) => (
              <MenuItem key={day} value={day}>
                {day}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleUpdate(editUser.id)} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
};

export default ManageHoliday;
