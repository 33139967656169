import React, { useState } from "react";
import { Box, Paper, Typography } from "@mui/material";
import SchoolIcon from "@mui/icons-material/School";
import SubjectIcon from "@mui/icons-material/Subject";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import TeacherExam from "./TeacherExam";
import AddSubject from "./AddSubject";
import HourlyRate from "./HourlyRate";
import e from "cors";

const menuItems = [
  { id: "add-exam", label: "Add Exam", icon: <SchoolIcon /> },
  { id: "add-subject", label: "Add Subject", icon: <SubjectIcon /> },
  { id: "hourly-rate", label: "Add Hourly Rate", icon: <AccessTimeIcon /> },
];  

const Sidebar =({menuItems, selectedComponent, setSelectedComponent}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);


  return (
    <Paper
      elevation={3}
      sx={{
        width: isCollapsed ? "60px" : "220px",
        padding: "10px",
        display: "flex",
        flexDirection: "column",
        borderRadius: "30px",
        justifyContent: "flex-start",
        height: "130px",
        transition: "width 0.7s ease",
        backgroundColor: "#2980B9",
      }}
      onMouseEnter={() => setIsCollapsed(false)}
      onMouseLeave={() => setIsCollapsed(true)}
    >
      {menuItems.map((item) => (
        <Typography
          key={item.id}
          variant="h6"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            cursor: "pointer",
            padding: "5px 10px",
            color: selectedComponent === item.id ? "black" : "white",
            fontWeight: selectedComponent === item.id ? "bold" : "normal",
            borderRadius: selectedComponent === item.id ? "10px" : "4px",
            backgroundColor: selectedComponent === item.id ? "white" : "",
            fontSize: "13px",
            transition: "all 0.3s ease",
        borderRadius: "30px",
            whiteSpace: "nowrap",
            boxShadow:
              selectedComponent === item.id
                ? "0px 4px 10px rgba(0, 0, 0, 0.2)"
                : "none",
          }}
          onClick={() => setSelectedComponent(item.id)}
        >
          {item.icon}
          {!isCollapsed && item.label}
        </Typography>
      ))}
    </Paper>
  );
};

const EnquirySetting = () => {
  const [selectedComponent, setSelectedComponent] = useState("add-exam");

  const renderComponent = () => {
    switch (selectedComponent) {
      case "add-exam":
        return <TeacherExam />;
      case "add-subject":
        return <AddSubject />;
      case "hourly-rate":
        return <HourlyRate />;
      default:
        return <TeacherExam />;
    }
  };

  return (
    <Box sx={{ display: "flex", padding: "10px" }}>
      <Sidebar
        menuItems={menuItems}
        selectedComponent={selectedComponent}
        setSelectedComponent={setSelectedComponent}
      />
      <Box sx={{ flexGrow: 1, padding: "20px", marginLeft: "20px" }}>
        {renderComponent()}
      </Box>
    </Box>
  );
};
export default EnquirySetting;