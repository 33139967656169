import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import Swal from "sweetalert2";

const About_us = () => {
  const [open, setOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const institutecode = () => localStorage.getItem("institutecode");
  const [selectedImage, setSelectedImage] = useState(null);
  const [formData, setFormData] = useState({
    aboutUsTitle: "",
    description: "",
    aboutUsImage: null,
  });
  const [aboutUsData, setAboutUsData] = useState([]);
  const [editId, setEditId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setEditId(null);
    setFormData({
      aboutUsTitle: "",
      description: "",
      aboutUsImage: null,
    });
  };


  const handleImageDialogOpen = (imageUrl) => {
    setSelectedImage(imageUrl);
    setImageDialogOpen(true);
  };

  const handleImageDialogClose = () => {
    setImageDialogOpen(false);
    setSelectedImage(null);
  };

  const fetchAboutUsData = async () => {
    try {
      const response = await axios.get(
        `https://pjsofttech.in:28443/getAllAboutUs?institutecode=${institutecode()}`
      );
  
      if (Array.isArray(response.data)) {
        setAboutUsData(response.data);  // If it's already an array
      } else if (response.data) {
        setAboutUsData([response.data]);  // Wrap the object in an array
      } else {
        setAboutUsData([]);  // Handle empty response
      }
    } catch (error) {
      console.error("Error fetching About Us data!", error);
    }
  };
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, aboutUsImage: e.target.files[0] });
  };

  const handleSubmit = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("aboutUsTitle", formData.aboutUsTitle);
    formDataToSend.append("description", formData.description);
    if (formData.aboutUsImage) {
      formDataToSend.append("aboutUsImage", formData.aboutUsImage);
    }

    try {
      if (editId) {
        await axios.put(
          `https://pjsofttech.in:28443/updateAboutUs/${editId}`,
          formDataToSend,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
         Swal.fire("Success", "About Us updated successfully!", "success");
      } else {
        await axios.post(
          `https://pjsofttech.in:28443/createAboutUs?institutecode=${institutecode()}`,
          formDataToSend,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
         Swal.fire("Success", "About Us Created successfully!", "success");
      }
      fetchAboutUsData();
      handleClose();
    } catch (error) {
      console.error("There was an error saving the AboutUs data!", error);
       Swal.fire("Error", "Failed to save About Us!", "error");
    }
  };

  const handleDelete = async (deleteId) => {
        Swal.fire({
                  title: "Are you sure?",
                  text: "You won't be able to revert this!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonText: "Yes, delete it!",
                  cancelButtonText: "No, cancel!",
                }).then(async (result) => {
                  if (result.isConfirmed) {
    try {
      await axios.delete(`https://pjsofttech.in:28443/deleteAboutUs/${deleteId}`);
      fetchAboutUsData();
      Swal.fire("Deleted!", "About Us has been deleted.", "success");
    } catch (error) {
      console.error("There was an error deleting the AboutUs data!", error);
      Swal.fire("Error", "Failed to delete About Us!", "error");
    }
  }
});
  };

  const handleEdit = (data) => {
    setFormData({
      aboutUsTitle: data.aboutUsTitle,
      description: data.description,
      aboutUsImage: data.aboutUsImage,
    });
    setEditId(data.aboutUsId);
    handleClickOpen();
  };

  useEffect(() => {
    fetchAboutUsData();
  }, []);

  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen}>
        Add About Us
      </Button>

      <Dialog open={open} onClose={handleClose} className="textField-root">
        <DialogTitle color={'black'}>{editId ? "Edit About Us" : "Add About Us"}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="aboutUsTitle"
            label="About Us Title"
            type="text"
            fullWidth
            value={formData.aboutUsTitle}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="description"
            label="Description"
            type="text"
            fullWidth
            value={formData.description}
            onChange={handleChange}
          />
          {formData.aboutUsImage && (
            <div style={{ marginTop: 10 }}>
              <img
                src={
                  typeof formData.aboutUsImage === "string"
                    ? formData.aboutUsImage
                    : URL.createObjectURL(formData.aboutUsImage)
                }
                alt="About Us"
                style={{ width: "100px", height: "auto", marginBottom: 10 }}
              />
              <Button
                variant="contained"
                component="label"
                style={{ marginTop: 10 }}
              >
                Change Image
                <input
                  type="file"
                  accept="image/*"
                  hidden
                  onChange={handleFileChange}
                />
              </Button>
            </div>
          )}

          {!formData.aboutUsImage && (
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              style={{ marginTop: 10 }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            {editId ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={imageDialogOpen} onClose={handleImageDialogClose}>
        <DialogTitle>Full Image</DialogTitle>
        <DialogContent>
          <img
            src={selectedImage}
            alt="Full View"
            style={{ width: "100%", height: "auto" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleImageDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <TableContainer component={Paper} style={{ marginTop: 20 }} className="table-root">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {aboutUsData.length > 0 ? (
              aboutUsData.map((row) => (
                <TableRow key={row.aboutUsId}>
                  <TableCell>{row.aboutUsId}</TableCell>
                  <TableCell>{row.aboutUsTitle}</TableCell>
                  <TableCell>{row.description}</TableCell>
                  <TableCell>
                    {row.aboutUsImage && (
                      <img
                        src={row.aboutUsImage}
                        alt="About Us"
                        style={{
                          width: "100px",
                          height: "auto",
                          cursor: "pointer",
                        }}
                        onClick={() => handleImageDialogOpen(row.aboutUsImage)}
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleEdit(row)} color="primary">
                      <Edit />
                    </IconButton>
                    <IconButton
                      onClick={() => handleDelete(row.aboutUsId)}
                      style={{ color: "red" }}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default About_us;
