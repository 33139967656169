import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  MenuItem,
  FormControl,
  TextField,
} from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";

function Dashboard() {
  const [data, setData] = useState(null);
  const [graphData, setGraphData] = useState([]);
  const [comparisonData, setComparisonData] = useState([]);
  const [year1, setYear1] = useState("2023");
  const [year2, setYear2] = useState("2024");
  const institutecode = () => localStorage.getItem("institutecode");

  // Year options
  const years = ["2026", "2025", "2024", "2023", "2022", "2021", "2020"];

  // Fetch card data
  useEffect(() => {
    axios
      .get(
        `https://pjsofttech.in:21443/count-revenueByOrder?institutecode=${institutecode()}`
      )
      .then((response) => setData(response.data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  // Fetch revenue graph data
  useEffect(() => {
    axios
      .get(
        `https://pjsofttech.in:21443/allCategory-Revenue?institutecode=${institutecode()}`
      )
      .then((response) => {
        const formattedData = response.data.map((item) => ({
          category: item.categoryName,
          revenue: item.totalRevenue,
        }));
        setGraphData(formattedData);
      })
      .catch((error) => console.error("Error fetching graph data:", error));
  }, []);

  // Fetch comparison graph data automatically on year change
  useEffect(() => {
    axios
      .get(
        `https://pjsofttech.in:21443/compare-years?institutecode=${institutecode()}&year1=${year1}&year2=${year2}`
      )
      .then((response) => {
        const formattedComparisonData = [
          {
            year: response.data.year1.year,
            totalOrders: response.data.year1.totalOrders,
            totalRevenue: response.data.year1.totalRevenue,
          },
          {
            year: response.data.year2.year,
            totalOrders: response.data.year2.totalOrders,
            totalRevenue: response.data.year2.totalRevenue,
          },
        ];
        setComparisonData(formattedComparisonData);
      })
      .catch((error) =>
        console.error("Error fetching comparison graph data:", error)
      );
  }, [year1, year2]);

  if (!data) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  const cardData = [
    { title: "Today", revenue: data.today.revenue, count: data.today.count, color: "#f44336" },
    { title: "Last 7 Days", revenue: data.last7Days.revenue, count: data.last7Days.count, color: "#2196f3" },
    { title: "Last 30 Days", revenue: data.last30Days.revenue, count: data.last30Days.count, color: "#4caf50" },
    { title: "Last 365 Days", revenue: data.last365Days.revenue, count: data.last365Days.count, color: "#ff9800" },
    { title: "Total Revenue", revenue: data.total.revenue, count: data.total.count, color: "#9c27b0" },
  ];

  return (
    <Grid container spacing={2}>

      {/* Render Cards */}
      {cardData.map((card, index) => (
        <Grid item xs={12} sm={6} md={2.4} key={index}>
          <Card style={{ backgroundColor: card.color, color: "#fff", borderRadius: "15px" }}>
            <CardContent>
              <Typography variant="h5">{card.title}</Typography>
              <Typography>Revenue: ₹{card.revenue}</Typography>
              <Typography>Orders: {card.count}</Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}

      {/* Inputs for Year Selection */}
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={6} md={2}>
          <FormControl fullWidth>
            <TextField select value={year1} onChange={(e) => setYear1(e.target.value)} className="textField-root" label="Year 1">
              {years.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
        <Grid item xs={6} md={2}>
          <FormControl fullWidth>
            <TextField select value={year2} onChange={(e) => setYear2(e.target.value)} className="textField-root" label="Year 2">
              {years.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
      </Grid>

      {/* Graphs */}
      <Grid container item spacing={2}>
        {/* Year Comparison Graph */}
        <Grid item xs={12} md={6}>
          <Card style={{ height: "100%" }}>
            <CardContent style={{ height: "400px" }}>
              <Typography variant="h6" gutterBottom>
                Revenue Comparison by Year
              </Typography>
              <ResponsiveBar
                data={comparisonData}
                keys={["totalOrders", "totalRevenue"]}
                indexBy="year"
                margin={{ top: 50, right: 10, bottom: 50, left: 60 }}
                padding={0.3}
                colors={({ id, data }) =>
                  id === "totalOrders" ? "#ff9800" : "#2196f3"
                }
                groupMode="stacked"
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  legend: "Year",
                  legendPosition: "middle",
                  legendOffset: 40,
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  legend: "Value",
                  legendPosition: "middle",
                  legendOffset: -50,
                }}
              />
            </CardContent>
          </Card>
        </Grid>

        {/* Revenue by Category */}
        <Grid item xs={12} md={6}>
          <Card style={{ height: "100%" }}>
            <CardContent style={{ height: "400px" }}>
              <Typography variant="h6" gutterBottom>
                Revenue by Category
              </Typography>
              <ResponsiveBar
                data={graphData}
                keys={["revenue"]}
                indexBy="category"
                margin={{ top: 50, right: 10, bottom: 50, left: 60 }}
                padding={0.3}
                colors={["#4caf50"]}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  legend: "Category",
                  legendPosition: "middle",
                  legendOffset: 40,
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  legend: "Revenue",
                  legendPosition: "middle",
                  legendOffset: -50,
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Dashboard;
