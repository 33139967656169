import React, { useState, useEffect } from "react";
import { HashLoader } from "react-spinners";
import {
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Grid,
  Typography,
  Paper,
  Snackbar,
} from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ImageIcon from "@mui/icons-material/Image";
import MuiAlert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import "./LoadingAnimation.css";
import Swal from 'sweetalert2';
import {
  getAllMaterialTypes,
  addMaterial,
  getAllCategoriesByMaterialType,
  getSubcategoriesByCategory,
} from "./API/AllApi"; // Import the API function

// Snackbar Alert Component
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AddMaterial() {
  const [materialId, setMaterialId] = useState("");
  const [materialtype, setMaterialType] = useState("");
  const [pdfFile, setPdfFile] = useState(null);
  const [thumbnailFile, setThumbnailFile] = useState(null);
  const [demoPdf, setDemoPdf] = useState(null);
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [saveToDevice, setSaveToDevice] = useState("no");
  const [status, setStatus] = useState("");
  const [mrp, setMrp] = useState("");
  const [price, setPrice] = useState("");
  const [validity, setValidity] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chapterName, setChapterName] = useState("");
  const [discription, setDiscription] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading animation

    const formData = new FormData();
    formData.append("materialtype", materialtype);
    formData.append("pdfFile", pdfFile);
    formData.append("thumbnailFile", thumbnailFile);
    formData.append("demoPdf", demoPdf);
    formData.append("saveToDevice", saveToDevice === "yes");
    formData.append("status", status);
 
    formData.append("validity", validity);
    formData.append("subcategory.id", subCategory);
    formData.append("chapterName", chapterName);
    formData.append("discription", discription);
 // If status is "free", pass mrp and price as 0.0
 if (status === "free") {
  formData.append("mrp", 0.0);
  formData.append("price", 0.0);
} else {
  formData.append("mrp", mrp);
  formData.append("price", price);
}
    try {
      await addMaterial(formData); // Use API function to add material

      // SweetAlert2 for success
      Swal.fire({
        title: 'Success!',
        text: 'Material added successfully!',
        icon: 'success',
        confirmButtonText: 'OK'
      });

      // Clear the form inputs after successful submit
      setMaterialType('');
      setPdfFile(null);
      setThumbnailFile(null);
      setDemoPdf(null);
      setSaveToDevice('');
      setStatus('');
      setMrp('');
      setPrice('');
      setValidity('');
      setSubCategory('');
      setChapterName('');
      setDiscription('');

    } catch (error) {
      // SweetAlert2 for error handling
      Swal.fire({
        title: 'Error!',
        text: "Error adding material: " + (error.response?.data || error.message),
        icon: 'error',
        confirmButtonText: 'OK'
      });
    } finally {
      setLoading(false); // Stop loading animation
    }
};


  // Fetch materials on component mount
  useEffect(() => {
    const fetchMaterialType = async () => {
      try {
        const data = await getAllMaterialTypes(); // Use API function
        setMaterials(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    fetchMaterialType();
  }, []);

  // Fetch categories
   // Fetch categories when material type changes
   const fetchCategories = async (selectedMaterialType) => {
    if (!selectedMaterialType) return;
    try {
      const data = await getAllCategoriesByMaterialType(selectedMaterialType); // Fetch categories by material type
      setCategories(
        data.map((cat) => ({ id: cat.id, name: cat.categoryName }))
      );
      setCategory("");
      setSubCategory("");
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  // Fetch subcategories when Category changes
  const fetchSubcategories = async (selectedCategory) => {
    if (!selectedCategory) return;
    try {
      const data = await getSubcategoriesByCategory(selectedCategory); // Use API function
      setSubcategories(
        data.map((subCat) => ({ id: subCat.id, name: subCat.subcategoryName }))
      );
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };

 // Handle Material Selection
 const handleMaterialChange = (e) => {
  const selectedMaterialId = e.target.value;
  setMaterialId(selectedMaterialId);
  const selectedMaterial = materials.find(
    (material) => material.id === Number(selectedMaterialId)
  );
  if (selectedMaterial) {
    setMaterialType(selectedMaterial.materialtype);
    fetchCategories(selectedMaterial.materialtype); // Fetch categories by material type
  }
};
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div>
      {/* <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{ fontWeight: "bold", color: "green" }}
      >
        Add Material
      </Typography> */}
      {loading && (
        <div className="loader-overlay">
          <HashLoader
            style={{ marginLeft: "10px" }}
            color="#00649F"
            size={80}
          />{" "}
          {/* Adjust size and color */}
          <Typography variant="body2" color="textSecondary" mt={15} ml={2}>
            Adding material, please wait...
          </Typography>
        </div>
      )}
      <Paper elevation={3} style={{ padding: "20px" }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} className="textField-root">
          <Grid item xs={12} sm={4}>
              <FormControl
                fullWidth
                required
                InputLabelProps={{ className: "required-asterisk" }}
              >
                <TextField
                  select
                  label="Select Material"
                  value={materialId}
                  onChange={handleMaterialChange}
                  variant="outlined"
                >
                  {materials.map((material) => (
                    <MenuItem key={material.id} value={material.id}>
                      {material.materialtype}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>

            {/* Category and Subcategory */}
            <Grid item xs={12} sm={4}>
              <Autocomplete
                options={categories}
                getOptionLabel={(option) => option.name}
                value={categories.find((cat) => cat.id === category) || null}
                onChange={(event, newValue) => {
                  setCategory(newValue ? newValue.id : "");
                  fetchSubcategories(newValue ? newValue.name : "");
                  setSubCategory("");
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Category"
                    required
                    InputLabelProps={{ className: "required-asterisk" }}
                    fullWidth
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Autocomplete
                options={subcategories}
                getOptionLabel={(option) => option.name}
                value={
                  subcategories.find((sub) => sub.id === subCategory) || null
                }
                onChange={(event, newValue) => {
                  setSubCategory(newValue ? newValue.id : null);
                }}
                disabled={!category}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Sub-Category"
                    required
                    InputLabelProps={{ className: "required-asterisk" }}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl
                fullWidth
                required
                InputLabelProps={{ className: "required-asterisk" }}
              >
                <TextField
                  label="Subject Name"
                  value={chapterName}
                  onChange={(e) => setChapterName(e.target.value)}
                  variant="outlined"
                ></TextField>
              </FormControl>
            </Grid>

            {/* Other Fields */}
            <Grid item xs={12} sm={4}>
              <TextField
                label="Save on Phone"
                variant="outlined"
                value={saveToDevice}
                onChange={(event) => setSaveToDevice(event.target.value)} // Update saveToDevice state on change
                select
                required
                InputLabelProps={{ className: "required-asterisk" }}
                fullWidth
              >
                <MenuItem value="yes">Yes</MenuItem>
                <MenuItem value="no">No</MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                label="Select Status"
                variant="outlined"
                value={status}
                onChange={(event) => setStatus(event.target.value)} // Update status on change
                select
                required
                InputLabelProps={{ className: "required-asterisk" }}
                fullWidth
              >
                <MenuItem value="free">Free</MenuItem>
                <MenuItem value="paid">Paid</MenuItem>
              </TextField>
            </Grid>

           {/* Conditionally render MRP and Price fields based on the status */}
{status != "free" && (
  <>
    <Grid item xs={12} sm={4}>
      <TextField
        label="MRP"
        variant="outlined"
        type="number"
        fullWidth
        value={mrp}
        onChange={(e) => setMrp(e.target.value)}
        required
        InputLabelProps={{ className: "required-asterisk" }}
      />
    </Grid>

    <Grid item xs={12} sm={4}>
      <TextField
        label="Price"
        variant="outlined"
        type="number"
        fullWidth
        value={price}
        onChange={(e) => setPrice(e.target.value)}
        onBlur={() => {
          if (price > mrp) {
            // Show a warning or reset price to be <= mrp
            alert("Price cannot be greater than MRP");
            setPrice(""); // Optionally reset price if it's invalid
          }
        }}
        required
        InputLabelProps={{ className: "required-asterisk" }}
      />
    </Grid>
  </>
)}

            <Grid item xs={12} sm={4}>
              <TextField
                label="Validity (in months)"
                variant="outlined"
                type="number"
                fullWidth
                value={validity}
                onChange={(e) => setValidity(e.target.value)}
                required
                InputLabelProps={{ className: "required-asterisk" }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Description"
                variant="outlined"
                fullWidth
                multiline // Enable multi-line input
                rows={4} // Specify the number of rows
                value={discription}
                onChange={(e) => setDiscription(e.target.value)}
                required
                InputLabelProps={{ className: "required-asterisk" }}
                style={{ marginTop: "16px" }}
              />
            </Grid>

            {/* File Uploads */}
            <Grid item xs={12} sm={4} mt={2}>
              <Button
                fullWidth
                variant="contained"
                component="label"
                startIcon={<PictureAsPdfIcon />}
              >
                Upload PDF
                <input
                  type="file"
                  accept=".pdf"
                  hidden
                  onChange={(e) => {
                    setPdfFile(e.target.files[0]);
                  }}
                />
              </Button>
              {pdfFile && (
                <Typography variant="body2" mt={1}>
                  {pdfFile.name}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} sm={4} mt={2}>
              <Button
                fullWidth
                variant="contained"
                component="label"
                startIcon={<ImageIcon />}
              >
                Upload Thumbnail
                <input
                  type="file"
                  accept="image/*"
                  hidden
                  onChange={(e) => {
                    setThumbnailFile(e.target.files[0]);
                  }}
                />
              </Button>
              {thumbnailFile && (
                <Typography variant="body2" mt={1}>
                  {thumbnailFile.name}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} sm={4} mt={2}>
              <Button
                fullWidth
                variant="contained"
                component="label"
                startIcon={<PictureAsPdfIcon />}
              >
                Upload Demo PDF
                <input
                  type="file"
                  accept=".pdf"
                  hidden
                  onChange={(e) => {
                    setDemoPdf(e.target.files[0]);
                  }}
                />
              </Button>
              {demoPdf && (
                <Typography variant="body2" mt={1}>
                  {demoPdf.name}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                disabled={loading}
              >
                Add Material
              </Button>
            </Grid>
          </Grid>
        </form>

        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Paper>
    </div>
  );
}
