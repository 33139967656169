import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import {
  Container,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  Modal,
  Box,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { getAllSlideBars } from "./Api/AllApi"; // Assuming you have an API function to fetch slide bars

export default function Slidebar() {
  const [slideBars, setSlideBars] = useState([]);
  const [slideBarColor, setSlideBarColor] = useState("#ffffff");
  const [instituteCode, setInstituteCode] = useState("");
  const [slideImages, setSlideImages] = useState([]);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editInstituteCode, setEditInstituteCode] = useState("");
  const [editSlideBarColor, setEditSlideBarColor] = useState("#ffffff");
  const [editSlideImages, setEditSlideImages] = useState([]);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const institutecode = () => localStorage.getItem("institutecode");

  // Fetch all slide bars on component load
  useEffect(() => {
    fetchSlideBars();
  }, []);

  const fetchSlideBars = async () => {
    try {
      const response = await getAllSlideBars(institutecode());
      setSlideBars(response);
    } catch (error) {
      console.error("Error fetching slide bars:", error);
    }
  };

  const handleCreateSlideBar = async () => {
    const formData = new FormData();
    formData.append("slideBarColor", slideBarColor);
    formData.append("institutecode", institutecode);

    slideImages.forEach((image) => {
      formData.append("slideImages", image);
    });

    try {
      const response = await axios.post(
        `https://pjsofttech.in:28443/createSlideBar`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      Swal.fire("Success", "Slide Bar created successfully!", "success");
      setSlideBarColor("#ffffff");
      setSlideImages([]);
      setOpenCreateDialog(false); // Close dialog
      fetchSlideBars(); // Refresh Slide Bars list
    } catch (error) {
      console.error("Error creating slide bar:", error);
      Swal.fire("Error", "Failed to create Slide Bar!", "error");
    }
  };

  const handleEditSlideBar = (slideBar) => {
    setEditInstituteCode(slideBar.institutecode);
    setEditSlideBarColor(slideBar.slideBarColor);
    setEditSlideImages([]); // Reset images for new uploads
    setOpenEditDialog(true);
  };

  const handleUpdateSlideBar = async () => {
    const formData = new FormData();
    formData.append("institutecode", editInstituteCode);
    if (editSlideBarColor) formData.append("slideBarColor", editSlideBarColor);

    for (let i = 0; i < editSlideImages.length; i++) {
      formData.append("slideImages", editSlideImages[i]);
    }

    try {
      await axios.put(`https://pjsofttech.in:28443/updateSlideBar?institutecode=${institutecode()}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      Swal.fire("Success", "Slide Bar updated successfully!", "success");
      setOpenEditDialog(false);
      fetchSlideBars();
    } catch (error) {
      console.error("Error updating slide bar:", error);
      Swal.fire("Error", "Failed to update Slide Bar!", "error");
    }
  };

  const handleDeleteSlideBar = async (id) => {
    try {
      await axios.delete(`https://pjsofttech.in:28443/deleteSlideBar/${id}`);
      Swal.fire("Success", "Slide Bar deleted successfully!", "success");
      fetchSlideBars();
    } catch (error) {
      console.error("Error deleting slide bar:", error);
      Swal.fire("Error", "Failed to delete Slide Bar!", "error");
    }
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setOpenImageModal(true);
  };

  const handleCloseImageModal = () => {
    setOpenImageModal(false);
    setSelectedImage(null);
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Sliders 
      </Typography>

      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpenCreateDialog(true)}
      >
        Create New Slide Bar
      </Button>

      {/* Create Dialog */}
      <Dialog
        open={openCreateDialog}
        onClose={() => setOpenCreateDialog(false)}
        className="textField-root"
      >
        <DialogTitle>Create New Slide Bar</DialogTitle>
        <DialogContent>
          <TextField
            label="Slide Bar Color"
            value={slideBarColor}
            onChange={(e) => setSlideBarColor(e.target.value)}
            fullWidth
            margin="normal"
          />
          {/* <TextField
            label="Institute Code"
            value={instituteCode}
            onChange={(e) => setInstituteCode(e.target.value)}
            fullWidth
            margin="normal"
          /> */}
          <input
            type="file"
            accept="image/*"
            multiple
            onChange={(e) => setSlideImages([...e.target.files])}
            style={{ marginTop: "10px" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCreateDialog(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleCreateSlideBar} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Dialog */}
      <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)} className="textField-root">
        <DialogTitle>Edit Slide Bar</DialogTitle>
        <DialogContent>
          <TextField
            label="Slide Bar Color"
            value={editSlideBarColor}
            onChange={(e) => setEditSlideBarColor(e.target.value)}
            fullWidth
            margin="normal"
          />
          <input
            type="file"
            accept="image/*"
            multiple
            onChange={(e) => setEditSlideImages([...e.target.files])}
            style={{ marginTop: "10px" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditDialog(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleUpdateSlideBar} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>

      {/* Slide Bars Table */}
      <Typography variant="h5" gutterBottom style={{ marginTop: "20px" }}>
        Existing Slide Bars
      </Typography>
      <TableContainer component={Paper} className="table-root">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Color</TableCell>
              {/* <TableCell>Institute Code</TableCell> */}
              <TableCell>Images</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(slideBars) && slideBars.map((slideBar) => (
              <TableRow key={slideBar.id}>
                <TableCell>{slideBar.id}</TableCell>
                <TableCell>{slideBar.slideBarColor}</TableCell>
                {/* <TableCell>{slideBar.institutecode}</TableCell> */}
                <TableCell>
                  {slideBar.slideImages &&
                    slideBar.slideImages.map((image, index) => {
                      const imageUrl = image; // Assuming image URLs are correct
                      return (
                        <img
                          key={index}
                          src={imageUrl}
                          alt={`Slide ${index + 1}`}
                          width="50px"
                          height="50px"
                          style={{ marginRight: "5px", cursor: "pointer" }}
                          onClick={() => handleImageClick(imageUrl)}
                        />
                      );
                    })}
                </TableCell>

                <TableCell>
                  <IconButton
                    onClick={() => handleEditSlideBar(slideBar)}
                    color="primary"
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    onClick={() => handleDeleteSlideBar(slideBar.id)}
                    sx={{ color: "red" }} // Apply red color using sx prop
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Image Modal */}
      <Modal
        open={openImageModal}
        onClose={handleCloseImageModal}
        aria-labelledby="image-modal-title"
        aria-describedby="image-modal-description"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
          }}
        >
          <Box
            sx={{
              position: "relative",
              maxWidth: "80%",
              maxHeight: "80%",
            }}
          >
            <img
              src={selectedImage}
              alt="Full Image"
              style={{
                width: "100%",
                height: "auto",
                borderRadius: "8px",
              }}
            />
          </Box>
        </Box>
      </Modal>
    </Container>
  );
}
