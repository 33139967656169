import React, { useState } from 'react';
import { Box, Typography, Grid, Card, CardContent, CardMedia, TextField, Button } from '@mui/material';
import { DatePicker } from '@mui/lab';
import ebookImage from './img/ebook2.jpg'; // Placeholder image, replace with actual images

const demoBoughtEbooks = [
  { id: 1, title: 'EBook 1', category: 'Fiction', date: '2023-01-15', price: '$10' },
  { id: 2, title: 'EBook 2', category: 'Science', date: '2023-02-20', price: '$15' },
  { id: 3, title: 'EBook 3', category: 'Technology', date: '2023-03-25', price: '$20' },
];

const demoCartItems = [
  { id: 1, title: 'EBook 4', category: 'Fiction', price: '$8' },
  { id: 2, title: 'EBook 5', category: 'Science', price: '$12' },
];

const demoBookmarkedEbooks = [
  { id: 1, title: 'EBook 6', category: 'Technology', price: '$18' },
  { id: 2, title: 'EBook 7', category: 'Non-Fiction', price: '$14' },
];

export default function Purchase() {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleDateChange = (setter) => (date) => {
    setter(date);
  };

  const filterByDateRange = (ebooks) => {
    if (!startDate || !endDate) return ebooks;
    return ebooks.filter((ebook) => {
      const ebookDate = new Date(ebook.date);
      return ebookDate >= startDate && ebookDate <= endDate;
    });
  };

  const sortedBoughtEbooks = filterByDateRange(demoBoughtEbooks);

  return (
    <Box sx={{ padding: '20px', backgroundColor: '#eaeff1', minHeight: '100vh' }}>
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{
          marginBottom: '40px',
          fontWeight: 'bold',
          color: '#1976d2',
          textShadow: '2px 2px 4px rgba(0,0,0,0.2)',
        }}>
        My Purchases
      </Typography>

      {/* Date Range Filter */}
      <Box sx={{ marginBottom: '20px', textAlign: 'center' }}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 'bold',
            color: '#388e3c',
            textDecoration: 'underline',
            marginBottom: '10px',
          }}>
          Sort by Date Range
        </Typography>
        <Grid container justifyContent="center" spacing={2} alignItems="center">
          <Grid item>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={handleDateChange(setStartDate)}
              renderInput={(params) => <TextField {...params} variant="outlined" />}
            />
          </Grid>
          <Grid item>
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={handleDateChange(setEndDate)}
              renderInput={(params) => <TextField {...params} variant="outlined" />}
            />
          </Grid>
        </Grid>
      </Box>

      {/* Bought EBooks Section */}
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          marginTop: '40px',
          fontWeight: 'bold',
          color: '#d32f2f',
          textShadow: '1px 1px 3px rgba(0,0,0,0.3)',
        }}>
        Bought EBooks
      </Typography>
      <Grid container spacing={3}>
        {sortedBoughtEbooks.map((ebook) => (
          <Grid item xs={12} sm={6} md={4} key={ebook.id}>
            <Card
              sx={{
                height: '100%',
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
                },
                borderRadius: '15px',
                overflow: 'hidden',
                boxShadow: '0 4px 16px rgba(0,0,0,0.1)',
                backgroundColor: '#ffffff',
              }}>
              <CardMedia
                component="img"
                alt={ebook.title}
                image={ebookImage}
                sx={{
                  height: 200,
                  objectFit: 'cover',
                  transition: 'opacity 0.2s',
                  '&:hover': {
                    opacity: 0.9,
                  },
                }}
              />
              <CardContent sx={{ padding: '16px' }}>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    fontSize: '1.1rem',
                    fontWeight: 'bold',
                    color: '#1976d2',
                  }}>
                  {ebook.title} <span style={{ color: '#8e24aa' }}>({ebook.category})</span>
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ fontSize: '0.9rem' }}>
                  Purchased on: {new Date(ebook.date).toLocaleDateString()}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ fontSize: '1rem', marginTop: '10px', fontWeight: 'bold' }}>
                  Price: {ebook.price}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ marginTop: '10px', width: '100%' }}>
                  Purchase
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Cart Items Section */}
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          marginTop: '40px',
          fontWeight: 'bold',
          color: '#d32f2f',
          textShadow: '1px 1px 3px rgba(0,0,0,0.3)',
        }}>
        Cart Items
      </Typography>
      <Grid container spacing={3}>
        {demoCartItems.map((item) => (
          <Grid item xs={12} sm={6} md={4} key={item.id}>
            <Card
              sx={{
                height: '100%',
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
                },
                borderRadius: '15px',
                overflow: 'hidden',
                boxShadow: '0 4px 16px rgba(0,0,0,0.1)',
                backgroundColor: '#ffffff',
              }}>
              <CardMedia
                component="img"
                alt={item.title}
                image={ebookImage}
                sx={{
                  height: 200,
                  objectFit: 'cover',
                  transition: 'opacity 0.2s',
                  '&:hover': {
                    opacity: 0.9,
                  },
                }}
              />
              <CardContent sx={{ padding: '16px' }}>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    fontSize: '1.1rem',
                    fontWeight: 'bold',
                    color: '#1976d2',
                  }}>
                  {item.title} <span style={{ color: '#8e24aa' }}>({item.category})</span>
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ fontSize: '1rem', marginTop: '10px', fontWeight: 'bold' }}>
                  Price: {item.price}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ marginTop: '10px', width: '100%' }}>
                  Purchase
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Bookmarked EBooks Section */}
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          marginTop: '40px',
          fontWeight: 'bold',
          color: '#d32f2f',
          textShadow: '1px 1px 3px rgba(0,0,0,0.3)',
        }}>
        Bookmarked EBooks
      </Typography>
      <Grid container spacing={3}>
        {demoBookmarkedEbooks.map((ebook) => (
          <Grid item xs={12} sm={6} md={4} key={ebook.id}>
            <Card
              sx={{
                height: '100%',
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
                },
                borderRadius: '15px',
                overflow: 'hidden',
                boxShadow: '0 4px 16px rgba(0,0,0,0.1)',
                backgroundColor: '#ffffff',
              }}>
              <CardMedia
                component="img"
                alt={ebook.title}
                image={ebookImage}
                sx={{
                  height: 200,
                  objectFit: 'cover',
                  transition: 'opacity 0.2s',
                  '&:hover': {
                    opacity: 0.9,
                  },
                }}
              />
              <CardContent sx={{ padding: '16px' }}>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    fontSize: '1.1rem',
                    fontWeight: 'bold',
                    color: '#1976d2',
                  }}>
                  {ebook.title} <span style={{ color: '#8e24aa' }}>({ebook.category})</span>
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ fontSize: '1rem', marginTop: '10px', fontWeight: 'bold' }}>
                  Price: {ebook.price}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ marginTop: '10px', width: '100%' }}>
                  Purchase
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}