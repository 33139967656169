import React, { useState } from "react";
import { Box, Typography, Paper, Tooltip } from "@mui/material";
import AddCourseIcon from "@mui/icons-material/LibraryAdd";
import SourceIcon from "@mui/icons-material/Source";
import GuideIcon from "@mui/icons-material/School";
import ClassIcon from "@mui/icons-material/Class";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import CatchingPokemonIcon from "@mui/icons-material/CatchingPokemon";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import EditNoteIcon from "@mui/icons-material/EditNote";

import AdmissionAddCourse from "../Addmission/AdmissionAddCourse";
import AddmissionSource from "../Addmission/AddmissionSource";
import AddGuide from "../Addmission/AddGuide";
import ClassRoom from "./ClassRoom";
import Medium from "./Medium";
import Teacher from "./Teacher";
import AdmissionQR from "./AdmissionQR";
import RulesRegulations from "./RulesRegulations";

const menuItems = [
  { id: "AdmissionAddCourse", label: "Add Course", icon: <AddCourseIcon /> },
  { id: "AddmissionSource", label: "Add Source", icon: <SourceIcon /> },
  { id: "AddGuide", label: "Add Conduct", icon: <GuideIcon /> },
  { id: "Medium", label: "Add Medium", icon: <AutoStoriesIcon /> },
  { id: "Teacher", label: "Add Teacher", icon: <CatchingPokemonIcon /> },
  { id: "ClassRoom", label: "Add ClassRoom", icon: <ClassIcon /> },
  { id: "AdmissionQR", label: "Admission QR", icon: <QrCodeScannerIcon /> },
  { id: "RulesRegulations", label: "Rules & Regulations", icon: <EditNoteIcon /> },
];

const AdmissionSetting = () => {
  const [selectedComponent, setSelectedComponent] = useState("AdmissionAddCourse");
  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <Box sx={{ padding: "10px", display: "flex", justifyContent: "center" }}>
      {/* Sidebar */}
      <Paper
        elevation={3}
        sx={{
          width: isCollapsed ? "60px" : "220px",
          padding: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          height: "370px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          borderRadius: "30px",
          backgroundColor: "#2980B9",
          transition: "width 0.7s ease",
          position: "relative",
          overflow: "hidden",
        }}
        onMouseEnter={() => setIsCollapsed(false)}
        onMouseLeave={() => setIsCollapsed(true)}
      >
        {menuItems.map((item) => (
          <Tooltip key={item.id} title={isCollapsed ? item.label : ""} placement="right">
            <Typography
              variant="h6"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                cursor: "pointer",
                padding: "10px",
                color: selectedComponent === item.id ? "black" : "white",
                fontWeight: selectedComponent === item.id ? "bold" : "normal",
                borderRadius: "30px",
                backgroundColor: selectedComponent === item.id ? "white" : "",
                fontSize: "13px",
                transition: "all 0.3s ease",
                whiteSpace:"nowrap",
                boxShadow:
                  selectedComponent === item.id
                    ? "0px 4px 10px rgba(0, 0, 0, 0.2)"
                    : "none",
              }}
              onClick={() => setSelectedComponent(item.id)}
         
            >
              {item.icon}
              {!isCollapsed && item.label}
            </Typography>
          </Tooltip>
        ))}
      </Paper>

      {/* Content Area */}
      <Box
        sx={{
          flexGrow: 1,
          padding: "20px",
          marginLeft: "20px",
          border: "1px solid #ddd",
          borderRadius: "8px",
        }}
      >
        {selectedComponent === "AdmissionAddCourse" && <AdmissionAddCourse />}
        {selectedComponent === "AddmissionSource" && <AddmissionSource />}
        {selectedComponent === "AddGuide" && <AddGuide />}
        {selectedComponent === "ClassRoom" && <ClassRoom />}
        {selectedComponent === "Medium" && <Medium />}
        {selectedComponent === "Teacher" && <Teacher />}
        {selectedComponent === "AdmissionQR" && <AdmissionQR />}
        {selectedComponent === "RulesRegulations" && <RulesRegulations />}
      </Box>
    </Box>
  );
};

export default AdmissionSetting;
