import React, { useEffect, useState } from "react";
import { Card, CardContent, Typography, Grid, Paper } from "@mui/material";
import OrdersCountByStatus from "./OrdersCountByStatus";
const institutecode = localStorage.getItem("institutecode");

const apiUrls = [
  {
    timeframe: "Today's Count",
    url: `https://pjsofttech.in:17443/count?timeframe=today&institutecode=${institutecode}`,
  },
  {
    timeframe: "7 Days Count",
    url: `https://pjsofttech.in:17443/count?timeframe=7&institutecode=${institutecode}`,
  },
  {
    timeframe: "30 Days Count",
    url: `https://pjsofttech.in:17443/count?timeframe=30&institutecode=${institutecode}`,
  },
  {
    timeframe: "365 Days Count",
    url: `https://pjsofttech.in:17443/count?timeframe=365&institutecode=${institutecode}`,
  },
  {
    timeframe: "Total Count",
    url: `https://pjsofttech.in:17443/count?timeframe=total&institutecode=${institutecode}`,
  },
];

// Define a color mapping based on timeframe
const backgroundColorMap = {
  "Today's Count": "#F9E79F", // Light red
  "7 Days Count": "#FF6F61", // Light yellow
  "30 Days Count": "#3498DB", // Light green
  "365 Days Count": "#F9E79F", // Light blue
  "Total Count": "#FF6F61", // Light purple
};

// Additional API for revenue report
const revenueApiUrl = `https://pjsofttech.in:17443/revenueReport?institutecode=${institutecode}`;

// Define a color mapping for revenue cards
const revenueBackgroundColorMap = {
  today: "#F9E79F", // Light coral
  last7Days: "#FF6F61", // Light yellow-green
  last30Days: "#3498DB", // Light green
  last365Days: "#F9E79F", // Light blue
  total: "#FF6F61", // Light purple
};

export default function ShipmentCounts() {
  const [counts, setCounts] = useState({});
  const [revenue, setRevenue] = useState({});

  useEffect(() => {
    const fetchCounts = async () => {
      const responses = await Promise.all(
        apiUrls.map((api) => fetch(api.url).then((res) => res.json()))
      );
      const newCounts = apiUrls.reduce((acc, api, index) => {
        acc[api.timeframe] = responses[index];
        return acc;
      }, {});
      setCounts(newCounts);
    };

    const fetchRevenue = async () => {
      const revenueResponse = await fetch(revenueApiUrl);
      const revenueData = await revenueResponse.json();
      setRevenue(revenueData);
    };

    fetchCounts();
    fetchRevenue();
  }, []);

  // Define the order for the revenue display
  const revenueOrder = [
    "today",
    "last7Days",
    "last30Days",
    "last365Days",
    "total",
  ];

  // Define the order for the revenue display with human-readable titles
  const revenueOrdersWithTitles = [
    { key: "today", title: "Today's Revenue" },
    { key: "last7Days", title: "7 Days Revenue" },
    { key: "last30Days", title: "30 Days Revenue" },
    { key: "last365Days", title: "365 Days Revenue" },
    { key: "total", title: "Total Revenue" },
  ];

  return (
    <div padding="0">
        <OrdersCountByStatus />
      <Grid container sx={{ justifyContent: "space-between" }}>
        {apiUrls.map((api) => (
          <Grid item xs={12} sm={6} md={2.4} key={api.timeframe}>
            <CardContent>
              <Paper elevation={3}
                sx={{
                  padding: 2,
                  backgroundColor: backgroundColorMap[api.timeframe],
                  borderRadius: 2,
                  fontWeight: "bold",
                  boxShadow: 3,
                }}>
              
                <Typography variant="h7" component="div">
                  {api.timeframe}
                </Typography>
                <Typography variant="h7">
                  {counts[api.timeframe] !== undefined
                    ? counts[api.timeframe]
                    : "Loading..." || "0"}
                </Typography>
                </Paper>
              </CardContent>
              
          </Grid>
        ))}
      </Grid>
{/* 
      <Grid container sx={{ justifyContent: "space-between" }}  mt={-3}>
        {revenueOrdersWithTitles.map(({ key, title }) => (
          <Grid item xs={12} sm={6} md={2.4} key={key}>
              <CardContent>
                <Paper elevation={3}
                sx={{
                  padding: 2,
                  backgroundColor: revenueBackgroundColorMap[key],
                  borderRadius: 2,
                  fontWeight: "bold",
                  boxShadow: 3,
                }}>
                <Typography variant="h7" component="div">
                  {title} 
                </Typography>
                <Typography variant="h7">
                  {revenue[key] !== undefined
                    ? revenue[key]
                    : "Loading..." || "0"}
                </Typography>
                </Paper>
              </CardContent>
          </Grid>
        ))} */}
          <Grid container sx={{ justifyContent: "space-between" }} mt={-3}>
        {revenueOrdersWithTitles.map(({ key, title }) => (
          <Grid item xs={12} sm={6} md={2.4} key={key}>
            <CardContent>
              <Paper
                elevation={3}
                sx={{
                  padding: 2,
                  backgroundColor: revenueBackgroundColorMap[key],
                  borderRadius: 2,
                  fontWeight: "bold",
                  boxShadow: 3,
                }}
              >
                <Typography variant="h7" component="div">
                  {title}
                </Typography>
                <Typography variant="h7">
                  {/* Updated to ensure '0' is displayed when there is no data */}
                  {revenue[key] !== undefined && revenue[key] !== null
                    ? revenue[key]
                    : "0"} {/* Highlighted Fix */}
                </Typography>
              </Paper>
            </CardContent>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
