// EmployeeManager.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import WebDashboard from "./WebDashboard";
import WebsiteManagerLayout from './WebsiteManagerLayout';
import WebsiteSetting from "./WebsiteSetting";
import WebsiteUrl from "./WebsiteUrl";
import Footer from "./Footer";
import Slidebar from "./Slidebar";

const WebsiteManager = () => {
  return (
    <Routes>
      {/* Main route that renders the layout with the sidebar */}
      <Route path="/" element={<WebsiteManagerLayout />}>
        {/* Nested routes inside EmployeeManagerLayout */}
        <Route index element={<WebsiteUrl />} />
        <Route path="Slidebar" element={<Slidebar />} />
        <Route path="Footer" element={<Footer />} />
        <Route path="WebsiteSetting" element={<WebsiteSetting />} />
      </Route>
    </Routes>
  );
};

export default WebsiteManager;
