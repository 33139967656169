import { active } from "d3";
import React, { useLayoutEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";

// Styles for each navbar item
const navItemStyle = {
  padding: "3px 8px",
  cursor: "pointer",
  fontSize: "14px",
  fontWeight: "bold",
  color: "white",
  textAlign: "center",
  flex: 1,
  transition: "background-color 0.3s ease, color 0.3s ease",
  textDecoration: "none", // Remove underline
};
// Styles for active item
const activeNavItemStyle = {
  ...navItemStyle,
  // backgroundColor: "#3498DB",
  // background: `linear-gradient(to right, #3498DB, #333333)`,
  backgroundColor:"white",
  color: "black",
  borderRadius: "30px",
  transform: "scale(1)", // Slight increase for active state
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", // Subtle shadow for active
};

// Styles for the subnavbar container
const subNavBarStyle = {
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "15px",
  backgroundColor: "#2980B9",
  padding: "8px",
  borderRadius: "30px",
  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)", // Slight shadow for navbar
};

export default function PayrollManagerLayout() {
  const location = useLocation();
  const isActive = (path) =>location.pathname === path;
  const [activeComponent, setActiveComponent] = useState("PayrollDashboard");
  const handleNavClick = (component) => {
    setActiveComponent(component);
  };
  return (
    <div>
      <div style={subNavBarStyle}>
      <Link to="/layout/payroll-manager" style={isActive("/layout/payroll-manager") ? activeNavItemStyle : navItemStyle}>
      PayrollDashboard
      </Link>
      <Link to="/layout/payroll-manager/monthly-salary" style={isActive("/layout/payroll-manager/monthly-salary") ? activeNavItemStyle : navItemStyle}>
      Monthly Salary 
      </Link>
      <Link to="/layout/payroll-manager/hourly-salary" style={isActive("/layout/payroll-manager/hourly-salary") ? activeNavItemStyle : navItemStyle}>
      Hourly Salary 
      </Link>
      <Link to="/layout/payroll-manager/teachers-list" style={isActive("/layout/payroll-manager/teachers-list") ? activeNavItemStyle : navItemStyle}>
      Teachers List
      </Link>
      <Link to="/layout/payroll-manager/PayrollSetting" style={isActive("/layout/payroll-manager/PayrollSetting") ? activeNavItemStyle : navItemStyle}>
      Payroll Setting
      </Link>
      {/* <Link to="/layout/payroll-manager/hourly-rate" style={isActive("/layout/payroll-manager/hourly-rate") ? activeNavItemStyle : navItemStyle}>
      Hourly Rate
      </Link>
      <Link to="/layout/payroll-manager/add-subject" style={isActive("/layout/payroll-manager/add-subject") ? activeNavItemStyle : navItemStyle}>
      Subject
      </Link>
      <Link to="/layout/payroll-manager/add-exam" style={isActive("/layout/payroll-manager/add-exam") ? activeNavItemStyle : navItemStyle}>
      Exam
      </Link> */}
    </div>
    <div>
      <Outlet/>
    </div>
    </div>
  )
}
