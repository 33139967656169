import React, { useState } from "react";
import { Box, Paper, Typography, Tooltip } from "@mui/material";
import {
  AddCircle as AddCircleIcon,
  Class as ClassIcon,
  HistoryEdu as HistoryEduIcon,
  Groups as GroupsIcon,
} from "@mui/icons-material";
import MenuBar from "./MenuBar";
import Course from "./Course";
import Facility from "./Facility";
import Gallery from "./Gallery";
import ContactForm from "./ContactForm";
import Toppers from "./Toppers";
import Testimonials from "./Testimonials";
import AboutUs from "./About_us";

const menuItems = [
  { id: "MenuBar", label: "MenuBar", icon: <AddCircleIcon /> },
  { id: "Course", label: "Course", icon: <ClassIcon /> },
  { id: "Facility", label: "Facility", icon: <HistoryEduIcon /> },
  { id: "Gallery", label: "Gallery", icon: <GroupsIcon /> },
  { id: "ContactForm", label: "ContactForm", icon: <GroupsIcon /> },
  { id: "Toppers", label: "Toppers", icon: <GroupsIcon /> },
  { id: "Testimonials", label: "Testimonials", icon: <GroupsIcon /> },
  { id: "AboutUs", label: "About Us", icon: <GroupsIcon /> },
];

const Sidebar = ({ menuItems, selectedComponent, setSelectedComponent }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <Paper
      elevation={3}
      sx={{
        width: isCollapsed ? "60px" : "220px",
        padding: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        height: "370px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        borderRadius: "30px",
        backgroundColor: "#2980B9",
        transition: "width 0.7s ease",
        position: "relative",
        overflow: "hidden",
      }}
      onMouseEnter={() => setIsCollapsed(false)}
      onMouseLeave={() => setIsCollapsed(true)}
    >
      {menuItems.map((item) => (
        <Tooltip
          key={item.id}
          title={isCollapsed ? item.label : ""}
          placement="right"
        >
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              cursor: "pointer",
              padding: "10px",
              color: selectedComponent === item.id ? "black" : "white",
              fontWeight: selectedComponent === item.id ? "bold" : "normal",
              borderRadius: "30px",
              backgroundColor: selectedComponent === item.id ? "white" : "",
              fontSize: "13px",
              transition: "all 0.3s ease",
              boxShadow:
                selectedComponent === item.id
                  ? "0px 4px 10px rgba(0, 0, 0, 0.2)"
                  : "none",
            }}
            onClick={() => setSelectedComponent(item.id)}
            whiteSpace="nowrap"
          >
            {item.icon}
            {!isCollapsed && item.label}
          </Typography>
        </Tooltip>
      ))}
    </Paper>
  );
};

const ShipmentSetting = () => {
  const [selectedComponent, setSelectedComponent] = useState("MenuBar");

  const renderComponent = () => {
    switch (selectedComponent) {
      case "MenuBar":
        return <MenuBar />;
      case "Course":
        return <Course />;
      case "Facility":
        return <Facility />;
      case "Gallery":
        return <Gallery />;
      case "ContactForm":
        return <ContactForm />;
      case "Toppers":
        return <Toppers />;
      case "Testimonials":
        return <Testimonials />;
      case "AboutUs":
        return <AboutUs />;
      default:
        return null;
    }
  };

  return (
    <Box sx={{ display: "flex", padding: "10px" }}>
      {/* Sidebar */}
      <Sidebar
        menuItems={menuItems}
        selectedComponent={selectedComponent}
        setSelectedComponent={setSelectedComponent}
      />

      {/* Content Area */}
      <Box
        sx={{
          flexGrow: 1,
          padding: "20px",
          marginLeft: "20px",
          border: "1px solid #ddd",
          borderRadius: "8px",
        }}
      >
        {renderComponent()}
      </Box>
    </Box>
  );
};

export default ShipmentSetting;
