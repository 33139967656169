// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import {
//   Box,
//   Button,
//   Grid,
//   MenuItem,
//   TextField,
// } from "@mui/material";
// import Category from "./Category";
// import Swal from "sweetalert2";

// function Form() {
//   const [values, setValues] = useState({
//     coursename: "",
//     price: "",
//     discountPrice: "",
//     description: "",
//     coursefeatures: "",
//     date: "",
//     validity: "",
//     category: "",
//   });

//   const [categories, setCategories] = useState([]);
//   const [openDialog, setOpenDialog] = useState(false);
//   const getInstituteCode = () => localStorage.getItem("institutecode");

//   const fetchCategories = async () => {
//     try {
//       const res = await axios.get(
//         `https://pjsofttech.in:14443/getAllCategories?institutecode=${getInstituteCode()}`
//       );
//       setCategories(res.data);
//     } catch (error) {
//       console.error("Error fetching categories:", error);
//     }
//   };

//   useEffect(() => {
//     fetchCategories();
//   }, []);

//   const handleChange = (e) => {
//     const { name, value, type, files } = e.target;
//     if (type === "file") {
//       setValues({ ...values, [name]: files[0] });
//     } else {
//       setValues({ ...values, [name]: value });
//     }
//   };

//   const handleSubmit = async () => {
//     const formData = new FormData();
  
//     // Exclude `thumbnail` from the `values` object when appending "course"
//     const { thumbnail, ...courseValues } = values;
  
//     formData.append("course", JSON.stringify(courseValues));
//     if (thumbnail) {
//       formData.append("thumbnail", thumbnail); // Append thumbnail separately
//     }
  
//     try {
//       const res = await axios.post(
//         `https://pjsofttech.in:14443/createCourse?institutecode=${getInstituteCode()}`,
//         formData,
//         {
//           headers: {
//             "Content-Type": "multipart/form-data",
//           },
//         }
//       );
//       console.log(res);
//       Swal.fire({
//         icon: "success",
//         title: "Course Added",
//         text: "Your course has been added successfully!",
//         confirmButtonText: "OK",
//       });
//       setValues({
//         coursename: "",
//         price: "",
//         discountPrice: "",
//         description: "",
//         coursefeatures: "",
//         date: "",
//         validity: "",
//         category: "",
//         thumbnail: null, // Reset thumbnail
//       });
//     } catch (err) {
//       console.error(err);
//       Swal.fire({
//         icon: "error",
//         title: "Oops...",
//         text: "Failed to add the course. Please try again.",
//         confirmButtonText: "OK",
//       });
//     }
//   };
  

//   return (
//     <>
//       <Box>
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={() => setOpenDialog(true)}
//         >
//           Add Category
//         </Button>

//         <Grid container spacing={2} mt={2}>
//           <Grid item xs={12} md={3}>
//             <TextField
//               label="Course Name"
//               variant="outlined"
//               fullWidth
//               name="coursename"
//               value={values.coursename}
//               onChange={handleChange}
//             />
//           </Grid>
//           <Grid item xs={12} md={3}>
//             <TextField
//               label="Category"
//               variant="outlined"
//               fullWidth
//               name="category"
//               value={values.category}
//               onChange={handleChange}
//               select
//             >
//               {categories.map((type) => (
//                 <MenuItem key={type.id} value={type.category}>
//                   {type.category}
//                 </MenuItem>
//               ))}
//             </TextField>
//           </Grid>
//           <Grid item xs={12} md={3}>
//             <TextField
//               label="Price"
//               variant="outlined"
//               fullWidth
//               name="price"
//               value={values.price}
//               onChange={handleChange}
//             />
//           </Grid>
//           <Grid item xs={12} md={3}>
//             <TextField
//               label="Discount Price"
//               variant="outlined"
//               fullWidth
//               name="discountPrice"
//               value={values.discountPrice}
//               onChange={handleChange}
//             />
//           </Grid>
//           <Grid item xs={12} md={3}>
//             <TextField
//               label="Description"
//               variant="outlined"
//               fullWidth
//               name="description"
//               value={values.description}
//               onChange={handleChange}
//             />
//           </Grid>
//           <Grid item xs={12} md={3}>
//             <TextField
//               label="Course Features"
//               variant="outlined"
//               fullWidth
//               name="coursefeatures"
//               value={values.coursefeatures}
//               onChange={handleChange}
//             />
//           </Grid>
//           <Grid item xs={12} md={3}>
//             <TextField
//               label="Date"
//               variant="outlined"
//               fullWidth
//               type="date"
//               name="date"
//               value={values.date}
//               onChange={handleChange}
//               InputLabelProps={{ shrink: true }}
//             />
//           </Grid>
//           <Grid item xs={12} md={3}>
//             <TextField
//               label="Validity"
//               variant="outlined"
//               fullWidth
//               name="validity"
//               value={values.validity}
//               onChange={handleChange}
//             />
//           </Grid>
//           <Grid item xs={12} md={3}>
//             <input
//               type="file"
//               name="thumbnail"
//               onChange={handleChange}
//             />
//           </Grid>
//         </Grid>
//         <Button
//           variant="contained"
//           fullWidth
//           color="secondary"
//           onClick={handleSubmit}
//           sx={{ marginTop: "10px" }}
//         >
//           Submit
//         </Button>
//       </Box>
//       <Category
//         open={openDialog}
//         onClose={() => setOpenDialog(false)}
//         fetchCategories={fetchCategories}
//       />
//     </>
//   );
// }

// export default Form;


import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import Swal from "sweetalert2";
import { Editor } from "@tinymce/tinymce-react";
// import DOMPurify from "dompurify";

function Courses() {
  const [values, setValues] = useState({
    coursename: "",
    price: "",
    discountPrice: "",
    description: "",
    coursefeatures: "",
    date: "",
    validity: "",
    category: "",
    thumbnail: null,
  });

  const [categories, setCategories] = useState([]);
  const [courses, setCourses] = useState([]);
  const [courseNames, setCourseNames] = useState([]); // To store dynamic course names
  const [openDialog, setOpenDialog] = useState(false);
  const [editingCourse, setEditingCourse] = useState(null); // For Edit functionality

  const getInstituteCode = () => localStorage.getItem("institutecode");

  // Fetch categories
  const fetchCategories = async () => {
    try {
      const res = await axios.get(
        `https://pjsofttech.in:14443/getAllCategories?institutecode=${getInstituteCode()}`
      );
      setCategories(res.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  // Fetch all courses
  const fetchCourses = async () => {
    try {
      const res = await axios.get(
        `https://pjsofttech.in:14443/getAllCourses?institutecode=${getInstituteCode()}`
      );
      setCourses(res.data);
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };

  // Fetch dynamic course names
  const fetchCourseNames = async () => {
    try {
      const res = await axios.get(
        `https://pjsofttech.in:14443/getAllExam?institutecode=${getInstituteCode()}`
      );
      setCourseNames(res.data);
    } catch (error) {
      console.error("Error fetching course names:", error);
    }
  };

  useEffect(() => {
    fetchCategories();
    fetchCourses();
    fetchCourseNames();
  }, []);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setValues({ ...values, [name]: files[0] });
    } else {
      setValues({ ...values, [name]: value });
    }
  };

  // Handle form submission (Add/Edit Course)
  const handleSubmit = async () => {
    const formData = new FormData();
    const { thumbnail, ...courseData } = values;
    formData.append("course", JSON.stringify(courseData));
    if (thumbnail) {
      formData.append("thumbnail", thumbnail);
    }

    try {
      if (editingCourse) {
        // Update course
        await axios.put(
          `https://pjsofttech.in:14443/updateCourse/${editingCourse.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        Swal.fire("Success", "Course updated successfully!", "success");
      } else {
        // Add course
        await axios.post(
          `https://pjsofttech.in:14443/createCourse?institutecode=${getInstituteCode()}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        Swal.fire("Success", "Course added successfully!", "success");
      }
      fetchCourses(); // Refresh courses
      handleCloseDialog();
    } catch (error) {
      console.error("Error saving course:", error);
      Swal.fire("Error", "Failed to save the course.", "error");
    }
  };

  // Handle course deletion
  const handleDelete = async (id) => {
    const confirm = await Swal.fire({
      title: "Are you sure?",
      text: "This action cannot be undone!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirm.isConfirmed) {
      try {
        await axios.delete(`https://pjsofttech.in:14443/deleteCourse/${id}`);
        Swal.fire("Deleted!", "Course has been deleted.", "success");
        fetchCourses(); // Refresh courses
      } catch (error) {
        console.error("Error deleting course:", error);
        Swal.fire("Error", "Failed to delete the course.", "error");
      }
    }
  };

  // Open dialog for Add/Edit
  const handleOpenDialog = (course = null) => {
    setEditingCourse(course);
    if (course) {
      setValues(course);
    } else {
      setValues({
        coursename: "",
        price: "",
        discountPrice: "",
        description: "",
        coursefeatures: "",
        date: "",
        validity: "",
        category: "",
        thumbnail: null,
      });
    }
    setOpenDialog(true);
  };

  // Close dialog
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditingCourse(null);
  };

  return (
     <Paper elevation={3}>
    <Box p={2}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid>
          <Button
            variant="contained"
            color="primary"
            sx={{ marginLeft: "10px" }}
            onClick={() => handleOpenDialog()}
          >
            Add Course
          </Button>
        </Grid>
      </Grid>
      <TableContainer component={Paper} sx={{ marginTop: 3 }} className="table-root">
        <Table>
          <TableHead>
            <TableRow>
            <TableCell>No</TableCell>
              <TableCell>Course Name</TableCell>
              {/* <TableCell>Course Features</TableCell> */}
              <TableCell>Date</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Discount Price</TableCell>
              <TableCell>Category</TableCell>
              {/* <TableCell>Description</TableCell> */}
              <TableCell>Validity</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {courses.map((course) => (
              <TableRow key={course.id}>
                <TableCell>{course.id}</TableCell>
                <TableCell>{course.coursename}</TableCell>
                {/* <TableCell>{course.coursefeatures}</TableCell> */}
                <TableCell> {course.date
              ? new Date(course.date).toLocaleDateString("en-GB"): "-"}</TableCell>
                <TableCell>{course.price}</TableCell>
                <TableCell>{course.discountPrice}</TableCell>
                <TableCell>{course.category}</TableCell>
                {/* <TableCell>
  <div
    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(course.description) }}
  />
</TableCell> */}
                <TableCell>{course.validity}</TableCell>
                <TableCell>
                  <IconButton
                    color="primary"
                    onClick={() => handleOpenDialog(course)}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    color="error"
                    onClick={() => handleDelete(course.id)}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Dialog for Add/Edit */}
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth='lg' fullWidth className="textField-root">
        <DialogTitle color={'black'}>
          {editingCourse ? "Edit Course" : "Add Course"}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} marginTop={1}>
            <Grid item xs={12} md={4}>
              <TextField
                select
                label="Course Name"
                fullWidth
                variant="outlined"
                name="coursename"
                value={values.coursename}
                onChange={handleChange}
              >
                {courseNames.map((name) => (
                  <MenuItem key={name.id} value={name.name}>
                    {name.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="Price"
                fullWidth
                variant="outlined"
                name="price"
                value={values.price}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="Discount Price"
                fullWidth
                variant="outlined"
                name="discountPrice"
                value={values.discountPrice}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="Category"
                fullWidth
                variant="outlined"
                name="category"
                value={values.category}
                onChange={handleChange}
                select
              >
                {categories.map((cat) => (
                  <MenuItem key={cat.id} value={cat.category}>
                    {cat.category}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          {/* <Grid item xs={12} md={4}>
            <TextField
              label="Course Features"
              variant="outlined"
              fullWidth
              name="coursefeatures"
              value={values.coursefeatures}
              onChange={handleChange}
            />
          </Grid> */}
          <Grid item xs={12} md={4}>
            <TextField
              label="Date"
              variant="outlined"
              fullWidth
              type="date"
              name="date"
              value={values.date}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              label="Validity"
              variant="outlined"
              fullWidth
              name="validity"
              value={values.validity}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <input
              type="file"
              name="thumbnail"
              onChange={handleChange}
            />
          </Grid>
          </Grid>
          <Grid container spacing={2} marginTop={1}>
          <Grid item xs={12} md={12}>
  <Editor
    apiKey="r27f3ompzsl1xcupix7cax0ote3bg9t9o23h3o00fkzudbsp" // Optional: Use your API key for the cloud version
    value={values.description} // Bind to your state or form value
    onEditorChange={(description) => handleChange({ target: { name: "description", value: description } })}
    init={{
      height: 400,
      menubar: false,
      plugins: [
        'advlist', 'autolink', 'lists', 'charmap', 'preview', 'anchor', 'searchreplace', 'wordcount'
      ],
      toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist | outdent indent ',
      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
    }}
  />
</Grid>
</Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
    </Paper>
  );
}

export default Courses;
