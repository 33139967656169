
import React, { useState, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Typography,
} from '@mui/material';
import { SketchPicker } from 'react-color';
import {
    createCourse,
    getAllCourses,
    updateCourse,
    deleteCourse,
    addCourseColor,
    updateCourseColor,
} from './Api/AllApi';
import Swal from 'sweetalert2';
import { Delete, Edit } from '@mui/icons-material';

const Course = () => {
    const [courses, setCourses] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [openColorDialog, setOpenColorDialog] = useState(false);
    const [formData, setFormData] = useState({
        courseTitle: '',
        link: '',
        description: '',
        courseImage: null,
    });
    const [editingCourse, setEditingCourse] = useState(null);
    const [courseColor, setCourseColor] = useState('#ffffff');
    const [selectedColor, setSelectedColor] = useState('#ffffff');
    const institutecode = () => localStorage.getItem("institutecode");

    useEffect(() => {
        fetchCourses();
    }, []);

    const fetchCourses = async () => {
        try {
            const response = await getAllCourses(institutecode());
            setCourses(response.data);
            if (response.data.length > 0 && response.data[0].courseColor) {
                setCourseColor(response.data[0].courseColor);
            }
        } catch (error) {
            console.error('Error fetching courses:', error);
        }
    };

    const handleOpenDialog = (course = null) => {
        setEditingCourse(course);
        if (course) {
            setFormData({
                courseTitle: course.courseTitle,
                link: course.link,
                description: course.description,
                courseImage: null,
            });
        } else {
            setFormData({
                courseTitle: '',
                link: '',
                description: '',
                courseImage: null,
            });
        }
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: files ? files[0] : value,
        }));
    };

    const handleSubmit = async () => {
        try {
            const data = new FormData();
            data.append('institutecode', institutecode()); // Add institutecode
            data.append('courseTitle', formData.courseTitle);
            data.append('link', formData.link);
            data.append('description', formData.description);
            if (formData.courseImage) {
                data.append('courseImage', formData.courseImage);
            }

            if (editingCourse) {
                // Update course
                await updateCourse(editingCourse.courseId, data);
                Swal.fire('Success', 'Course updated successfully', 'success');
            } else {
                // Create course
                await createCourse(data);
                Swal.fire('Success', 'Course created successfully', 'success');
            }

            fetchCourses(); // Refresh course list
            handleCloseDialog();
        } catch (error) {
            console.error('Error submitting course:', error.response || error);
            Swal.fire('Error', error.response?.data?.message || 'Failed to submit course', 'error');
        }
    };

    const handleDelete = async (courseId) => {
        try {
            await deleteCourse(courseId);
            Swal.fire('Success', 'Course deleted successfully', 'success');
            fetchCourses();
        } catch (error) {
            console.error('Error deleting course:', error);
        }
    };

    const handleColorChange = async () => {
        try {
            if (courseColor) {
                await updateCourseColor(institutecode(), courseColor);
                Swal.fire('Success', 'Course color updated successfully', 'success');
            } else {
                await addCourseColor(institutecode(), courseColor);
                Swal.fire('Success', 'Course color added successfully', 'success');
            }
            fetchCourses();
            setOpenColorDialog(false);
        } catch (error) {
            console.error('Error updating course color:', error);
        }
    };

    return (
      <div>
         <Typography variant="h4" gutterBottom>
        Courses
      </Typography>
        <Button variant="contained" onClick={() => handleOpenDialog()}>
          Add Course
        </Button>
        <Button
          variant="contained"
          style={{ marginLeft: '8px' }}
          onClick={() => setOpenColorDialog(true)}
        >
          Select Color for card
        </Button>
        <TableContainer component={Paper} style={{ marginTop: '16px' }} className="table-root">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Link</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Image</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {courses.map((course) => (
                <TableRow key={course.courseId}>
                  <TableCell>{course.courseTitle}</TableCell>
                  <TableCell>{course.link}</TableCell>
                  <TableCell>{course.description}</TableCell>
                  <TableCell>
                  {course.courseImage && (
                    <img
                      src={course.courseImage}
                      alt={course.courseTitle}
                      style={{ width: "50px", height: "50px", objectFit: "cover" }}
                    />
                  )}
                </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleOpenDialog(course)} color='primary'> <Edit /></IconButton>
                    <IconButton onClick={() => handleDelete(course.courseId)} color="error">
                             <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="sm" className="textField-root">
          <DialogTitle>{editingCourse ? 'Edit Course' : 'Add Course'}</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="courseTitle"
                  label="Course Title"
                  fullWidth
                  value={formData.courseTitle}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="link"
                  label="Course Link"
                  fullWidth
                  value={formData.link}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="description"
                  label="Course Description"
                  fullWidth
                  multiline
                  rows={4}
                  value={formData.description}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" component="label">
                  Upload Image
                  <input
                    type="file"
                    hidden
                    name="courseImage"
                    onChange={handleChange}
                  />
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            <Button onClick={handleSubmit} variant="contained">
              {editingCourse ? 'Update' : 'Submit'}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openColorDialog} onClose={() => setOpenColorDialog(false)} fullWidth maxWidth="xs">
          <DialogTitle>Select Course Color</DialogTitle>
          <DialogContent>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <SketchPicker
                color={courseColor}
                onChangeComplete={(color) => setCourseColor(color.hex)}
              />
              <div
                style={{
                  width: '100px',
                  height: '100px',
                  backgroundColor: courseColor,
                  borderRadius: '50%',
                  border: '1px solid #ccc',
                }}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenColorDialog(false)}>Cancel</Button>
            <Button onClick={handleColorChange} variant="contained">
              Save Color
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
};

export default Course;
