import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Button, Grid, Card, CardContent, CardMedia, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';

import ebook3 from './img/ebook3.jpg';
import ebook5 from './img/ebook5.webp';
// Define the chapter data based on the category
const chaptersData = {
  'Contemporary Fiction': [
    { title: 'Chapter 1', image: ebook5, description: 'Description of Chapter 1', price: '100.00', mrp: '120.00', availability: 'Available' },
    { title: 'Chapter 2', image: ebook5, description: 'Description of Chapter 2', price: '10.00', mrp: '12.00', availability: 'Not Available' },
    { title: 'Chapter 1', image: ebook5, description: 'Description of Chapter 1', price: '10.00', mrp: '12.00', availability: 'Available' },
    { title: 'Chapter 1', image: ebook5, description: 'Description of Chapter 1', price: '10.00', mrp: '12.00', availability: 'Available' },
    // Add more chapters as needed
  ],
  'Self-Improvement':[
    { title: 'Chapter 1', image: ebook5, description: 'Description of Chapter 1', price: '10.00', mrp: '12.00', availability: 'Available' },
    { title: 'Chapter 2', image: ebook5, description: 'Description of Chapter 2', price: '10.00', mrp: '12.00', availability: 'Not Available' },
  ],
  'Historical':[
    { title: 'Chapter 1', image: ebook5, description: 'Description of Chapter 1', price: '10.00', mrp: '12.00', availability: 'Available' },
    { title: 'Chapter 2', image: ebook5, description: 'Description of Chapter 2', price: '10.00', mrp: '12.00', availability: 'Not Available' },
    { title: 'Chapter 1', image: ebook5, description: 'Description of Chapter 1', price: '10.00', mrp: '12.00', availability: 'Available' },
  ],
  'Science & Mathematics':[
    { title: 'Chapter 1', image: ebook5, description: 'Description of Chapter 1', price: '10.00', mrp: '12.00', availability: 'Available' },
    { title: 'Chapter 2', image: ebook5, description: 'Description of Chapter 2', price: '10.00', mrp: '12.00', availability: 'Not Available' },
    { title: 'Chapter 1', image: ebook5, description: 'Description of Chapter 1', price: '10.00', mrp: '12.00', availability: 'Available' },
    { title: 'Chapter 1', image: ebook5, description: 'Description of Chapter 1', price: '10.00', mrp: '12.00', availability: 'Available' },
    { title: 'Chapter 1', image: ebook5, description: 'Description of Chapter 1', price: '10.00', mrp: '12.00', availability: 'Not Available' },
  ],
  'Marathi':[
    { title: 'Chapter 1', image: ebook5, description: 'Description of Chapter 1', price: '10.00', mrp: '12.00', availability: 'Not Available' },
    { title: 'Chapter 2', image: ebook5, description: 'Description of Chapter 2', price: '10.00', mrp: '12.00', availability: 'Not Available' },
  ],
  'Finance & Money Management':[
    { title: 'Chapter 1', image: ebook5, description: 'Description of Chapter 1', price: '10.00', mrp: '12.00', availability: 'Available' },
    { title: 'Chapter 2', image: ebook5, description: 'Description of Chapter 2', price: '10.00', mrp: '12.00', availability: 'Not Available' },
    { title: 'Chapter 2', image: ebook5, description: 'Description of Chapter 2', price: '10.00', mrp: '12.00', availability: 'Not Available' },
  ],

  // Define other categories similarly
};

const Chapters = () => {
  const { category } = useParams();
  const chapters = chaptersData[category] || [];
  const [openModal, setOpenModal] = useState(false);
  const [selectedBook, setSelectedBook] = useState(null);
  const [userDetails, setUserDetails] = useState({
    name: '',
    address: '',
    pincode: '',
    deliveryTime: '',
  });

  const handleRedirectToRazorpay = () => {
    window.open('https://www.razorpay.com/', '_blank');
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        {category} Chapters
      </Typography>
      <Grid container spacing={3} mt={3}>
        {chapters.map((chapter, index) => (
          <Grid item xs={12} sm={6} md={4} lg={2} key={index}>
          <Card
            sx={{
              height: '100%',
              transition: 'transform 0.2s',
              '&:hover': {
                transform: 'scale(1.05)', // Scale card on hover
              },
              boxShadow: 5, // Optional: Add a box shadow for better appearance
            }}
          >
            <CardMedia
              component="img"
              alt={chapter.title}
              image={chapter.image}
              sx={{ 
                height: 100, // Adjusted height for smaller card
                width: '150%',
                objectFit: 'cover',
                transition: 'opacity 0.2s',
                '&:hover': {
                  opacity: 0.7, // Change opacity on hover
                },
              }}
            />
            <CardContent sx={{ padding: 1 }}> {/* Reduced padding for compactness */}
              <Typography variant="h6" component="div" sx={{ fontSize: '0.9rem' }}>
                {chapter.title} <span style={{ color: chapter.availability === 'Available' ? 'green' : 'red' }}>({chapter.availability})</span>
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.8rem' }}>
                {chapter.description}
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '0.9rem' }}>
                  Rs.{chapter.price}
                </Typography>
                <Typography variant="body2" sx={{ textDecoration: 'line-through', color: 'gray', fontSize: '0.8rem' }}>
                  Rs.{chapter.mrp}
                </Typography>
              </Box>
              <Button variant="contained" color="primary" onClick={handleRedirectToRazorpay} sx={{ mt: 2, fontSize: '0.8rem' }}>
                Buy Now
              </Button>
            </CardContent>
          </Card>
        </Grid>
        
        ))}
      </Grid>
    </Box>
  );
};

export default Chapters;
