import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Typography,
  FormControl,
  TextField,
  MenuItem,
  Box,
  Grid,
  Card,
  CardContent,
  CardActionArea,
  IconButton,
  Fade,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Swal from "sweetalert2"; // Import SweetAlert2
import axios from "axios";
import './Attendance.css';
const AttendanceTable = () => {
  const [classIds, setClassIds] = useState([]);
  const [expandedClassId, setExpandedClassId] = useState(null);
  const [attendanceData, setAttendanceData] = useState({});
  const [absentStudents, setAbsentStudents] = useState([]);
  const [selectedRollNos, setSelectedRollNos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [nameFilter, setNameFilter] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [hoveredCard, setHoveredCard] = useState(null);
  const [dateFilter, setDateFilter] = useState("today");
const [startDate, setStartDate] = useState("");
const [endDate, setEndDate] = useState("");


  const institutecode = localStorage.getItem("institutecode");

  useEffect(() => {
    const fetchClassIds = async () => {
      try {
        const response = await axios.get(
          `https://pjsofttech.in:13443/by-institutecode?institutecode=${institutecode}`
        );
        setClassIds(response.data);
      } catch (err) {
        setError("Failed to fetch class IDs.");
      }
    };
    fetchClassIds();
  }, [institutecode]);

  const fetchAttendanceByClassId = async (classId) => {
    setLoading(true);
    try {
      let url = `https://pjsofttech.in:13443/attendanceFilterbyClassId?classId=${classId}&filter=${dateFilter}`;
      if (dateFilter === "custom" && startDate && endDate) {
        url += `&startDate=${startDate}&endDate=${endDate}`;
      }
      const response = await axios.get(url);
      setAttendanceData((prev) => ({
        ...prev,
        [classId]: [
          ...response.data.presentStudents,
          ...response.data.absentStudents,
        ],
      }));
      setAbsentStudents(response.data.absentStudents);
      setExpandedClassId(classId);
    } catch (err) {
      setError("Failed to fetch attendance data.");
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    if (expandedClassId) {
      fetchAttendanceByClassId(expandedClassId);
    }
  }, [expandedClassId, dateFilter, startDate, endDate]);
  

  const handleBackClick = () => {
    setExpandedClassId(null);
    setStatusFilter("");
    setNameFilter("");
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedRollNos([]);
  };

  const handleCheckboxChange = (rollNo) => {
    setSelectedRollNos((prev) =>
      prev.includes(rollNo)
        ? prev.filter((num) => num !== rollNo)
        : [...prev, rollNo]
    );
  };

  const handleAddAttendance = async () => {
    try {
      await axios.post(
        `https://pjsofttech.in:13443/LoginforSaveattendanceByTeacher?classId=${expandedClassId}&rollNos=${selectedRollNos.join(
          ","
        )}`
      );
      Swal.fire({
        icon: "success",
        title: "Attendance successfully added!",
        showConfirmButton: false,
        timer: 1500,
      });
      setSelectedRollNos([]); // Reset selected roll numbers
      handleDialogClose();
      fetchAttendanceByClassId(expandedClassId); // Refresh the attendance table
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Failed to add attendance.",
        text: err.message,
      });
    }
  };

  const filterData = (data) =>
    data.filter((item) => {
      return (
        (!statusFilter || item.status === statusFilter) &&
        (!nameFilter || item.name.toLowerCase().includes(nameFilter.toLowerCase()))
      );
    });

  const handleMouseEnter = (index) => {
    setHoveredCard(index);
  };

  const handleMouseLeave = () => {
    setHoveredCard(null);
  };

  return (
    <Paper elevation={3} sx={{ padding: "20px", borderRadius: "8px" }}>
      <Typography variant="h5" gutterBottom>
        Class Attendance
      </Typography>
      {expandedClassId ? (
        <Fade in={true} timeout={500}>
          <Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <Box>
                <IconButton onClick={handleBackClick} sx={{ cursor: "pointer" }}>
                  <ArrowBackIcon />
                </IconButton>
              </Box>
              <Button variant="contained" color="primary" onClick={handleDialogOpen}>
                Add Attendance
              </Button>
            </Box>

            {/* Cards for Present and Absent Counts */}
            <Grid container spacing={2} mb={3}>
              <Grid item xs={3}>
                <Card sx={{ backgroundColor: "#F9E79F", borderRadius:"10px"}}>
                  <CardContent>
                    <Typography variant="h6">Present</Typography>
                    <Typography variant="h4">
                      {attendanceData[expandedClassId]?.filter((student) => student.status === "Present").length || 0}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={3}>
                <Card sx={{ backgroundColor: "#FF6F61", borderRadius:"10px" }}>
                  <CardContent>
                    <Typography variant="h6">Absent</Typography>
                    <Typography variant="h4">
                      {attendanceData[expandedClassId]?.filter((student) => student.status === "Absent").length || 0}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Box mb={2} display={"flex"} justifyContent={"space-evenly"}>
              <Typography variant="body1"><strong>Batch Name:</strong> {classIds.find((cls) => cls.classId === expandedClassId)?.batchName || "-"}</Typography>
              <Typography variant="body1"><strong>Today's Date:</strong> {new Date().toLocaleDateString()}</Typography>
              <Typography variant="body1"><strong>Academic Year:</strong> {classIds.find((cls) => cls.classId === expandedClassId)?.academicYear || "-"}</Typography>
              <Typography variant="body1"><strong>Batch Start Time:</strong> {classIds.find((cls) => cls.classId === expandedClassId)?.batchStartTime || "-"}</Typography>
              <Typography variant="body1"><strong>Batch End Time:</strong> {classIds.find((cls) => cls.classId === expandedClassId)?.batchEndTime || "-"}</Typography>
            </Box>
            <Box display="flex" gap={2} mb={3} className="textField-root">
            <FormControl fullWidth>
    <TextField
      select
      label="Date Filter"
      value={dateFilter}
      onChange={(e) => setDateFilter(e.target.value)}
    >
      <MenuItem value="today">Today</MenuItem>
      <MenuItem value="7days">Last 7 Days</MenuItem>
      <MenuItem value="30days">Last 30 Days</MenuItem>
      <MenuItem value="365days">Last 365 Days</MenuItem>
      <MenuItem value="custom">Custom</MenuItem>
    </TextField>
  </FormControl>
  {dateFilter === "custom" && (
    <>
      <FormControl fullWidth>
        <TextField
          type="date"
          label="Start Date"
          InputLabelProps={{ shrink: true }}
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          type="date"
          label="End Date"
          InputLabelProps={{ shrink: true }}
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
      </FormControl>
    </>
  )}
              <FormControl fullWidth>
                <TextField
                  label="Search Name"
                  value={nameFilter}
                  onChange={(e) => setNameFilter(e.target.value)}
                />
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  select
                  label="Status"
                  value={statusFilter}
                  onChange={(e) => setStatusFilter(e.target.value)}
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value="Present">Present</MenuItem>
                  <MenuItem value="On time">On time</MenuItem>
                  <MenuItem value="Late">Late</MenuItem>
                  <MenuItem value="Absent">Absent</MenuItem>
                </TextField>
              </FormControl>
            </Box>
            {loading ? (
              <CircularProgress />
            ) : (
              attendanceData[expandedClassId] && (
                <Fade in={true} timeout={500}>
                  <TableContainer component={Paper} className="table-root">
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Roll No</TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell>Date</TableCell>
                          <TableCell>Login Time</TableCell>
                          <TableCell>Logout Time</TableCell>
                          <TableCell>Login IP</TableCell>
                          <TableCell>Logout IP</TableCell>
                          <TableCell>Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filterData(attendanceData[expandedClassId]).map((row, index) => (
                          <TableRow key={index}>
                            <TableCell>{row.rollNo}</TableCell>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.todaysDate}</TableCell>
                            <TableCell>{row.loginTime || "-"}</TableCell>
                            <TableCell>{row.logoutTime || "-"}</TableCell>
                            <TableCell>{row.ip || "-"}</TableCell>
                            <TableCell>{row.logoutIP || "-"}</TableCell>
                            <TableCell
                              sx={{
                                fontWeight: "bold",
                                color:
                                  row.status === "On time"
                                    ? "green"
                                    :row.status === "Present"
                                    ? "green"
                                    : row.status === "Late"
                                    ? "orange"
                                    : row.status === "Absent"
                                    ? "red"
                                    : "inherit",
                              }}
                            >
                              {row.status}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Fade>
              )
            )}
          </Box>
        </Fade>
      ) : (
        <Grid container spacing={2}>
          {classIds.map((classItem, index) => (
            <Grid item xs={12} sm={6} md={3} key={classItem.classId}>
                <Card
                  sx={{ cursor: "pointer", backgroundColor: "#f2f2f2" }}
                  onClick={() => fetchAttendanceByClassId(classItem.classId)}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                  className={`card ${hoveredCard === index ? 'hovered' : ''}`}
                >
                  <CardActionArea>
                    <CardContent>
                      <Typography variant="h6" textAlign="center">
                        {classItem.batchName}
                      </Typography>
                      <hr />
                      <Typography variant="body2">
                        Medium: {classItem.medium.mediumName}
                      </Typography>
                      <Typography variant="body2">
                        Course: {classItem.course.cname}
                      </Typography>
                      <Typography variant="body2">
                        Year: {classItem.academicYear}
                      </Typography>
                      {hoveredCard === index && <div className="hover-text">Show Attendance</div>}
                    </CardContent>
                  </CardActionArea>
                </Card>
            </Grid>
          ))}
        </Grid>
      )}

      {error && <Typography color="error">{error}</Typography>}

      <Dialog open={dialogOpen} onClose={handleDialogClose} fullWidth maxWidth="lg">
        <DialogTitle>Mark Attendance for Absent Students</DialogTitle>
        <DialogContent>
          <TableContainer component={Paper} className="table-root">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Roll No</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Mark Present</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {absentStudents.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.rollNo}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        color:
                          row.status === "On time"
                            ? "green"
                            : row.status === "Late"
                            ? "orange"
                            : row.status === "Absent"
                            ? "red"
                            : "inherit",
                      }}
                    >
                      {row.status}
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={selectedRollNos.includes(row.rollNo)}
                        onChange={() => handleCheckboxChange(row.rollNo)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleAddAttendance}
            color="primary"
            variant="contained"
            disabled={selectedRollNos.length === 0}
          >
            Add Attendance
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default AttendanceTable;
