import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
} from "@mui/material";
import { SketchPicker } from "react-color";
import Swal from "sweetalert2";
import {
  getAllFacilities,
  createFacility,
  updateFacility,
  deleteFacility,
  addFacilityColor,
  updateFacilityColor,
} from "./Api/AllApi";
import { Delete, Edit } from "@mui/icons-material";

const Facility = () => {
  const [facilities, setFacilities] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openColorDialog, setOpenColorDialog] = useState(false);
  const [formData, setFormData] = useState({
    facilityName: "",
    experienceInYear: "",
    subject: "",
    facilityEducation: "",
    facilityImage: null,
  });
  const [editingFacility, setEditingFacility] = useState(null);
  const [facilityColor, setFacilityColor] = useState("#ffffff");
  const institutecode = () => localStorage.getItem("institutecode");

  useEffect(() => {
    fetchFacilities();
  }, []);

  const fetchFacilities = async () => {
    try {
      const response = await getAllFacilities(institutecode());
      setFacilities(response.data);
      if (response.data.length > 0 && response.data[0].facilityColor) {
        setFacilityColor(response.data[0].facilityColor);
      }
    } catch (error) {
      console.error("Error fetching facilities:", error);
    }
  };

  const handleOpenDialog = (facility = null) => {
    setEditingFacility(facility);
    if (facility) {
      setFormData({
        facilityName: facility.facilityName,
        experienceInYear: facility.experienceInYear,
        subject: facility.subject,
        facilityEducation: facility.facilityEducation,
        facilityImage: null,
      });
    } else {
      setFormData({
        facilityName: "",
        experienceInYear: "",
        subject: "",
        facilityEducation: "",
        facilityImage: null,
      });
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: files ? files[0] : value,
    }));
  };

  const handleSubmit = async () => {
    try {
      const data = new FormData();
      data.append("institutecode", institutecode());
      data.append("facilityName", formData.facilityName);
      data.append("experienceInYear", formData.experienceInYear);
      data.append("subject", formData.subject);
      data.append("facilityEducation", formData.facilityEducation);
      if (formData.facilityImage) {
        data.append("facilityImage", formData.facilityImage);
      }

      if (editingFacility) {
        await updateFacility(editingFacility.id, data);
        Swal.fire("Success", "Facility updated successfully", "success");
      } else {
        await createFacility(data);
        Swal.fire("Success", "Facility created successfully", "success");
      }

      fetchFacilities();
      handleCloseDialog();
    } catch (error) {
      console.error("Error submitting facility:", error.response || error);
      Swal.fire("Error", error.response?.data?.message || "Failed to submit facility", "error");
    }
  };

  const handleDelete = async (facilityId) => {
    try {
      await deleteFacility(facilityId);
      Swal.fire("Success", "Facility deleted successfully", "success");
      fetchFacilities();
    } catch (error) {
      console.error("Error deleting facility:", error);
    }
  };

  const handleColorChange = async () => {
    try {
      if (facilityColor) {
        await updateFacilityColor(institutecode(), facilityColor);
        Swal.fire("Success", "Facility color updated successfully", "success");
      } else {
        await addFacilityColor(institutecode(), facilityColor);
        Swal.fire("Success", "Facility color added successfully", "success");
      }
      fetchFacilities();
      setOpenColorDialog(false);
    } catch (error) {
      console.error("Error updating facility color:", error);
    }
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Facilities
      </Typography>
      <Button variant="contained" onClick={() => handleOpenDialog()}>
        Add Facility
      </Button>
      <Button
        variant="contained"
        style={{ marginLeft: "8px" }}
        onClick={() => setOpenColorDialog(true)}
      >
        Select Color for card
      </Button>
      <TableContainer component={Paper} style={{ marginTop: "16px" }} className="table-root">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Experience</TableCell>
              <TableCell>Subject</TableCell>
              <TableCell>Education</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {facilities.map((facility) => (
              <TableRow key={facility.facilityId}>
                <TableCell>{facility.facilityName}</TableCell>
                <TableCell>{facility.experienceInYear}</TableCell>
                <TableCell>{facility.subject}</TableCell>
                <TableCell>{facility.facilityEducation}</TableCell>
                <TableCell>
                  {facility.facilityImage && (
                    <img
                      src={facility.facilityImage}
                      alt={facility.facilityName}
                      style={{ width: "50px", height: "50px", objectFit: "cover" }}
                    />
                  )}
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => handleOpenDialog(facility)} color="primary">
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(facility.facilityId)} color="error">
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="sm" className="textField-root">
        <DialogTitle>{editingFacility ? "Edit Facility" : "Add Facility"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="facilityName"
                label="Facility Name"
                fullWidth
                value={formData.facilityName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="experienceInYear"
                label="Experience (Years)"
                fullWidth
                value={formData.experienceInYear}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="subject"
                label="Subject"
                fullWidth
                value={formData.subject}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="facilityEducation"
                label="Education"
                fullWidth
                value={formData.facilityEducation}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" component="label">
                Upload Image
                <input
                  type="file"
                  hidden
                  name="facilityImage"
                  onChange={handleChange}
                />
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSubmit} variant="contained">
            {editingFacility ? "Update" : "Submit"}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openColorDialog} onClose={() => setOpenColorDialog(false)} fullWidth maxWidth="xs">
        <DialogTitle>Select Facility Color</DialogTitle>
        <DialogContent>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <SketchPicker
              color={facilityColor}
              onChangeComplete={(color) => setFacilityColor(color.hex)}
            />
            <div
              style={{
                width: "100px",
                height: "100px",
                backgroundColor: facilityColor,
                borderRadius: "50%",
                border: "1px solid #ccc",
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenColorDialog(false)}>Cancel</Button>
          <Button onClick={handleColorChange} variant="contained">
            Save Color
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Facility;