import axios from 'axios';

const baseURL = 'https://pjsofttech.in:17443';


const institutecode = localStorage.getItem('institutecode');


// entity = Type 

export const getTypes = async (institutecode) => {
  try {
    const response = await axios.get(
      `${baseURL}/getAllTypes?institutecode=${institutecode}`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching types:', error);
    throw error;
  }
};

export const deleteType = async (typeId) => {
  try {
    await axios.delete(`${baseURL}/deleteType/${typeId}`);
  } catch (error) {
    console.error('Error deleting type:', error);
    throw error;
  }
};

export const addType = async (type, institutecode) => {
  try {
    await axios.post(
      `${baseURL}/createType?institutecode=${institutecode}`,
      type
    );
  } catch (error) {
    console.error('Error adding type:', error);
    throw error;
  }
};


// entity = status
export const getStatuses = async (institutecode) => {
  try {
    const response = await axios.get(
      `${baseURL}/getAllStatuses?institutecode=${institutecode}`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching statuses:', error);
    throw error;
  }
};


export const deleteStatus = async (statusId) => {
  try {
    await axios.delete(`${baseURL}/deleteStatus/${statusId}`);
  } catch (error) {
    console.error('Error deleting status:', error);
    throw error;
  }
};


export const addStatus = async (status, institutecode) => {
  try {
    await axios.post(
      `${baseURL}/createStatus?institutecode=${institutecode}`,
      { status: status.status }
    );
  } catch (error) {
    console.error('Error adding status:', error);
    throw error;
  }
}; 





// entity = bookname 
export const getBookNames = async () => {
  try {
    const response = await axios.get(
      `${baseURL}/getAllBookNames?institutecode=${institutecode}`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching book names:', error);
    throw error;
  }
};


export const deleteBookName = async (bookId) => {
  try {
    await axios.delete(`${baseURL}/deleteBookName/${bookId}`);
  } catch (error) {
    console.error('Error deleting book name:', error);
    throw error;
  }
};


export const addBookName = async (bookName, price) => {
  try {
    await axios.post(
      `${baseURL}/createBookName?institutecode=${institutecode}`,
      { bookName, price }
    );
  } catch (error) {
    console.error('Error adding book name:', error);
    throw error;
  }
};


export const updateBookName = async (id, updatedData) => {
  const response = await axios.put(`${baseURL}/updateBookName/${id}`, updatedData);
  return response.data;
};


// entity = vendorname
export const getVendorNames = async () => {
  try {
    const response = await axios.get(`${baseURL}/getAllVendorNames?institutecode=${institutecode}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching Vendor Names:", error);
    throw error;
  }
};


export const deleteVendorName = async (vendorId) => {
  try {
    await axios.delete(`${baseURL}/deleteVendorName/${vendorId}`);
  } catch (error) {
    console.error("Error deleting vendor name:", error);
    throw error;
  }
};


export const createVendorName = async (vendorName) => {
  try {
    await axios.post(
      `${baseURL}/createVendorName?institutecode=${institutecode}`,
      { vendorName }
    );
  } catch (error) {
    console.error("Error adding vendor name:", error);
    throw error;
  }
};

// all shipments forms
export const getShipments = async () => {
  const institutecode = localStorage.getItem('institutecode');
  try {
    const response = await axios.get(`${baseURL}/getAllShipmentForms?institutecode=${institutecode}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching shipment data:', error);
    throw error;
  }
};


export const getSentBys = async () => {
  try {
    const response = await axios.get(
      `${baseURL}/getAllSentBy?institutecode=${institutecode}`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching sent bys:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const deleteSentBys = async (sentById) => {
  try {
    await axios.delete(`${baseURL}/deleteSentBy/${sentById}`); // Corrected axios.delete
  } catch (error) {
    console.error('Error deleting sent by:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const addSentBy = async (sentBy, link) => {
  try {
    await axios.post(
      `${baseURL}/createSentBy?institutecode=${institutecode}`,
      { sentBy, link }
    );
  } catch (error) {
    console.error('Error adding sent by:', error.response ? error.response.data : error.message);
    throw error;
  }
};