import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Grid,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { getBookNames, deleteBookName, addBookName, updateBookName } from "./API/ShipmentAPI"; // Import API logic

const MySwal = withReactContent(Swal);

const Bookname = () => {
  const { id } = useParams(); // If needed, use the id param
  const [bookNames, setBookNames] = useState([]);
  const [filteredBookNames, setFilteredBookNames] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [selectedBook, setSelectedBook] = useState(null);
  const [bookName, setBookName] = useState({ bookName: "", price: "" });

  const institutecode = localStorage.getItem("institutecode") || "MIT1"; // Default to MIT1 if not found

  useEffect(() => {
    loadBookNames();
  }, [institutecode]);

  useEffect(() => {
    filterBookNames();
  }, [searchTerm, bookNames]);

  const loadBookNames = async () => {
    try {
      const data = await getBookNames();
      setBookNames(data);
      setFilteredBookNames(data);
    } catch (error) {
      console.error("Error fetching book names:", error);
    }
  };

  const filterBookNames = () => {
    const filtered = bookNames.filter((book) =>
      book.bookName?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredBookNames(filtered);
  };

  const handleDeleteBookName = (book) => {
    MySwal.fire({
      title: "Are you sure?",
      text: `Do you want to delete book name: ${book.bookName}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteBookNameHandler(book.id);
      }
    });
  };

  const deleteBookNameHandler = async (bookId) => {
    try {
      await deleteBookName(bookId);
      MySwal.fire({
        title: "Deleted!",
        text: "The book name has been deleted successfully.",
        icon: "success",
        timer: 1500,
        showConfirmButton: false,
      });
      loadBookNames();
    } catch (error) {
      console.error("Error deleting book name:", error);
      MySwal.fire({
        title: "Error!",
        text: "There was an error deleting the book name.",
        icon: "error",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  const handleOpenAddDialog = () => {
    setBookName({ bookName: "", price: "" });
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
  };

  const onAddBookNameSubmit = async (e) => {
    e.preventDefault();
    if (!bookName.bookName || !bookName.price) {
      MySwal.fire({
        title: "Error!",
        text: "Please fill out the necessary fields.",
        icon: "error",
        timer: 2000,
        showConfirmButton: false,
      });
      return;
    }

    try {
      await addBookName(bookName.bookName, bookName.price);
      MySwal.fire({
        title: "Success!",
        text: "The book name has been added successfully.",
        icon: "success",
        timer: 1500,
        showConfirmButton: false,
      });
      handleCloseAddDialog();
      loadBookNames();
    } catch (error) {
      console.error("There was an error adding the book name!", error);
      MySwal.fire({
        title: "Error!",
        text: "There was an error adding the book name.",
        icon: "error",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  const handleOpenUpdateDialog = (book) => {
    setSelectedBook(book);
    setOpenUpdateDialog(true);
  };

  const handleCloseUpdateDialog = () => {
    setOpenUpdateDialog(false);
    setSelectedBook(null);
  };

  const onUpdateBookNameSubmit = async () => {
    if (!selectedBook.bookName || !selectedBook.price) {
      MySwal.fire({
        title: "Error!",
        text: "Please fill out all the fields.",
        icon: "error",
        timer: 2000,
        showConfirmButton: false,
      });
      return;
    }

    try {
      await updateBookName(selectedBook.id, selectedBook);
      MySwal.fire({
        title: "Updated!",
        text: "The book name has been updated successfully.",
        icon: "success",
        timer: 1500,
        showConfirmButton: false,
      });
      handleCloseUpdateDialog();
      loadBookNames();
    } catch (error) {
      console.error("There was an error updating the book name!", error);
      MySwal.fire({
        title: "Error!",
        text: "There was an error updating the book name.",
        icon: "error",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  return (
    <div>
      <Grid container spacing={2} sx={{ marginTop: "10px" }}>
        <Typography variant="h6" gutterBottom sx={{ marginTop: 3 }}>
          Total Product: {filteredBookNames.length}
        </Typography>

        <Grid item xs={12} sm={1.6} className="textField-root">
          <TextField
            label="Search Product"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={2.5}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenAddDialog}
          >
            Add Product
          </Button>
        </Grid>
      </Grid>

      <Box mt={2}>
        <TableContainer className="table-root">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>Product Name</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredBookNames.map((book) => (
                <TableRow key={book.id}>
                  <TableCell>{book.id}</TableCell>
                  <TableCell>{book.bookName}</TableCell>
                  <TableCell>{book.price || 0}</TableCell>
                  <TableCell>
                    <Button
                      color="primary"
                      onClick={() => handleOpenUpdateDialog(book)}
                      size="small"
                      variant="contained"
                    >
                      Update
                    </Button>
                    <Button
                      color="error"
                      onClick={() => handleDeleteBookName(book)}
                      size="small"
                      variant="contained"
                      style={{ marginLeft: 8 }}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Add Book Name Dialog */}
      <Dialog open={openAddDialog} onClose={handleCloseAddDialog}>
        <DialogTitle>Add Product Name</DialogTitle>
        <DialogContent className="textField-root">
          <form onSubmit={onAddBookNameSubmit}>
            <TextField
              autoFocus
              margin="dense"
              label="Product Name"
              fullWidth
              variant="outlined"
              value={bookName.bookName}
              onChange={(e) =>
                setBookName({ ...bookName, bookName: e.target.value })
              }
            />
            <TextField
              margin="dense"
              label="Price"
              fullWidth
              variant="outlined"
              value={bookName.price}
              onChange={(e) =>
                setBookName({ ...bookName, price: e.target.value })
              }
            />
            <DialogActions>
              <Button onClick={handleCloseAddDialog} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Add
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>

      {/* Update Book Name Dialog */}
      <Dialog open={openUpdateDialog} onClose={handleCloseUpdateDialog}>
        <DialogTitle>Update Product</DialogTitle>
        <DialogContent className="textField-root">
          <TextField
            autoFocus
            margin="dense"
            label="Product Name"
            fullWidth
            variant="outlined"
            value={selectedBook?.bookName || ""}
            onChange={(e) =>
              setSelectedBook({ ...selectedBook, bookName: e.target.value })
            }
          />
          <TextField
            margin="dense"
            label="Price"
            fullWidth
            variant="outlined"
            value={selectedBook?.price || ""}
            onChange={(e) =>
              setSelectedBook({ ...selectedBook, price: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUpdateDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={onUpdateBookNameSubmit} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Bookname;
