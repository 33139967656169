import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Snackbar,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2"; // Import SweetAlert
import { Delete, Edit } from "@mui/icons-material";

const API_URL = "https://pjsofttech.in:21443"; // Replace with your API URL

const CategoryList = () => {
  const { state } = useLocation();
  const { materialTypeId, materialTypeName } = state || {};
  const [categories, setCategories] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentCategoryId, setCurrentCategoryId] = useState(null);
  const [newCategory, setNewCategory] = useState("");
  const [thumbnailFile, setThumbnailFile] = useState(null);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const institutecode = () => localStorage.getItem("institutecode");


  // Fetch categories for the selected material type
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${API_URL}/bymaterialtype?institutecode=${institutecode()}`, {
          params: { materialTypeName },
        });
        setCategories(response.data);
      } catch (error) {
        showError("Error fetching categories. Please try again.");
      }
    };

    if (materialTypeName) {
      fetchCategories();
    }
  }, [materialTypeName]);

  // Function to show error messages using SweetAlert
  const showError = (message) => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: message,
    });
  };

  // Function to show success messages using SweetAlert
  const showSuccess = (message) => {
    Swal.fire({
      icon: "success",
      title: "Success!",
      text: message,
    });
  };

  // Open dialog for adding or editing category
  const handleDialogOpen = (category = null) => {
    if (category) {
      setEditMode(true);
      setNewCategory(category.name || category.categoryName);
      setCurrentCategoryId(category.id);
    } else {
      setEditMode(false);
      setNewCategory("");
      setCurrentCategoryId(null);
    }
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setNewCategory("");
    setThumbnailFile(null);
  };

  const handleAddOrUpdateCategory = async () => {
    try {
      const createdDate = new Date().toISOString().split("T")[0];
      const formData = new FormData();
      formData.append("name", newCategory);
      formData.append("createdDate", createdDate);
      formData.append("materialtype_id", materialTypeId);
      if (thumbnailFile) {
        formData.append("thumbnail", thumbnailFile);
      }

      if (editMode) {
        await axios.put(`${API_URL}/updateCategory/${currentCategoryId}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setCategories((prevCategories) =>
          prevCategories.map((category) =>
            category.id === currentCategoryId ? { ...category, name: newCategory } : category
          )
        );
        showSuccess("Category updated successfully!");
      } else {
        const response = await axios.post(`${API_URL}/addCategory?institutecode=${institutecode()}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        const newCategoryData = { id: response.data.id, name: newCategory };
        setCategories((prevCategories) => [...prevCategories, newCategoryData]);
        showSuccess("Category added successfully!");
      }

      handleDialogClose();
    } catch (error) {
      showError("Error adding or updating category. Please try again.");
      console.error("Error adding or updating category:", error);
    }
  };

  // Delete category
  const handleDeleteCategory = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "This action cannot be undone!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`${API_URL}/deleteCategory/${id}`);
          setCategories((prevCategories) =>
            prevCategories.filter((category) => category.id !== id)
          );
          showSuccess("Category deleted successfully!");
        } catch (error) {
          showError("Error deleting category. Please try again.");
          console.error("Error deleting category:", error);
        }
      }
    });
  };
  

  // Filter categories based on search input
  const filteredCategories = categories.filter(
    (category) =>
      (category.name || category.categoryName)?.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div>
      <h2 align="center">Categories of Material Type: {materialTypeName}</h2>
    
      <Grid container spacing={2} alignItems="center" className="textField-root">
        <Grid item xs={12} md={2.4}>
          <Typography variant="h6">
            Category Count: {filteredCategories.length}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            label="Search Categories"
            variant="outlined"
            margin="normal"
            fullWidth
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={2.4}>
          <Button variant="contained" color="primary" onClick={() => handleDialogOpen()}>
            Add Category
          </Button>
        </Grid>
        <Grid item xs={12} md={2.4}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => navigate(-1)}
            style={{ marginLeft: "10px" }}
          >
            Back to Material Type
          </Button>
        </Grid>
      </Grid>

      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>{editMode ? "Edit Category" : "Add New Category"}</DialogTitle>
        <DialogContent className="textField-root">
          <TextField
            autoFocus
            margin="dense"
            label="Category Name"
            fullWidth
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
          />
          <input type="file" onChange={(e) => setThumbnailFile(e.target.files[0])} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAddOrUpdateCategory} color="primary">
            {editMode ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>

      <TableContainer className="table-root">
        {filteredCategories.length === 0 ? (
          <p>No categories available.</p>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Category Name</TableCell>
                <TableCell>Thumbnail</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredCategories.map((category) => (
                <TableRow key={category.id}>
                  <TableCell>{category.id}</TableCell>
                  <TableCell>{category.name || category.categoryName}</TableCell>
                  <TableCell>
                    {category.thumbnail && (
                      <img
                        src={category.thumbnail}
                        alt={category.name}
                        style={{ width: 50, height: 50, objectFit: "cover" }}
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        navigate(`/layout/Ebook-manager/SubCategoryList/${category.id}`, {
                          state: {
                            categoryId: category.id,
                            categoryName: category.name || category.categoryName,
                            materialTypeId: materialTypeId,
                          },
                        });
                      }}
                    >
                      Manage Subcategories
                    </Button>
                    <IconButton
                      variant="contained"
                      color="primary"
                      onClick={() => handleDialogOpen(category)}
                      style={{ marginLeft: 8 }}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      variant="contained"
                      color="error"
                      onClick={() => handleDeleteCategory(category.id)}
                      style={{ marginLeft: 8 }}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </div>
  );
};

export default CategoryList;
