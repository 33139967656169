import React, { useState, useEffect } from "react";
import {
  Container,
  Paper,
  Grid,
  TextField,
  Button,
  MenuItem,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import axios from "axios";
import Swal from 'sweetalert2'; // Import SweetAlert2
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";

const AdmissionForm = () => {
  const institutecode = localStorage.getItem("institutecode");
  const [formData, setFormData] = useState({
    name: "",
    mobile2: "",
    gender:"",
    dob:"",
    date: "",
    courses: "",
    duration: "",
    institutecode: institutecode || "",
    transactionid: "",
    currentAddress:"",
    permanentAddress: "",
    totalFees: "",
    remark: "",
    dueDate: "",
    medium: "",
    academicYear:"",
    paymentMode: "",
    paidFees: "",
    guideName: "",
    sourceBy: "",
    mobile1: "",
    // feesReceipt: null,
    studentPhoto: null,
    paymentMethod: "",
    pendingFees: "",
  });
  const [courses, setCourses] = useState([]);
  const [mediums, setMediums] = useState([]);
  const [source, setSources] = useState([]);
  const [guide, setGuide] = useState([]);
  const [touched, setTouched] = useState({});
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isFormSaved, setIsFormSaved] = useState(false);
  const [academicYears, setAcademicYears] = useState([]);
  let navigate = useNavigate();

  useEffect(() => {
    const fetchGuide = async () => {
      try {
        const response = await axios.get(
          `https://pjsofttech.in:13443/api/conductBy/getAllConductBy?institutecode=${institutecode}`
        );
        setGuide(response.data);
      } catch (error) {
        console.error("Error fetching guides:", error);
      }
    };

    if (institutecode) {
      fetchGuide();
    }
  }, [institutecode]);

  useEffect(() => {
    const fetchSources = async () => {
      try {
        const response = await axios.get(
          `https://pjsofttech.in:13443/api/sourceBy/getAll?institutecode=${institutecode}`
        );
        setSources(response.data);
      } catch (error) {
        console.error("Error fetching sources:", error);
      }
    };

    if (institutecode) {
      fetchSources();
    }
  }, [institutecode]);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get(
          `https://pjsofttech.in:13443/getAllCourse?institutecode=${institutecode}`
        );
        setCourses(response.data);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };

    if (institutecode) {
      fetchCourses();
    }
  }, [institutecode]);

  useEffect(() => {
    const fetchMediums = async () => {
      try {
        const response = await axios.get(
          `https://pjsofttech.in:13443/MediumByInstitutecode?institutecode=${institutecode}`
        );
        setMediums(response.data);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };

    if (institutecode) {
      fetchMediums();
    }
  }, [institutecode]);

  useEffect(() => {
    const calculatependingFees = () => {
      const total = parseFloat(formData.totalFees) || 0;
      const paid = parseFloat(formData.paidFees) || 0;
      const pendingFees = total - paid;
      setFormData((prevFormData) => ({
        ...prevFormData,
        pendingFees: pendingFees > 0 ? pendingFees.toString() : "",
      }));
    };

    calculatependingFees();
  }, [formData.totalFees, formData.paidFees]);

  useEffect(() => {
    if (formData.paymentMethod === "Complete") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        paidFees: prevFormData.totalFees,
      }));
    } else if (formData.paymentMethod === "Partial") {
      // Handle Partial Payment if needed
    }
  }, [formData.paymentMethod]);

   useEffect(() => {
      const generateAcademicYears = () => {
        const currentYear = new Date().getFullYear();
        const range = 10; // Number of years forward and backward
        const years = [];
  
        for (let i = currentYear - range; i <= currentYear + range; i++) {
          years.push(`${i}-${i + 1}`);
        }
  
        setAcademicYears(years);
      };
  
      generateAcademicYears();
    }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
  
    // Check if the paidFees exceeds totalFees
    if (name === "paidFees") {
      const paid = parseFloat(value) || 0;
      const total = parseFloat(formData.totalFees) || 0;
  
      if (paid > total) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid Input',
          text: 'Paid fees cannot exceed the total fees.',
        });
        return; // Exit without updating state
      }
    }
  
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (event, fieldName) => {
    setFormData({ ...formData, [fieldName]: event.target.files[0] });
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      const { feesReceipt, studentPhoto, ...dataToSubmit } = formData; // exclude files from the initial submission
      await axios.post(
        `https://pjsofttech.in:13443/saveAdmission?institutecode=${institutecode}`,
        dataToSubmit
      );
       // Show success message using SweetAlert2
       await Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: 'Form Submitted successfully!',
        confirmButtonText: 'OK',
      });
      setIsFormSubmitted(true); // mark form as submitted
      setIsFormSaved(true); // mark form as saved
    } catch (error) {
      console.error("Error submitting form:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Failed to Submitting Form. Please try again.',
        confirmButtonText: 'OK',
      });
    }
  };

  const handleFinalSubmit = () => {
    navigate("/layout/Admission-manager");
  };

  const handleFileUpload = async (fieldName, endpoint) => {
    const file = formData[fieldName];

    if (!file) {
      Swal.fire("No file selected");
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append(fieldName, file);

    try {
      await axios.post(endpoint, formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      Swal.fire(`${fieldName} uploaded successfully`);
    } catch (error) {
      console.error(`Error uploading ${fieldName}:`, error);
      Swal.fire(`Only JPG and JPEG files are allowed for ${fieldName}`);
    }
  };

  const calculateBalance = () => {
    if (formData.paymentMethod === "Pending") {
      // If the payment method is "Pending", set paidFees to 0
      return formData.totalFees; // Balance will be the total fees since nothing is paid
    }

    if (formData.paymentMethod === "Partial") {
      // For partial payments, subtract paid fees from total fees
      return formData.totalFees - formData.paidFees;
    }

    return 0; // No balance for other payment methods
  };

  const handleBlur = (field) => (event) => {
    setTouched({ ...touched, [field]: true });
  };

  const validateMobileNumber = (value) => {
    return /^\d{10}$/.test(value);
  };

  const validateEmail = (value) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
  };

  return (
    <>
         <div maxWidth="lg" className="required-asterisk">
        <form onSubmit={handleFormSubmit}>
          <Grid container spacing={3} className="textField-root">
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                id="name"
                name="name"
                label="Full Name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                id="email"
                name="email"
                label="Email"
                value={formData.email}
                onChange={handleInputChange}
                onBlur={handleBlur("email")}
                required
                error={touched.email && !validateEmail(formData.email)}
                helperText={
                  touched.email && !validateEmail(formData.email)
                    ? "Enter a valid email address"
                    : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                id="gender"
                name="gender"
                label="Gender"
                value={formData.gender}
                onChange={handleInputChange}
                select
                required
              >
                 <MenuItem value="All">All</MenuItem>
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
                </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                id="dob"
                name="dob"
                label="Date of Birth"
                value={formData.dob}
                onChange={handleInputChange}
                type="date"
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                id="mobile1"
                name="mobile1"
                label="Mobile"
                value={formData.mobile1}
                onChange={handleInputChange}
                onBlur={handleBlur("mobile1")}
                required
                error={
                  touched.mobile1 &&
                  (!validateMobileNumber(formData.mobile1) ||
                    formData.mobile1.length !== 10)
                }
                helperText={
                  touched.mobile1 && !validateMobileNumber(formData.mobile1)
                    ? "Mobile number must be 10 digits"
                    : ""
                }
                inputProps={{
                  maxLength: 10, // Limit input to 10 digits
                  pattern: "[0-9]*", // Optional: Restrict input to numbers only
                  inputMode: "numeric", // Optional: Show numeric keyboard on mobile devices
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                id="mobile2"
                name="mobile2"
                label="Parent Mobile"
                value={formData.mobile2}
                onChange={handleInputChange}
                inputProps={{
                  maxLength: 10, // Limit input to 10 digits
                  pattern: "[0-9]*", // Optional: Restrict input to numbers only
                  inputMode: "numeric", // Optional: Show numeric keyboard on mobile devices
                }}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                id="currentAddress"
                name="currentAddress"
                label="Current Address"
                value={formData.currentAddress}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                id="permanentAddress"
                name="permanentAddress"
                label="Permanent Address"
                value={formData.permanentAddress}
                onChange={handleInputChange}
                required
              />
            </Grid> */}
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                id="courses"
                name="courses"
                label="Courses"
                value={formData.courses}
                onChange={handleInputChange}
                select
                required
              >
                {courses.map((course) => (
                  <MenuItem key={course.id} value={course.cname}>
                    {course.cname}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                id="medium"
                name="medium"
                label="Medium"
                value={formData.medium}
                onChange={handleInputChange}
                select
             >
               {mediums.map((medium) => (
                  <MenuItem key={medium.mid} value={medium.mediumName}>
                    {medium.mediumName}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
               <TextField
                          select
                          fullWidth
                          label="Academic Year"
                          name="academicYear"
                          value={formData.academicYear}
                          onChange={handleInputChange}
                        >
                          {academicYears.map((year) => (
                            <MenuItem key={year} value={year}>
                              {year}
                            </MenuItem>
                          ))}
                        </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                id="duration"
                name="duration"
                label="Duration"
                value={formData.duration}
                onChange={handleInputChange}
                select
                required
              >
                <MenuItem value="1 Months">1 Months</MenuItem>
                <MenuItem value="2 Months">2 Months</MenuItem>
                <MenuItem value="3 Months">3 Months</MenuItem>
                <MenuItem value="4 Months">4 Months</MenuItem>
                <MenuItem value="6 Months">6 Months</MenuItem>
                <MenuItem value="12 Months">12 Months</MenuItem>
                <MenuItem value="24 Months">24 Months</MenuItem>
                <MenuItem value="36 Months">36 Months</MenuItem>
                <MenuItem value="48 Months">48 Months</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                id="totalFees"
                name="totalFees"
                label="Total Fees"
                value={formData.totalFees}
                onChange={handleInputChange}
              />
            </Grid>{" "}
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                id="paymentMethod"
                name="paymentMethod"
                label="Payment Method"
                value={formData.paymentMethod}
                onChange={handleInputChange}
                select
                required
              >
                <MenuItem value="Pending">Pending</MenuItem>
                <MenuItem value="Partial">Partial</MenuItem>
                <MenuItem value="Complete">Complete</MenuItem>
                <MenuItem value="Refund">Refund</MenuItem>
                <MenuItem value="Cancel">Cancel</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                id="paidFees"
                name="paidFees"
                label="Fees Paying"
                value={formData.paidFees }
                onChange={handleInputChange}
                required
                disabled={formData.paymentMethod === "Pending"} // Disable input if paymentMethod is "Pending"
              />
            </Grid>
            {formData.paymentMethod === "Pending" ||
            formData.paymentMethod === "Partial" ? (
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth
                  id="pendingFees"
                  name="pendingFees"
                  label="Pending Fees"
                  value={calculateBalance() || 0}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
            ) : null}
            {formData.paymentMethod === "Partial" ||
            formData.paymentMethod === "Complete" ? (
              <>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    fullWidth
                    id="paymentMode"
                    name="paymentMode"
                    label="Payment Mode"
                    value={formData.paymentMode}
                    onChange={handleInputChange}
                    disabled={formData.paymentMethod === "Pending"}
                    select
                    required
                  >
                    <MenuItem value="Cheque">Cheque</MenuItem>
                    <MenuItem value="UPI">UPI</MenuItem>
                    <MenuItem value="Cash">Cash</MenuItem>
                    <MenuItem value="Bank Transfer">Bank Transfer</MenuItem>
                  </TextField>
                </Grid>
                {formData.paymentMode !== "Cash" ? (
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      fullWidth
                      id="transactionid"
                      name="transactionid"
                      label="Transaction ID"
                      value={
                        formData.paymentMethod === "Pending"
                          ? "Pending"
                          : formData.transactionid
                      }
                      onChange={handleInputChange}
                      disabled={formData.paymentMethod === "Pending"}
                    />
                  </Grid>
                ) : null}
              </>
            ) : null}
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                id="sourceBy"
                name="sourceBy"
                label="Source By"
                value={formData.sourceBy}
                onChange={handleInputChange}
                select
                required
              >
                {source.map((src) => (
                  <MenuItem key={src.id} value={src.sourceBy}>
                    {src.sourceBy}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                id="guideName"
                name="guideName"
                label="Conduct By"
                value={formData.guideName}
                onChange={handleInputChange}
                select
                required
              >
                {guide.map((g) => (
                  <MenuItem key={g.id} value={g.guideName}>
                    {g.guideName}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                id="date"
                name="date"
                label="Date"
                value={formData.date}
                onChange={handleInputChange}
                type="date"
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            {formData.paymentMethod === "Pending" ||
            formData.paymentMethod === "Partial" ? (
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth
                  id="dueDate"
                  name="dueDate"
                  label="Due Date"
                  value={formData.dueDate}
                  onChange={handleInputChange}
                  type="date"
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            ) : null}
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                id="remark"
                name="remark"
                label="Remark"
                value={formData.remark}
                onChange={handleInputChange}
                rows={4}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={12} xl={12}>
  <TextField
    fullWidth
    multiline
    rows={2}
    id="currentAddress"
    name="currentAddress"
    label="Current Address"
    value={formData.currentAddress}
    onChange={handleInputChange}
  />
</Grid>

<Grid item xs={12} sm={12} md={4} lg={4} xl={4} display="flex" alignItems="center">
  <FormControlLabel
    control={
      <Checkbox
        checked={formData.sameAsCurrent}
        onChange={(e) => {
          if (e.target.checked) {
            setFormData({
              ...formData,
              permanentAddress: formData.currentAddress,
              sameAsCurrent: true,
            });
          } else {
            setFormData({ ...formData, sameAsCurrent: false });
          }
        }}
        name="sameAsCurrent"
      />
    }
    label="Same as Current Address"
  />
</Grid>
<Grid item xs={12} sm={6} md={4} lg={12} xl={12}>
  <TextField
    fullWidth
    multiline
    rows={2}
    id="permanentAddress"
    name="permanentAddress"
    label="Permanent Address"
    value={formData.permanentAddress}
    onChange={handleInputChange}
  />
</Grid>
            <Grid
              container
              spacing={3}
              style={{ marginTop: "10px", justifyContent: "center" }}
            >
              <Grid item>
                <Button type="submit" variant="contained" color="primary">
                  Save Data
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              justifyContent="space-evenly"
              display="flex"
              alignItems="center" // Align items vertically in the center
            >
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                sx={{ display: "flex", alignItems: "center" }}
              >
                {" "}
                {/* Add flexbox styling here */}
                <Button
                  variant="contained"
                  component="label"
                  style={{ marginRight: "10px", marginTop: "20px" }}
                  //color={errors.studentPhoto ? "error" : "primary"} // Change button color if there's an error
                >
                  Upload Student Photo
                  <Typography
                    variant="body1"
                    color="error"
                    sx={{ display: "inline-block", marginLeft: 1 }}
                  >
                    * {/* Asterisk to indicate required field */}
                  </Typography>
                  <input
                    type="file"
                    hidden
                    onChange={(event) =>
                      handleFileChange(event, "studentPhoto")
                    }
                  />
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginTop: "20px" }}
                  onClick={() =>
                    handleFileUpload(
                      "studentPhoto",
                      `https://pjsofttech.in:13443/saveStudentPhoto/${formData.email}`
                    )
                  }
                  disabled={!isFormSubmitted}
                >
                  Upload
                </Button>
              </Grid>
            </Grid>
            {isFormSaved && (
              <Grid
                container
                justifyContent="center"
                spacing={3}
                style={{ marginTop: "10px" }}
              >
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={handleFinalSubmit}
                    color="primary"
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </form>
      </div>
    </>
  );
};

export default AdmissionForm;


// import React, { useState, useEffect } from "react";
// import {
//   Container,
//   Paper,
//   Grid,
//   TextField,
//   Button,
//   MenuItem,
//   Typography,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   FormControlLabel,
//   Checkbox,
// } from "@mui/material";
// import axios from "axios";
// import Swal from 'sweetalert2'; // Import SweetAlert2
// import { styled } from "@mui/system";
// import { useNavigate } from "react-router-dom";
// import jsPDF from 'jspdf';
// import 'jspdf-autotable';

// const AdmissionForm = () => {
//   const institutecode = localStorage.getItem("institutecode");
//   const [formData, setFormData] = useState({
//     name: "",
//     mobile2: "",
//     date: "",
//     courses: "",
//     duration: "",
//     institutecode: institutecode || "",
//     transactionid: "",
//     currentAddress:"",
//     permanentAddress: "",
//     totalFees: "",
//     remark: "",
//     dueDate: "",
//     medium: "",
//     academicYear:"",
//     paymentMode: "",
//     paidFees: "",
//     guideName: "",
//     sourceBy: "",
//     mobile1: "",
//     // feesReceipt: null,
//     studentPhoto: null,
//     paymentMethod: "",
//     pendingFees: "",
//   });
//   const [courses, setCourses] = useState([]);
//   const [mediums, setMediums] = useState([]);
//   const [source, setSources] = useState([]);
//   const [guide, setGuide] = useState([]);
//   const [touched, setTouched] = useState({});
//   const [isFormSubmitted, setIsFormSubmitted] = useState(false);
//   const [isFormSaved, setIsFormSaved] = useState(false);
//   const [agreeToRules, setAgreeToRules] = useState(false);
//   const [rulesAndRegulations, setRulesAndRegulations] = useState("");
//   const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
//   const [academicYears, setAcademicYears] = useState([]);
//   let navigate = useNavigate();

//   useEffect(() => {
//     if (agreeToRules) {
//       axios.get(`https://pjsofttech.in:13443/getAllRulesAndRegulations?institutecode=${institutecode()}`)
//         .then(response => {
//           if (response.data && response.data.rulesAndRegulations) {
//             setRulesAndRegulations(response.data.rulesAndRegulations);
//           } else {
//             setRulesAndRegulations("No rules and regulations available.");
//           }
//         })
//         .catch(error => {
//           console.error("Error fetching rules:", error);
//           setRulesAndRegulations("Failed to load rules and regulations.");
//         });
//     }
//   }, [agreeToRules]);
  

//   useEffect(() => {
//     const fetchGuide = async () => {
//       try {
//         const response = await axios.get(
//           `https://pjsofttech.in:13443/api/conductBy/getAllConductBy?institutecode=${institutecode}`
//         );
//         setGuide(response.data);
//       } catch (error) {
//         console.error("Error fetching guides:", error);
//       }
//     };

//     if (institutecode) {
//       fetchGuide();
//     }
//   }, [institutecode]);

//   useEffect(() => {
//     const fetchSources = async () => {
//       try {
//         const response = await axios.get(
//           `https://pjsofttech.in:13443/api/sourceBy/getAll?institutecode=${institutecode}`
//         );
//         setSources(response.data);
//       } catch (error) {
//         console.error("Error fetching sources:", error);
//       }
//     };

//     if (institutecode) {
//       fetchSources();
//     }
//   }, [institutecode]);

//   useEffect(() => {
//     const fetchCourses = async () => {
//       try {
//         const response = await axios.get(
//           `https://pjsofttech.in:13443/getAllCourse?institutecode=${institutecode}`
//         );
//         setCourses(response.data);
//       } catch (error) {
//         console.error("Error fetching courses:", error);
//       }
//     };

//     if (institutecode) {
//       fetchCourses();
//     }
//   }, [institutecode]);

//   useEffect(() => {
//     const fetchMediums = async () => {
//       try {
//         const response = await axios.get(
//           `https://pjsofttech.in:13443/MediumByInstitutecode?institutecode=${institutecode}`
//         );
//         setMediums(response.data);
//       } catch (error) {
//         console.error("Error fetching courses:", error);
//       }
//     };

//     if (institutecode) {
//       fetchMediums();
//     }
//   }, [institutecode]);

//   useEffect(() => {
//     const calculatependingFees = () => {
//       const total = parseFloat(formData.totalFees) || 0;
//       const paid = parseFloat(formData.paidFees) || 0;
//       const pendingFees = total - paid;
//       setFormData((prevFormData) => ({
//         ...prevFormData,
//         pendingFees: pendingFees > 0 ? pendingFees.toString() : "",
//       }));
//     };

//     calculatependingFees();
//   }, [formData.totalFees, formData.paidFees]);

//   useEffect(() => {
//     if (formData.paymentMethod === "Complete") {
//       setFormData((prevFormData) => ({
//         ...prevFormData,
//         paidFees: prevFormData.totalFees,
//       }));
//     } else if (formData.paymentMethod === "Partial") {
//       // Handle Partial Payment if needed
//     }
//   }, [formData.paymentMethod]);

//    useEffect(() => {
//       const generateAcademicYears = () => {
//         const currentYear = new Date().getFullYear();
//         const range = 10; // Number of years forward and backward
//         const years = [];
  
//         for (let i = currentYear - range; i <= currentYear + range; i++) {
//           years.push(`${i}-${i + 1}`);
//         }
  
//         setAcademicYears(years);
//       };
  
//       generateAcademicYears();
//     }, []);

//   const handleInputChange = (event) => {
//     const { name, value } = event.target;
  
//     // Check if the paidFees exceeds totalFees
//     if (name === "paidFees") {
//       const paid = parseFloat(value) || 0;
//       const total = parseFloat(formData.totalFees) || 0;
  
//       if (paid > total) {
//         Swal.fire({
//           icon: 'error',
//           title: 'Invalid Input',
//           text: 'Paid fees cannot exceed the total fees.',
//         });
//         return; // Exit without updating state
//       }
//     }
  
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleFileChange = (event, fieldName) => {
//     setFormData({ ...formData, [fieldName]: event.target.files[0] });
//   };

//   const handleFormSubmit = async (event) => {
//     event.preventDefault();
//     try {
//       const { feesReceipt, studentPhoto, ...dataToSubmit } = formData; // exclude files from the initial submission
//       await axios.post(
//         `https://pjsofttech.in:13443/saveAdmission?institutecode=${institutecode}`,
//         dataToSubmit
//       );
//        // Show success message using SweetAlert2
//        await Swal.fire({
//         icon: 'success',
//         title: 'Success!',
//         text: 'Form Submitted successfully!',
//         confirmButtonText: 'OK',
//       });
//       setIsFormSubmitted(true); // mark form as submitted
//       setIsFormSaved(true); // mark form as saved
//     } catch (error) {
//       console.error("Error submitting form:", error);
//       Swal.fire({
//         icon: 'error',
//         title: 'Error!',
//         text: 'Failed to Submitting Form. Please try again.',
//         confirmButtonText: 'OK',
//       });
//     }
//   };

//   const generatePDF = () => {
//     const doc = new jsPDF();
//     doc.setFontSize(18);
//     doc.text("Admission Form Preview", 70, 20);
//     if (formData.studentPhoto) {
//       doc.addImage(URL.createObjectURL(formData.studentPhoto), "JPEG", 75, 30, 50, 50);
//     }
//     doc.setFontSize(14);
//     doc.text(`Name: ${formData.name}`, 20, 90);
//     doc.text(`Email: ${formData.email}`, 20, 100);
//     doc.text(`Mobile: ${formData.mobile1}`, 20, 110);
//     doc.text(`Parent Mobile: ${formData.mobile2}`, 20, 120);
//     doc.text(`Current Address: ${formData.currentAddress}`, 20, 130);
//     doc.text(`Permanent Address: ${formData.permanentAddress}`, 20, 140);
//     doc.text(`Courses: ${formData.courses}`, 20, 150);
//     doc.text(`Medium: ${formData.medium}`, 20, 160);
//     doc.text(`Academic Year: ${formData.academicYear}`, 20, 170);
//     doc.text(`Duration: ${formData.duration}`, 20, 180);
//     doc.text(`Source By: ${formData.sourceBy}`, 20, 190);
//     doc.text(`Conduct By: ${formData.guideName}`, 20, 200);
//     doc.text(`Payment Method: ${formData.paymentMethod}`, 20, 210);
//     doc.text(`Payment Mode: ${formData.paymentMode}`, 20, 220);
//     doc.text(`Transaction ID: ${formData.transactionid}`, 20, 230);
//     doc.text(`Total Fees: ${formData.totalFees}`, 20, 240);
//     doc.text(`Paid Fees: ${formData.paidFees}`, 20, 250);
//     doc.text(`Pending Fees: ${formData.pendingFees}`, 20, 260);
//     doc.text(`Due Date: ${formData.dueDate}`, 20, 270);
//     doc.text(`Date: ${formData.date}`, 20, 280);
//     doc.text(`Remark: ${formData.remark}`, 20, 290);
   


//     doc.setFontSize(16);
//     doc.text("Rules and Regulations", 70, 300);
//     doc.setFontSize(12);
//     doc.text(rulesAndRegulations, 20, 310);

//     doc.save("Admission_Form_Preview.pdf");
//   };


//   const handleFinalSubmit = () => {
//     setOpenPreviewDialog(true);
//   };

//   const handleFileUpload = async (fieldName, endpoint) => {
//     const file = formData[fieldName];

//     if (!file) {
//       Swal.fire("No file selected");
//       return;
//     }

//     const formDataToSend = new FormData();
//     formDataToSend.append(fieldName, file);

//     try {
//       await axios.post(endpoint, formDataToSend, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });
//       Swal.fire(`${fieldName} uploaded successfully`);
//     } catch (error) {
//       console.error(`Error uploading ${fieldName}:`, error);
//       Swal.fire(`Only JPG and JPEG files are allowed for ${fieldName}`);
//     }
//   };

//   const calculateBalance = () => {
//     if (formData.paymentMethod === "Pending") {
//       // If the payment method is "Pending", set paidFees to 0
//       return formData.totalFees; // Balance will be the total fees since nothing is paid
//     }

//     if (formData.paymentMethod === "Partial") {
//       // For partial payments, subtract paid fees from total fees
//       return formData.totalFees - formData.paidFees;
//     }

//     return 0; // No balance for other payment methods
//   };

//   const handleBlur = (field) => (event) => {
//     setTouched({ ...touched, [field]: true });
//   };

//   const validateMobileNumber = (value) => {
//     return /^\d{10}$/.test(value);
//   };

//   const validateEmail = (value) => {
//     return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
//   };

//   return (
//     <>
//          <div maxWidth="lg" className="required-asterisk">
//         <form onSubmit={handleFormSubmit}>
//           <Grid container spacing={3} className="textField-root">
//             <Grid item xs={12} sm={6} md={4}>
//               <TextField
//                 fullWidth
//                 id="name"
//                 name="name"
//                 label="Full Name"
//                 value={formData.name}
//                 onChange={handleInputChange}
//                 required
//               />
//             </Grid>
//             <Grid item xs={12} sm={6} md={4}>
//               <TextField
//                 fullWidth
//                 id="email"
//                 name="email"
//                 label="Email"
//                 value={formData.email}
//                 onChange={handleInputChange}
//                 onBlur={handleBlur("email")}
//                 required
//                 error={touched.email && !validateEmail(formData.email)}
//                 helperText={
//                   touched.email && !validateEmail(formData.email)
//                     ? "Enter a valid email address"
//                     : ""
//                 }
//               />
//             </Grid>
//             <Grid item xs={12} sm={6} md={4}>
//               <TextField
//                 fullWidth
//                 id="mobile1"
//                 name="mobile1"
//                 label="Mobile"
//                 value={formData.mobile1}
//                 onChange={handleInputChange}
//                 onBlur={handleBlur("mobile1")}
//                 required
//                 error={
//                   touched.mobile1 &&
//                   (!validateMobileNumber(formData.mobile1) ||
//                     formData.mobile1.length !== 10)
//                 }
//                 helperText={
//                   touched.mobile1 && !validateMobileNumber(formData.mobile1)
//                     ? "Mobile number must be 10 digits"
//                     : ""
//                 }
//                 inputProps={{
//                   maxLength: 10, // Limit input to 10 digits
//                   pattern: "[0-9]*", // Optional: Restrict input to numbers only
//                   inputMode: "numeric", // Optional: Show numeric keyboard on mobile devices
//                 }}
//               />
//             </Grid>
//             <Grid item xs={12} sm={6} md={4}>
//               <TextField
//                 fullWidth
//                 id="mobile2"
//                 name="mobile2"
//                 label="Parent Mobile"
//                 value={formData.mobile2}
//                 onChange={handleInputChange}
//                 inputProps={{
//                   maxLength: 10, // Limit input to 10 digits
//                   pattern: "[0-9]*", // Optional: Restrict input to numbers only
//                   inputMode: "numeric", // Optional: Show numeric keyboard on mobile devices
//                 }}
//               />
//             </Grid>
//             <Grid item xs={12} sm={6} md={4}>
//               <TextField
//                 fullWidth
//                 id="currentAddress"
//                 name="currentAddress"
//                 label="Current Address"
//                 value={formData.currentAddress}
//                 onChange={handleInputChange}
//               />
//             </Grid>
//             <Grid item xs={12} sm={6} md={4}>
//               <TextField
//                 fullWidth
//                 id="permanentAddress"
//                 name="permanentAddress"
//                 label="Permanent Address"
//                 value={formData.permanentAddress}
//                 onChange={handleInputChange}
//               />
//             </Grid>
//             <Grid item xs={12} sm={6} md={4}>
//               <TextField
//                 fullWidth
//                 id="courses"
//                 name="courses"
//                 label="Courses"
//                 value={formData.courses}
//                 onChange={handleInputChange}
//                 select
//                 required
//               >
//                 {courses.map((course) => (
//                   <MenuItem key={course.id} value={course.cname}>
//                     {course.cname}
//                   </MenuItem>
//                 ))}
//               </TextField>
//             </Grid>
//             <Grid item xs={12} sm={6} md={4}>
//               <TextField
//                 fullWidth
//                 id="medium"
//                 name="medium"
//                 label="Medium"
//                 value={formData.medium}
//                 onChange={handleInputChange}
//                 select
//              >
//                {mediums.map((medium) => (
//                   <MenuItem key={medium.mid} value={medium.mediumName}>
//                     {medium.mediumName}
//                   </MenuItem>
//                 ))}
//               </TextField>
//             </Grid>
//             <Grid item xs={12} sm={6} md={4}>
//                <TextField
//                           select
//                           fullWidth
//                           label="Academic Year"
//                           name="academicYear"
//                           value={formData.academicYear}
//                           onChange={handleInputChange}
//                         >
//                           {academicYears.map((year) => (
//                             <MenuItem key={year} value={year}>
//                               {year}
//                             </MenuItem>
//                           ))}
//                         </TextField>
//             </Grid>
//             <Grid item xs={12} sm={6} md={4}>
//               <TextField
//                 fullWidth
//                 id="duration"
//                 name="duration"
//                 label="Duration"
//                 value={formData.duration}
//                 onChange={handleInputChange}
//                 select
//                 required
//               >
//                 <MenuItem value="1 Months">1 Months</MenuItem>
//                 <MenuItem value="2 Months">2 Months</MenuItem>
//                 <MenuItem value="3 Months">3 Months</MenuItem>
//                 <MenuItem value="4 Months">4 Months</MenuItem>
//                 <MenuItem value="6 Months">6 Months</MenuItem>
//                 <MenuItem value="12 Months">12 Months</MenuItem>
//                 <MenuItem value="24 Months">24 Months</MenuItem>
//                 <MenuItem value="36 Months">36 Months</MenuItem>
//                 <MenuItem value="48 Months">48 Months</MenuItem>
//               </TextField>
//             </Grid>
//             <Grid item xs={12} sm={6} md={4}>
//               <TextField
//                 fullWidth
//                 id="totalFees"
//                 name="totalFees"
//                 label="Total Fees"
//                 value={formData.totalFees}
//                 onChange={handleInputChange}
//               />
//             </Grid>{" "}
//             <Grid item xs={12} sm={4}>
//               <TextField
//                 fullWidth
//                 id="paymentMethod"
//                 name="paymentMethod"
//                 label="Payment Method"
//                 value={formData.paymentMethod}
//                 onChange={handleInputChange}
//                 select
//                 required
//               >
//                 <MenuItem value="Pending">Pending</MenuItem>
//                 <MenuItem value="Partial">Partial</MenuItem>
//                 <MenuItem value="Complete">Complete</MenuItem>
//                 <MenuItem value="Refund">Refund</MenuItem>
//                 <MenuItem value="Cancel">Cancel</MenuItem>
//               </TextField>
//             </Grid>
//             <Grid item xs={12} sm={6} md={4}>
//               <TextField
//                 fullWidth
//                 id="paidFees"
//                 name="paidFees"
//                 label="Fees Paying"
//                 value={formData.paidFees }
//                 onChange={handleInputChange}
//                 required
//                 disabled={formData.paymentMethod === "Pending"} // Disable input if paymentMethod is "Pending"
//               />
//             </Grid>
//             {formData.paymentMethod === "Pending" ||
//             formData.paymentMethod === "Partial" ? (
//               <Grid item xs={12} sm={6} md={4}>
//                 <TextField
//                   fullWidth
//                   id="pendingFees"
//                   name="pendingFees"
//                   label="Pending Fees"
//                   value={calculateBalance() || 0}
//                   InputProps={{ readOnly: true }}
//                 />
//               </Grid>
//             ) : null}
//             {formData.paymentMethod === "Partial" ||
//             formData.paymentMethod === "Complete" ? (
//               <>
//                 <Grid item xs={12} sm={6} md={4}>
//                   <TextField
//                     fullWidth
//                     id="paymentMode"
//                     name="paymentMode"
//                     label="Payment Mode"
//                     value={formData.paymentMode}
//                     onChange={handleInputChange}
//                     disabled={formData.paymentMethod === "Pending"}
//                     select
//                     required
//                   >
//                     <MenuItem value="Cheque">Cheque</MenuItem>
//                     <MenuItem value="UPI">UPI</MenuItem>
//                     <MenuItem value="Cash">Cash</MenuItem>
//                     <MenuItem value="Bank Transfer">Bank Transfer</MenuItem>
//                   </TextField>
//                 </Grid>
//                 {formData.paymentMode !== "Cash" ? (
//                   <Grid item xs={12} sm={6} md={4}>
//                     <TextField
//                       fullWidth
//                       id="transactionid"
//                       name="transactionid"
//                       label="Transaction ID"
//                       value={
//                         formData.paymentMethod === "Pending"
//                           ? "Pending"
//                           : formData.transactionid
//                       }
//                       onChange={handleInputChange}
//                       disabled={formData.paymentMethod === "Pending"}
//                     />
//                   </Grid>
//                 ) : null}
//               </>
//             ) : null}
//             <Grid item xs={12} sm={6} md={4}>
//               <TextField
//                 fullWidth
//                 id="sourceBy"
//                 name="sourceBy"
//                 label="Source By"
//                 value={formData.sourceBy}
//                 onChange={handleInputChange}
//                 select
//                 required
//               >
//                 {source.map((src) => (
//                   <MenuItem key={src.id} value={src.sourceBy}>
//                     {src.sourceBy}
//                   </MenuItem>
//                 ))}
//               </TextField>
//             </Grid>
//             <Grid item xs={12} sm={4}>
//               <TextField
//                 fullWidth
//                 id="guideName"
//                 name="guideName"
//                 label="Conduct By"
//                 value={formData.guideName}
//                 onChange={handleInputChange}
//                 select
//                 required
//               >
//                 {guide.map((g) => (
//                   <MenuItem key={g.id} value={g.guideName}>
//                     {g.guideName}
//                   </MenuItem>
//                 ))}
//               </TextField>
//             </Grid>
//             <Grid item xs={12} sm={6} md={4}>
//               <TextField
//                 fullWidth
//                 id="date"
//                 name="date"
//                 label="Date"
//                 value={formData.date}
//                 onChange={handleInputChange}
//                 type="date"
//                 required
//                 InputLabelProps={{
//                   shrink: true,
//                 }}
//               />
//             </Grid>
//             {formData.paymentMethod === "Pending" ||
//             formData.paymentMethod === "Partial" ? (
//               <Grid item xs={12} sm={6} md={4}>
//                 <TextField
//                   fullWidth
//                   id="dueDate"
//                   name="dueDate"
//                   label="Due Date"
//                   value={formData.dueDate}
//                   onChange={handleInputChange}
//                   type="date"
//                   required
//                   InputLabelProps={{
//                     shrink: true,
//                   }}
//                 />
//               </Grid>
//             ) : null}
//             <Grid item xs={12} sm={6} md={4}>
//               <TextField
//                 fullWidth
//                 id="remark"
//                 name="remark"
//                 label="Remark"
//                 value={formData.remark}
//                 onChange={handleInputChange}
//                 rows={4}
//               />
//             </Grid>
//             <Grid item xs={12}>
//             <FormControlLabel
//               control={
//                 <Checkbox
//                   checked={agreeToRules}
//                   onChange={() => setAgreeToRules(!agreeToRules)}
//                 />
//               }
//               label="I agree to the rules and regulations of the institute"
//             />
//           </Grid>
//             <Grid
//               container
//               spacing={3}
//               style={{ marginTop: "10px", justifyContent: "center" }}
//             >
//               <Grid item>
//                 <Button type="submit" variant="contained" color="primary">
//                   Save Data
//                 </Button>
//               </Grid>
//             </Grid>
//             <Grid
//               container
//               spacing={1}
//               justifyContent="space-evenly"
//               display="flex"
//               alignItems="center" // Align items vertically in the center
//             >
//               <Grid
//                 item
//                 xs={12}
//                 sm={6}
//                 md={4}
//                 sx={{ display: "flex", alignItems: "center" }}
//               >
//                 {" "}
//                 {/* Add flexbox styling here */}
//                 <Button
//                   variant="contained"
//                   component="label"
//                   style={{ marginRight: "10px", marginTop: "20px" }}
//                   //color={errors.studentPhoto ? "error" : "primary"} // Change button color if there's an error
//                 >
//                   Upload Student Photo
//                   <Typography
//                     variant="body1"
//                     color="error"
//                     sx={{ display: "inline-block", marginLeft: 1 }}
//                   >
//                     * {/* Asterisk to indicate required field */}
//                   </Typography>
//                   <input
//                     type="file"
//                     hidden
//                     onChange={(event) =>
//                       handleFileChange(event, "studentPhoto")
//                     }
//                   />
//                 </Button>
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   style={{ marginTop: "20px" }}
//                   onClick={() =>
//                     handleFileUpload(
//                       "studentPhoto",
//                       `https://pjsofttech.in:13443/saveStudentPhoto/${formData.email}`
//                     )
//                   }
//                   disabled={!isFormSubmitted}
//                 >
//                   Upload
//                 </Button>
//               </Grid>
//             </Grid>
//             {isFormSaved && (
//               <Grid
//                 container
//                 justifyContent="center"
//                 spacing={3}
//                 style={{ marginTop: "10px" }}
//               >
//                 <Grid item>
//                   <Button
//                     variant="contained"
//                     onClick={handleFinalSubmit}
//                     color="primary"
//                   >
//                     Submit
//                   </Button>
//                 </Grid>
//               </Grid>
//             )}
//           </Grid>
//         </form>

//         <Dialog open={openPreviewDialog} onClose={() => setOpenPreviewDialog(false)} fullWidth maxWidth="md">
//         <DialogTitle align="center">Admission Form Preview</DialogTitle>
//         <DialogContent>
//           {formData.studentPhoto && (
//             <div style={{ textAlign: "center" }}>
//               <img
//                 src={URL.createObjectURL(formData.studentPhoto)}
//                 alt="Student"
//                 style={{ width: 100, height: 100, borderRadius: "50%" }}
//               />
//             </div>
//           )}
//           <Typography align="center" variant="h6">{formData.name || "-"}</Typography>
//           <Typography>Email: {formData.email || "-"}</Typography>
//           <Typography>Mobile: {formData.mobile1 || "-"}</Typography>
//           <Typography>Parent Mobile: {formData.mobile2 || "-"}</Typography>
//           <Typography>Current Address: {formData.currentAddress || "-"}</Typography>
//           <Typography>Permanent Address: {formData.permanentAddress || "-"}</Typography>
//           <Typography>Courses: {formData.courses || "-"}</Typography>
//           <Typography>Medium: {formData.medium || "-"}</Typography>
//           <Typography>Academic Year: {formData.academicYear || "-"}</Typography>
//           <Typography>Duration: {formData.duration || "-"}</Typography>
//           <Typography>Source By: {formData.sourceBy || "-"}</Typography>
//           <Typography>Conduct By: {formData.guideName || "-"}</Typography>
//           <Typography>Payment Method: {formData.paymentMethod || "-"}</Typography>
//           <Typography>Payment Mode: {formData.paymentMode || "-"}</Typography>
//           <Typography>Total Fees: {formData.totalFees || "-"}</Typography>
//           <Typography>Paid Fees: {formData.paidFees || "-"}</Typography>
//           <Typography>Pending Fees: {formData.pendingFees || "-"}</Typography>
//           <Typography>Transaction ID: {formData.transactionid || "-"}</Typography>
//           <Typography>Date: {formData.date || "-"}</Typography>
//           <Typography>Due Date: {formData.dueDate || "-"}</Typography>
//           <Typography>Remark: {formData.remark || "-"}</Typography>
//           <Typography variant="h6">Rules and Regulations</Typography>
//           <Typography>{rulesAndRegulations || "No rules and regulations available."}</Typography>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={generatePDF} color="primary" variant="contained">Download PDF</Button>
//           <Button onClick={() => navigate("/layout/Admission-manager/student-list")} color="secondary">Go to Student List</Button>
//           <Button onClick={() => setOpenPreviewDialog(false)}>Close</Button>
//         </DialogActions>
//       </Dialog>
//       </div>
//     </>
//   );
// };

// export default AdmissionForm;
