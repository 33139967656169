import React, { useState } from "react";
import { Box, Paper, Typography, Tooltip } from "@mui/material";
import {
  Quiz as ExamIcon,
  Source as SourceIcon,
  School as ConductIcon,
  QrCodeScanner as QrCodeScannerIcon,
  MenuBook as MenuBookIcon,
  AutoStories as AutoStoriesIcon,
} from "@mui/icons-material";
import Exam from "../Enquiry/ExamPages/Exam";
import Source from "../Enquiry/ConductBy/Sources/Source";
import Conduct from "../Enquiry/ConductBy/Conduct";
import QrCode from "./QrCode";
import Form from "./Form";
import Category from "./Category";

// Menu items
const menuItems = [
  { id: "exam", label: "Add Exam/Course", icon: <ExamIcon /> },
  { id: "source", label: "Add Source", icon: <SourceIcon /> },
  { id: "conduct", label: "Add Conduct", icon: <ConductIcon /> },
  { id: "qrcode", label: "Generate QR", icon: <QrCodeScannerIcon /> },
  { id: "category", label: "Add Category", icon: <MenuBookIcon /> },
  { id: "course", label: "Course (Catalogue)", icon: <AutoStoriesIcon /> },
];

// Sidebar Component
const Sidebar = ({ menuItems, selectedComponent, setSelectedComponent }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <Paper
      elevation={3}
      sx={{
        width: isCollapsed ? "70px" : "230px",
        padding: "10px",
        display: "flex",
        flexDirection: "column",
        borderRadius: "30px",
        justifyContent: "flex-start",
        height: "300px",
        transition: "width 0.7s ease",
        backgroundColor: "#2980B9",
      }}
      onMouseEnter={() => setIsCollapsed(false)}
      onMouseLeave={() => setIsCollapsed(true)}
    >
      {menuItems.map((item) => (
        <Tooltip
          key={item.id}
          title={isCollapsed ? item.label : ""}
          placement="right"
        >
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              padding: "10px",
              cursor: "pointer",
              fontSize: "13px",
              color: selectedComponent === item.id ? "black" : "white",
              fontWeight: selectedComponent === item.id ? "bold" : "normal",
              borderRadius: "30px",
              backgroundColor: selectedComponent === item.id ? "white" : "",
              transition: "all 0.3s ease",
              whiteSpace: "nowrap",
              boxShadow:
                selectedComponent === item.id
                  ? "0px 4px 10px rgba(0, 0, 0, 0.2)"
                  : "none",
            }}
            onClick={() => setSelectedComponent(item.id)}
          >
            {item.icon}
            {!isCollapsed && item.label}
          </Typography>
        </Tooltip>
      ))}
    </Paper>
  );
};

// EnquirySetting Component
const EnquirySetting = () => {
  const [selectedComponent, setSelectedComponent] = useState("exam");

  const renderComponent = () => {
    switch (selectedComponent) {
      case "exam":
        return <Exam />;
      case "source":
        return <Source />;
      case "conduct":
        return <Conduct />;
      case "qrcode":
        return <QrCode />;
      case "category":
        return <Category />;
      case "course":
        return <Form />;
      default:
        return null;
    }
  };

  return (
    <Box sx={{ display: "flex", padding: "10px" }}>
      <Sidebar
        menuItems={menuItems}
        selectedComponent={selectedComponent}
        setSelectedComponent={setSelectedComponent}
      />
      <Box sx={{ flexGrow: 1, padding: "20px", marginLeft: "20px" }}>
        {renderComponent()}
      </Box>
    </Box>
  );
};

export default EnquirySetting;
