import React from "react";
import { Link, Outlet, useLocation } from "react-router-dom";

const navItemStyle = {
  padding: "3px 8px",
  cursor: "pointer",
  fontSize: "14px",
  fontWeight: "bold",
  color: "white",
  textAlign: "center",
  flex: 1,
  transition: "background-color 0.3s ease, color 0.3s ease",
  textDecoration: "none", // Remove underline
};

// Styles for active item
const activeNavItemStyle = {
  ...navItemStyle,
  backgroundColor: "white",
  color: "black",
  borderRadius: "30px",
  transform: "scale(1)", // Slight increase for active state
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", // Subtle shadow for active
};

const subNavBarStyle = {
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "15px",
  backgroundColor: "#2980B9",
  padding: "8px",
  borderRadius: "30px",
  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)", // Slight shadow for navbar
};

const StudentManagerLayout = () => {
  const location = useLocation();

  const isActive = (path) => location.pathname === path;

  return (
    <div>
      <div style={subNavBarStyle}>
        <Link
          to="/layout/website-manager"
          style={
            isActive("/layout/website-manager")
              ? activeNavItemStyle
              : navItemStyle
          }
        >
          WebsiteUrl
        </Link>
        <Link
          to="/layout/website-manager/Slidebar"
          style={
            isActive("/layout/website-manager/Slidebar")
              ? activeNavItemStyle
              : navItemStyle
          }
        >
          Slidebar
        </Link>
        <Link
          to="/layout/website-manager/Footer"
          style={
            isActive("/layout/website-manager/Footer")
              ? activeNavItemStyle
              : navItemStyle
          }
        >
          Footer
        </Link>
        <Link
          to="/layout/website-manager/WebsiteSetting"
          style={
            isActive("/layout/website-manager/WebsiteSetting")
              ? activeNavItemStyle
              : navItemStyle
          }
        >
          Website Setting
        </Link>
      </div>
      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default StudentManagerLayout;
