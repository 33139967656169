import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import {
  addMaterialType,
  getAllMaterialTypes,
  deleteMaterialType,
  updateMaterialType,
} from "./API/AllApi";
import { Delete, Edit } from "@mui/icons-material";

const AddMaterialType = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [materialtype, setMaterialType] = useState("");
  const [materials, setMaterials] = useState([]);
  const [search, setSearch] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [currentMaterialId, setCurrentMaterialId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchMaterials();
  }, []);

  // Fetch all material types
  const fetchMaterials = async () => {
    try {
      const data = await getAllMaterialTypes();
      setMaterials(data);
    } catch (error) {
      console.error("Error fetching materials:", error);
    }
  };

  // Open dialog for adding or editing material type
  const handleDialogOpen = (material = null) => {
    if (material) {
      setEditMode(true);
      setMaterialType(material.materialtype);
      setCurrentMaterialId(material.id);
    } else {
      setEditMode(false);
      setMaterialType("");
    }
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setMaterialType("");
    setEditMode(false);
    setCurrentMaterialId(null);
  };

  // Add or update material type
  const handleAddOrUpdateMaterialType = async () => {
    try {
      if (editMode) {
        await updateMaterialType(currentMaterialId, materialtype);
        Swal.fire("Success!", "Material Type updated successfully.", "success");
      } else {
        await addMaterialType(materialtype);
        Swal.fire("Success!", "Material Type added successfully.", "success");
      }
      handleDialogClose();
      fetchMaterials();
    } catch (error) {
      Swal.fire("Error", "Something went wrong.", "error");
      console.error("Error adding/updating material type:", error);
    }
  };

  // Delete material type
  const handleDeleteMaterialType = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });
    if (result.isConfirmed) {
      try {
        await deleteMaterialType(id);
        Swal.fire("Deleted!", "Material Type has been deleted.", "success");
        fetchMaterials();
      } catch (error) {
        Swal.fire("Error", "Failed to delete material type.", "error");
        console.error("Error deleting material type:", error);
      }
    }
  };

  // Filter materials based on search
  const filteredMaterials = materials.filter(
    (material) =>
      material.materialtype &&
      material.materialtype.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div>
    <Grid container spacing={2} alignItems="center" className="textField-root">
  <Grid item xs={12} md={2.4}>
    <Typography variant="h6">
      Material Type Count: {filteredMaterials.length}
    </Typography>
  </Grid>
  <Grid item xs={12} md={3}>
    {/* Search field */}
    <TextField
      label="Search Materials"
      variant="outlined"
      margin="normal"
      fullWidth
      value={search}
      onChange={(e) => setSearch(e.target.value)}
    />
  </Grid>
  <Grid item xs={12} md={2.4}>
    <Button
      variant="contained"
      color="primary"
      onClick={() => handleDialogOpen()}
      fullWidth // Optional: Makes the button full width
    >
      Add Material Type
    </Button>
  </Grid>
</Grid>

      {/* Dialog for adding or editing material type */}
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>
          {editMode ? "Edit Material Type" : "Add New Material Type"}
        </DialogTitle>
        <DialogContent className="textField-root">
          <TextField
            autoFocus
            margin="dense"
            label="Material Type"
            fullWidth
            value={materialtype}
            onChange={(e) => setMaterialType(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAddOrUpdateMaterialType} color="primary">
            {editMode ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Table displaying material data */}
      <TableContainer>
        <Table className="table-root">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Material Type</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredMaterials.map((material) => (
              <TableRow key={material.id}>
                <TableCell>{material.id}</TableCell>
                <TableCell>{material.materialtype}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() =>
                      navigate(`/layout/Ebook-manager/CategoryList/${material.id}`, {
                        state: {
                          materialTypeId: material.id,
                          materialTypeName: material.materialtype,
                        },
                      })
                    }
                  >
                    Manage Category
                  </Button>
                  <IconButton
                    variant="contained"
                    color="primary"
                    onClick={() => handleDialogOpen(material)}
                    style={{ marginLeft: 8 }}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    variant="contained"
                    color="error"
                    onClick={() => handleDeleteMaterialType(material.id)}
                    style={{ marginLeft: 8 }}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AddMaterialType;
