import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  MenuItem,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import axios from "axios";
import { SketchPicker } from "react-color";
import Swal from "sweetalert2";

const Gallery = () => {
  const [galleries, setGalleries] = useState([]);
  const [open, setOpen] = useState(false);
  const [galleryColor, setGalleryColor] = useState("#ffffff");
  const institutecode = () => localStorage.getItem("institutecode");
  const currentYear = new Date().getFullYear();
const yearOptions = Array.from({ length: 6 }, (_, i) => currentYear + i);
  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [formData, setFormData] = useState({
    eventName: "",
    galleryColor: "",
    year: "",
    galleryImage: null,
  });
  const [editId, setEditId] = useState(null);
  const [currentImage, setCurrentImage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setFormData({ eventName: "", year: "", galleryColor: "", galleryImage: null});
    setEditId(null);
    setCurrentImage(null);
    setImagePreview(null);
  };

  const fetchGalleries = async () => {
    try {
      const response = await axios.get("https://pjsofttech.in:28443/getAllGalleries", {
        params: { institutecode: localStorage.getItem("institutecode") },
      });
      setGalleries(response.data);
    } catch (error) {
      console.error("Error fetching galleries:", error);
    }
  };

  useEffect(() => {
    fetchGalleries();
  }, []);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    const file = files ? files[0] : null;
    setFormData({
      ...formData,
      [name]: file || value,
    });

    // Handle image preview
    if (name === "galleryImage" && file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleColorChange = (color) => {
    setGalleryColor(color.hex);
    setFormData({ ...formData, galleryColor: color.hex });
  };
  const handleSubmit = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("eventName", formData.eventName);
    formDataToSend.append("galleryColor", formData.galleryColor)
    formDataToSend.append("year", formData.year);
    formDataToSend.append("galleryImage", formData.galleryImage);

    try {
      if (editId) {
        await axios.put(`https://pjsofttech.in:28443/updateGallery/${editId}`, formDataToSend);
        Swal.fire("Success", "Gallery updated successfully!", "success");
      } else {
        await axios.post(`https://pjsofttech.in:28443/createGallery?institutecode=${institutecode()}`, formDataToSend);
        Swal.fire("Success", "Gallery Created successfully!", "success");
      }
      fetchGalleries();
      handleClose();
    } catch (error) {
      console.error("Error submitting gallery:", error);
       Swal.fire("Error", "Failed to Save Gallery!", "error");
    }
  };

  const handleEdit = (gallery) => {
    setEditId(gallery.galleryId);
    setFormData({
      eventName: gallery.eventName,
      year: gallery.year,
      galleryColor: gallery.galleryColor,
      galleryImage: null,
    });
    setGalleryColor(gallery.galleryColor);
    setCurrentImage(gallery.galleryImage);
    setImagePreview(null);
    handleOpen();
  };

  const handleDeleteConfirmation = async (deleteId) => {
          Swal.fire({
              title: "Are you sure?",
              text: "You won't be able to revert this!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Yes, delete it!",
              cancelButtonText: "No, cancel!",
            }).then(async (result) => {
              if (result.isConfirmed) {
    try {
      await axios.delete(`https://pjsofttech.in:28443/deleteGallery/${deleteId}`);
      fetchGalleries();
      Swal.fire("Deleted!", "Gallery has been deleted.", "success");
    } catch (error) {
      console.error("Error deleting gallery:", error);
      Swal.fire("Error", "Failed to delete Gallery!", "error");
    }
  }
});
  };

  const handleImageDialogOpen = (imageUrl) => {
    setSelectedImage(imageUrl);
    setImageDialogOpen(true);
  };

  const handleImageDialogClose = () => {
    setImageDialogOpen(false);
    setSelectedImage(null);
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Create Gallery
      </Button>

      {/* Dialog for Create/Edit */}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm" className="textField-root">
        <DialogTitle color={'black'}>{editId ? "Edit Gallery" : "Create Gallery"}</DialogTitle>
        <DialogContent>
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                      {/* Sketch Picker for color selection */}
                      <SketchPicker color={galleryColor} onChangeComplete={handleColorChange} />
                    
                      {/* Box to preview selected color and label below it */}
                      <div style={{ textAlign: 'center' }}>
                        <div
                          style={{
                            width: '150px',
                            height: '150px',
                            backgroundColor: galleryColor,
                            border: '1px solid #ccc',
                            borderRadius: '50%',
                            marginBottom: '10px', // Adds space between the circle and the label
                          }}
                        />
                        Selected Color
                      </div>
                    </div>
          <TextField
            margin="dense"
            label="Event Name"
            name="eventName"
            value={formData.eventName}
            onChange={handleInputChange}
            fullWidth
          />
        <FormControl fullWidth margin="dense">
  <TextField
  select
    name="year"
    value={formData.year}
    onChange={handleInputChange}
    label="Year"
  >
    {yearOptions.map((year) => (
      <MenuItem key={year} value={year}>
        {year}
      </MenuItem>
    ))}
  </TextField>
</FormControl>

          <input
            type="file"
            accept="image/*"
            name="galleryImage"
            onChange={handleInputChange}
            style={{ marginTop: 15 }}
          />
          {currentImage && !imagePreview && (
            <div style={{ marginTop: 15 }}>
              <p>Current Image:</p>
              <img
                src={currentImage}
                alt="Current Gallery"
                style={{ width: "100%", maxHeight: 200, objectFit: "cover" }}
              />
            </div>
          )}
          {imagePreview && (
            <div style={{ marginTop: 15 }}>
              <p>New Image Preview:</p>
              <img
                src={imagePreview}
                alt="New Gallery Preview"
                style={{ width: "100%", maxHeight: 200, objectFit: "cover" }}
              />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            {editId ? "Update" : "Create"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Full Image Dialog */}
      <Dialog open={imageDialogOpen} onClose={handleImageDialogClose}>
        <DialogContent>
          <img src={selectedImage} alt="Full View" style={{ width: "100%" }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleImageDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Table */}
      <TableContainer component={Paper} style={{ marginTop: 20 }} className="table-root">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Event Name</TableCell>
              <TableCell>Color</TableCell>
              <TableCell>Year</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {galleries.map((gallery) => (
              <TableRow key={gallery.galleryId}>
                <TableCell>{gallery.galleryId}</TableCell>
                <TableCell>{gallery.eventName}</TableCell>
                <TableCell><div
                    style={{
                      width: "20px",
                      height: "20px",
                      backgroundColor: gallery.galleryColor,
                      display: "inline-block",
                      marginRight: "5px",
                    }}
                  />{gallery.galleryColor}</TableCell>
                <TableCell>{gallery.year}</TableCell>
                <TableCell>
                  <img
                    src={gallery.galleryImage}
                    alt={gallery.eventName}
                    style={{ width: 50, height: 50, cursor: "pointer" }}
                    onClick={() => handleImageDialogOpen(gallery.galleryImage)}
                  />
                </TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => handleEdit(gallery)}>
                    <Edit />
                  </IconButton>
                  <IconButton
                   sx={{ color: "red" }}
                    onClick={() => handleDeleteConfirmation(gallery.galleryId)}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Gallery;
