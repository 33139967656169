import React from "react";
import { Routes, Route } from "react-router-dom";
// import AdmissionDashboard from "./AdmissionDashboard";
// import AdmissionForm from "./AdmissionForm";
// import StudentList from "./StudentList";
// import AdmissionAddCourse from "./AdmissionAddCourse";
// import AddmissionSource from "./AddmissionSource";
// import AddGuide from "./AddGuide";
// import AdmissionManagerLayout from "./AdmissionManagerLayout";
// import AdmissionSetting from "./AdmissionSetting";
// import Attendance from "./Attendance";
// import ClassRoom from "./ClassRoom";
// import Medium from "./Medium";
// import Teacher from "./Teacher";
import EbookDashboard from "./EbookDashboard";
import EbookManagerLayout from "./EbookManagerLayout";
import AddData from "./AddData";
import AddMaterial from "./AddMaterial";
import EbookReport from "./EbookReport";
import SalesList from "./SalesList";
import CategoryList from "./CategoryList";
import SubcategoryList from "./SubcategoryList";

const EbookManager = () => {
  return (
    <Routes>
      {/* Main route that renders the layout with the sidebar */}
      <Route path="/" element={<EbookManagerLayout />}>
        {/* Nested routes inside AdmissionManagerLayout */}
        <Route index element={<EbookDashboard />} />
        <Route path="AddData" element={<AddData />} />
        <Route path="AddMaterial" element={<AddMaterial />} />
        <Route path="EbookReport" element={<EbookReport />} />
        <Route path="SalesList" element={<SalesList />} />
        <Route path="CategoryList/:materialTypeId" element={<CategoryList />} />
        <Route path="SubCategoryList/:id" element={<SubcategoryList />} />
        {/* <Route path="admission-form" element={<AdmissionForm />} />
        <Route path="AdmissionSetting" element={<AdmissionSetting />} />
        <Route path="add-course" element={<AdmissionAddCourse />} />
        <Route path="add-source" element={<AddmissionSource />} />
        <Route path="Attendance" element={<Attendance />} />
        <Route path="add-guide" element={<AddGuide />} />
        <Route path="ClassRoom" element={<ClassRoom />} />
        <Route path="Medium" element={<Medium />} />
        <Route path="Teacher" element={<Teacher />} />
        <Route path="student-list" element={<StudentList />} /> */}
      </Route>
    </Routes>
  );
};

export default EbookManager;



