import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  CircularProgress,
  Typography,
  Grid,
  Container,
  Box,
} from "@mui/material";

import { policies } from "./policies";
import PolicyPopup from "./PolicyPopup ";
import CreateAccount from "./CreateAccountPlans/CreateAccount";

const Settings = () => {
  const [employeeDetails, setEmployeeDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const email = localStorage.getItem("email");
  const [open, setOpen] = useState(false);
  const [selectedPolicy, setSelectedPolicy] = useState(null);

  useEffect(() => {
    const fetchEmployeeDetails = async () => {
      try {
        if (!email) {
          console.error("No email found in localStorage");
          setLoading(false);
          return;
        }

        const response = await axios.get(
          `https://pjsofttech.in:20443/findInstitutesby/email?emailaddress=${email}`
        );
        setEmployeeDetails(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching employee details:", error);
        setLoading(false);
      }
    };

    fetchEmployeeDetails();
  }, [email]);

  if (loading) {
    return (
      <Container
        maxWidth="md"
        style={{ textAlign: "center", marginTop: "20%" }}
      >
        <CircularProgress />
      </Container>
    );
  }

  if (!employeeDetails) {
    return (
      <Container
        maxWidth="md"
        style={{ textAlign: "center", marginTop: "20%" }}
      >
        <Typography variant="h6">No employee details found</Typography>
      </Container>
    );
  }
  const handleClickOpen = (policy) => {
    setSelectedPolicy(policy);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div
      maxWidth="lg"
      style={{
        padding: "10px",
        backgroundColor: "#0c1c45",
        color: "#fff",
      }}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{}}
        mb={2}
      >
        <Typography
          variant="h6"
          gutterBottom
          maxWidth={"md"}
          sx={{
            fontWeight: "bold",
            color: "#fff",
            textAlign: "center",
            background: `linear-gradient(to right, #3498DB, #333333)`,
            borderRadius: "40px",
            padding: "10px 250px",
            animation: "fadeIn 0.5s ease-in-out",
            boxShadow: "0 8px 20px rgba(52, 152, 219, 0.6)",
            marginBottom: "10px",
          }}
        >
          Settings
        </Typography>
      </Grid>

      <Grid container spacing={2} justifyContent="flex-start">
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1">
            <strong>Admin Name: </strong> {employeeDetails.ownerName}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1">
            <strong>Organization Name: </strong> {employeeDetails.institutename}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1">
            <strong>Organization Code: </strong> {employeeDetails.institutecode}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1">
            <strong>Email Address: </strong> {employeeDetails.emailaddress}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1">
            <strong>Registration Number: </strong>{" "}
            {employeeDetails.registrationnumber}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1">
            <strong>Contact No. : </strong> {employeeDetails.mobilenumber}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1">
            <strong>Webside Name: </strong> {employeeDetails.websidename}
          </Typography>
        </Grid>{" "}
      </Grid>

      <CreateAccount />

      {/* Divider */}
      <Grid item xs={12}>
        <Box
          sx={{
            borderBottom: "2px solid #0D47A1",
            margin: "34px 0", // Adjust the margin as needed
            width: "100%", // Make sure it spans the full width
          }}
        />
      </Grid>

     
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{}}
        mb={2}
      >
        <Typography
          variant="h6"
          gutterBottom
          maxWidth={"md"}
          sx={{
            fontWeight: "bold",
            color: "#fff",
            textAlign: "center",
            background: `linear-gradient(to right, #3498DB, #333333)`,
            borderRadius: "40px",
            padding: "10px 250px",
            animation: "fadeIn 0.5s ease-in-out",
            boxShadow: "0 8px 20px rgba(52, 152, 219, 0.6)",
            marginBottom: "10px",
          }}
        >
          Legal Details
        </Typography>
      </Grid>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="body1" align="center" style={{ padding: "8px" }}>
            <strong>Created At:</strong>{" "}
            {new Date(employeeDetails.createdAt).toLocaleDateString()}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="body1" align="center" style={{ padding: "8px" }}>
            <strong>PAN No: </strong>
            {employeeDetails.pancard}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="body1" align="center" style={{ padding: "8px" }}>
            <strong>GST No:</strong> {employeeDetails.gstNo}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="body1" align="center" style={{ padding: "8px" }}>
            <strong>LOA:</strong> {employeeDetails.loa}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} justifyContent="center">
        {/* Your existing grid items for employee details */}
        {/* ... */}
      </Grid>

      {/* Policies Section */}
      <Grid
        container
        spacing={2}
        justifyContent="center"
        sx={{ marginTop: "24px" }}
      >
        <Grid item>
          <Typography
            variant="body1"
            align="center"
            onClick={() => handleClickOpen(policies.privacyPolicy)}
            sx={{ cursor: "pointer", textDecoration: "underline" }}
          >
            Privacy Policy
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="body1"
            align="center"
            onClick={() => handleClickOpen(policies.termsConditions)}
            sx={{ cursor: "pointer", textDecoration: "underline" }}
          >
            Terms & Conditions
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="body1"
            align="center"
            onClick={() => handleClickOpen(policies.dataProductPolicy)}
            sx={{ cursor: "pointer", textDecoration: "underline" }}
          >
            Data & Product Policy
          </Typography>
        </Grid>
      </Grid>

      {/* Dialog for Policy Information */}

      {selectedPolicy && (
        <PolicyPopup
          open={open}
          onClose={handleClose}
          policy={selectedPolicy}
        />
      )}
    </div>
  );
};

export default Settings;
