import axios from 'axios';

const API_BASE_URL = "https://pjsofttech.in:20443";

export const fetchSections = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/AllSections`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch sections:", error);
    throw error;
  }
};

export const fetchPlansBySectionId = async (sectionId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/${sectionId}/plans`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch plans:", error);
    throw error;
  }
};

export const updateFeatures = async (sectionId, planId, featureId, enabled) => {
  try {
    const response = await axios.put(
      `${API_BASE_URL}/updateFeatures?sectionId=${sectionId}&planId=${planId}&featureId=${featureId}`,
      { enabled }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to update feature:", error);
    throw error;
  }
};

export const fetchAddOns = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/AllAddOnSystems`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch add-ons:", error);
    throw error;
  }
};

export const fetchAddOnDetails = async (addOnId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/AddOnSystemById/${addOnId}`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch add-on details:", error);
    throw error;
  }
};

export const fetchPlanDetails = async (planId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/PlanById/${planId}`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch plan details:", error);
    throw error;
  }
};

export const fetchValidities = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/AllValidities`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch validities:", error);
    throw error;
  }
};

export const saveInstitute = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/saveinstitude`, data);
    return response.data;
  } catch (error) {
    console.error("Failed to save institute:", error);
    throw error;
  }
};

export const fetchDiscount = async (planName, sectionName, validity) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/getDiscount?plan=${planName}&section=${sectionName}&validity=${validity}`
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch discount:", error);
    throw error;
  }
};
